import { getFunctions } from '../firebase/UthereFirebase';
import { getUserId } from './UserServices';
import { store } from '../store/store';
import { cloneObject } from '../utils/GeneralUtils';

export async function findInsuranceData(data) {
    return new Promise(async function (resolve, reject) {
        try {
            let uthereFindUserByInsuranceDataInput = data;
            let functions = await getFunctions();
            let functionsImpl = await import("firebase/functions");
            const uthereFindUserByInsuranceData = functionsImpl.httpsCallable(functions, 'uthereFindUserByInsuranceData');
            let result = await uthereFindUserByInsuranceData(uthereFindUserByInsuranceDataInput);
            let dataToReturn = null;
            if (result && result.data) {
                dataToReturn = result.data;
            }
            resolve(dataToReturn);
        } catch (e) {
            reject(e)
        }
    });
};

export async function cancelInsurance(insuranceId) {
    return new Promise(async function (resolve, reject) {
        try {
            let userId = getUserId();
            let uthereCancelInsuranceInput = {
                insuranceId: insuranceId,
                userId: userId
            }
            let functions = await getFunctions();
            let functionsImpl = await import("firebase/functions");
            const uthereCancelInsurance = functionsImpl.httpsCallable(functions, 'uthereCancelInsurance');
            await uthereCancelInsurance(uthereCancelInsuranceInput);
            resolve();
        } catch (e) {
            reject(e)
        }
    });
};


export function getActiveInsurances(insuranceNumber, branch, inlcudeInEmission) {
    let insurances = store.getState().insurances;
    let listToReturn = [];
    if (insurances && insurances.list) {
        let insuranceKeys = Object.keys(insurances.list);
        for (let i = 0; i < insuranceKeys.length; i++) {
            if (insurances.list[insuranceKeys[i]].branch !== 4 && (!branch || insurances.list[insuranceKeys[i]].branch === branch)
                && (!insuranceNumber || insurances.list[insuranceKeys[i]].insuranceNumber == insuranceNumber)) {
                let include = false;
                if (inlcudeInEmission) {
                    include = insurances.list[insuranceKeys[i]].state == 2 || insurances.list[insuranceKeys[i]].state == 1 || insurances.list[insuranceKeys[i]].state == 3;
                }
                else {
                    include = insurances.list[insuranceKeys[i]].state == 2;
                }
                if (include) {
                    let clonedInsurances = cloneObject(insurances.list[insuranceKeys[i]])
                    clonedInsurances.id = insuranceKeys[i];
                    listToReturn.push(clonedInsurances);
                }
            }
        }
    }
    return listToReturn;
}

