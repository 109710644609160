import { isIOSversion15, isMobile } from '../../utils/DeviceUtils';
import { setTimeoutWait } from '../../utils/GeneralUtils';

const device = isMobile() ? "mobile" : "desktop";
const initalSize = window.visualViewport.height;

/**
 * 
 * Fornece os métodos para a lógica geral de cada uma das páginas
 * -Títulos e descrições;
 * -Scroll, overflow e resizes; Ter em atenção que o processo funciona de forma bastante distinta das restantes
 * páginas
 */


/**
 * Inicializa a página. Método invoado em todos os useEffect de cada página
 * @param {nome da página} page 
 */
export function initPage(page) {
    if (device === "mobile") {
        setPageTitleAndDescription(page);
        if (page === "process") {
            setSimulationInitialHeightAndScroll();
            setSimulationBodyStyle();
            addSimulationResizeListener();
        }
        if (page === "apolice") {
            setSimulationBodyStyle();
        }
        else {
            removeSimulationBodyStyle();
        }
    }
}
/**
 * Se do html & body style quando a página é a de simulação
 */
export function setSimulationBodyStyle() {
    document.body.style.position = "fixed";
    document.body.style.overflow = "hidden";
    let elements = document.getElementsByTagName("html");
    if (elements && elements.length > 0) {
        elements[0].style.position = "fixed";
        elements[0].style.overflow = "hidden";
    }
};
/**
 * Remove o html & body style quando não estamos na página de simulação
 */
export function removeSimulationBodyStyle() {
    document.body.style.position = null;
    document.body.style.overflow = null;
    let elements = document.getElementsByTagName("html");
    if (elements && elements.length > 0) {
        elements[0].style.position = null;
        elements[0].style.overflow = null;
    }
};
/**
 * Define o scroll inicial e tamanho da página de simulação
 */
export function setSimulationInitialHeightAndScroll() {
    heightAndScrollFunction();
};
/**
 * Listen for resizes on window and updates height and sets scroll to bottom
 */
export function addSimulationResizeListener() {
    if (window.attachEvent) {
        window.attachEvent('onresize', heightAndScrollFunction, true);
    }
    else if (window.addEventListener) {
        window.addEventListener('resize', heightAndScrollFunction, true);
    }
    if (/iPhone|iPod/.test(navigator.userAgent)) {
        visualViewport.addEventListener("resize", () => {
            let keyBoardSize = initalSize - window.visualViewport.height;
            if (keyBoardSize > 0 && window.visualViewport.offsetTop === 0) {
                window.scrollTo(0, keyBoardSize);
            }
        });
    }
};
export function removeSimulationResizeListener() {
    window.removeEventListener('onresize', heightAndScrollFunction, true);
}

export function setPageTitleAndDescription(page) {
    let currentPageConfig = pagesTitleDescription[page];
    if (!currentPageConfig) {
        currentPageConfig = pagesTitleDescription.default;
    }
    document.title = currentPageConfig.title;
    document.querySelector('meta[name="description"]').setAttribute("content", currentPageConfig.descrition);
}

const heightAndScrollFunction = async function setSimulationInitialHeightAndScroll() {
    let elementToResize = document.getElementById("processContainer");
    let uthereHeader = document.getElementById("uthereHeader");
    let uthereProgressBar = document.getElementById("uthereProgressBar");

    if (/iPhone|iPod/.test(navigator.userAgent)) {
        const result = navigator.userAgent.indexOf('CriOS');
        if (result)
            await setTimeoutWait(100);
    }
    let size = window.innerHeight - (uthereHeader ? uthereHeader.clientHeight : 0) - (uthereProgressBar ? uthereProgressBar.clientHeight : 0);
    if (elementToResize) {
        elementToResize.style.height = size + "px";
        if (!isIOSversion15()) {
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            window.scrollTo(0, 0);
        }
    }
}

/**
 * Objecto que define os títulos e descrições de cada página
 * TODO - Terminar parametrização
 */
const pagesTitleDescription = {
    default: { title: 'uthere', descritpion: 'Liberdade para escolher o melhor seguro Vida. Obtenha em 5 minutos as melhores propostas de seguros para si! É só comparar e escolher. Sem papel, sem complicações, quase instantâneo' },
    home: { title: 'uthere', descritpion: 'Liberdade para escolher o melhor seguro Vida. Obtenha em 5 minutos as melhores propostas de seguros para si! É só comparar e escolher. Sem papel, sem complicações, quase instantâneo' },
    simulacao: { title: 'Simulação', descritpion: 'Liberdade para escolher o melhor seguro Vida. Obtenha em 5 minutos as melhores propostas de seguros para si! É só comparar e escolher. Sem papel, sem complicações, quase instantâneo' },
    login: { title: 'Login', descritpion: 'Liberdade para escolher o melhor seguro Vida. Obtenha em 5 minutos as melhores propostas de seguros para si! É só comparar e escolher. Sem papel, sem complicações, quase instantâneo' },
    legal: { title: 'Aspectos Legais', descritpion: 'Liberdade para escolher o melhor seguro Vida. Obtenha em 5 minutos as melhores propostas de seguros para si! É só comparar e escolher. Sem papel, sem complicações, quase instantâneo' },
    contact: { title: 'Fale connosco', descritpion: 'Liberdade para escolher o melhor seguro Vida. Obtenha em 5 minutos as melhores propostas de seguros para si! É só comparar e escolher. Sem papel, sem complicações, quase instantâneo' },
    account: { title: 'Minha conta', descritpion: 'Liberdade para escolher o melhor seguro Vida. Obtenha em 5 minutos as melhores propostas de seguros para si! É só comparar e escolher. Sem papel, sem complicações, quase instantâneo' },
}

