import { getFunctions } from '../firebase/UthereFirebase';


export async function startPlateSearch(processId) {
    return new Promise(async function (resolve, reject) {
        try {
            let uthereGetCarDetailsInput = {
                processId : processId,
                versions : true,
                start : true,
            }
            let functions = await getFunctions();
            let functionsImpl = await import("firebase/functions");
            const uthereGetCarDetails = functionsImpl.httpsCallable(functions, 'uthereGetCarDetails');
            await uthereGetCarDetails(uthereGetCarDetailsInput);
            resolve();
        }
        catch (e) {
            reject(e);
        }
    });
}


export async function searhPlate(processId, plate, mode) {
    return new Promise(async function (resolve, reject) {
        try {
            let uthereGetCarDetailsInput = {
                processId : processId,
                versions : true,
                start : false,
                plate : plate,
                mode : mode,
            }
            let functions = await getFunctions();
            let functionsImpl = await import("firebase/functions");
            const uthereGetCarDetails = functionsImpl.httpsCallable(functions, 'uthereGetCarDetails');
            await uthereGetCarDetails(uthereGetCarDetailsInput);
            resolve();
        }
        catch (e) {
            reject(e);
        }
    });
}

export async function startAutoProposal(processId) {
    return new Promise(async function (resolve, reject) {
        try {
            let uthereGetCarDetailsInput = {
                processId : processId,
                proposal : true,
                start : true,
            }
            let functions = await getFunctions();
            let functionsImpl = await import("firebase/functions");
            const uthereGetCarDetails = functionsImpl.httpsCallable(functions, 'uthereGetCarDetails');
            await uthereGetCarDetails(uthereGetCarDetailsInput);
            resolve();
        }
        catch (e) {
            reject(e);
        }
    });
}

export async function getAutoProposal(processId, processData) {
    return new Promise(async function (resolve, reject) {
        try {
            let uthereGetCarDetailsInput = {
                processId : processId,
                proposal : true,
                start : false,
                processData : processData
            }
            let functions = await getFunctions();
            let functionsImpl = await import("firebase/functions");
            const uthereGetCarDetails = functionsImpl.httpsCallable(functions, 'uthereGetCarDetails');
            await uthereGetCarDetails(uthereGetCarDetailsInput);
            resolve();
        }
        catch (e) {
            reject(e);
        }
    });
}




