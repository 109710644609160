import { isBetweenFridayAndSunday, isMorningTime, isOuvertTime } from '../../utils/DateUtils';
import * as lang from './helpChatLang';



export function getFlow(service) {
    return proposalFlow(service);
}

export function getLang() {
    return lang.lang;
}

let numberStore;

function getRandomUserAnswer() {
    const randomNumber = Math.floor(Math.random() * 5) + 1;
    numberStore = randomNumber;
    return randomNumber;
}


const proposalFlow = (service) => {
        return {
        begin: 'beginChat',
        pages: {
            beginChat: {
                type: 'radio',
                nextPageId: {
                    sequence:
                        [
                            {
                                result: 'endChatRedirect1',
                                type: 'AND',
                                conditions: [
                                    {
                                        comparator: 'EQUALS',
                                        values: [
                                            {
                                                type: 'userVariable',
                                                value: 'beginChat',
                                            },
                                            {
                                                type: 'constant',
                                                value: 1,
                                            },
                                        ]
                                    },
                                ],
                            },
                            {
                                result: 'followChat1',
                            }],
                },
                laraMessage: ["laraIntro", 'laraFollow1'],
                options: {
                    columns: true,
                    items: [
                        {
                            value: 2,
                            label: 'lblHaveQuestion',

                        },
                        {
                            value: 1,
                            label: 'lblNotNow',

                        },
                    ]
                }
            },
            followChat1: {
                type: 'input',
                nextPageId: 'followChat2',
                laraMessage: ["laraFollow1pos"],
                options: {
                    type: 'multi',
                    label: 'describeQuestion',
                    subType: 'text',
                }   
            },            
            followChat1pos: {
                type: 'input',
                nextPageId: 'followChat2',
                laraMessage: ["laraFollow3"],
                options: {
                    type: 'multi',
                    label: 'describeQuestion',
                    subType: 'text',
                }   
            },
            followChat2: {
                laraMessage: ["laraRedirect1", isOuvertTime() ? "laraRedirect2" : isBetweenFridayAndSunday() ? "laraRedirect4pos" : isMorningTime() ? "laraRedirect3pos" :  "laraRedirect2pos"],
                userMessage: `lblOk${getRandomUserAnswer()}`,
                type: 'next',
                nextPageId: 'followChat2end',
                options: {
                    label: `lblOk${numberStore}`,
                }
            },
            followChat2end: {
                laraMessage: [`laraRedirect3${service}`, "laraRedirect3end"],
                type: 'closeChatBox',
                nextPageId: 'separator1',
                options: {
                    label: 'lblConfirm',
                }
            },
            separator1: {
                type: 'separatorMessage',
                nextPageId: 'followChat3',
            },
            followChat3: {
                type: 'input',
                nextPageId: 'followChat4',
                laraMessage: ["laraFollow2"],
                options: {
                    type: 'multi',
                    label: 'describeQuestion',
                    subType: 'text',
                }
            },  
            followChat4: {
                nextPageId: 'separator2',
                laraMessage: !isOuvertTime() ? ["laraRedirect4"] : ["laraRedirect4", "laraRedirect5"],
                type: 'closeChatBox',
                options: {
                    label: `lblOk${getRandomUserAnswer()}`,
                }
            },
            separator2: {
                type: 'separatorMessage',
                nextPageId: 'followChat5',
            },
            followChat5: {
                nextPageId: 'followChat6',
                laraMessage: ["laraFollow3"],
                type: 'input',
                options: {
                    type: 'multi',
                    label: 'describeQuestion',
                    subType: 'text',
                }
            },
            followChat6: {
                nextPageId: 'separator3',
                laraMessage: ["laraRedirect7", "laraRedirect6"],
                type: 'closeChatBox',
                options: {
                    label: `lblOk${getRandomUserAnswer()}`,
                }
            },
            separator3: {
                type: 'separatorMessage',
                nextPageId: `followChat7`, 
            },
            followChat7: {
                nextPageId: `followChat8`, 
                laraMessage: ["laraFollow3"],
                type: 'input',
                options: {
                    type: 'multi',
                    label: 'describeQuestion',
                    subType: 'text',
                }
            },
            followChat8: { 
                nextPageId: `separator4`, 
                laraMessage: ["laraRedirect7", "laraRedirect6"],
                type: 'closeChatBox',
                options: {
                    label: `lblOk${getRandomUserAnswer()}`,
                }
            },
            separator4: { 
                type: 'separatorMessage',
                nextPageId: `followChat9`, 
            },

            followChat9: { 
                nextPageId: `followChat10`, 
                laraMessage: ["laraFollow3"],
                type: 'input',
                options: {
                    type: 'multi',
                    label: 'describeQuestion',
                    subType: 'text',
                }
            },
            followChat10: { 
                nextPageId: `separator5`, 
                laraMessage: ["laraRedirect7", "laraRedirect6"],
                type: 'closeChatBox',
                options: {
                    label: `lblOk${getRandomUserAnswer()}`,
                }
            },
            separator5: { 
                type: 'separatorMessage',
                nextPageId: `followChat11`, 
            },

            followChat11: { 
                nextPageId: `followChat12`, 
                laraMessage: ["laraFollow3"],
                type: 'input',
                options: {
                    type: 'multi',
                    label: 'describeQuestion',
                    subType: 'text',
                }
            },
            followChat12: { 
                nextPageId: `separator6`, 
                laraMessage: ["laraRedirect7", "laraRedirect6"],
                type: 'closeChatBox',
                options: {
                    label: `lblOk${getRandomUserAnswer()}`,
                }
            },
            separator6: { 
                type: 'separatorMessage',
                nextPageId: `followChat13`, 
            },

            followChat13: { 
                nextPageId: `followChat14`, 
                laraMessage: ["laraFollow3"],
                type: 'input',
                options: {
                    type: 'multi',
                    label: 'describeQuestion',
                    subType: 'text',
                }
            },
            followChat14: { 
                nextPageId: `separator7`, 
                laraMessage: ["laraRedirect7", "laraRedirect6"],
                type: 'closeChatBox',
                options: {
                    label: `lblOk${getRandomUserAnswer()}`,
                }
            },
            separator7: { 
                type: 'separatorMessage',
                nextPageId: `followChat15`, 
            },

            followChat15: { 
                nextPageId: `followChat16`, 
                laraMessage: ["laraFollow3"],
                type: 'input',
                options: {
                    type: 'multi',
                    label: 'describeQuestion',
                    subType: 'text',
                }
            },
            followChat16: { 
                nextPageId: `separator8`, 
                laraMessage: ["laraRedirect7", "laraRedirect6"],
                type: 'closeChatBox',
                options: {
                    label: `lblOk${getRandomUserAnswer()}`,
                }
            },
            separator8: { 
                type: 'separatorMessage',
                nextPageId: `followChat17`, 
            },

            followChat17: { 
                nextPageId: `followChat18`, 
                laraMessage: ["laraFollow3"],
                type: 'input',
                options: {
                    type: 'multi',
                    label: 'describeQuestion',
                    subType: 'text',
                }
            },
            followChat18: { 
                nextPageId: `separator9`, 
                laraMessage: ["laraRedirect7", "laraRedirect6"],
                type: 'closeChatBox',
                options: {
                    label: `lblOk${getRandomUserAnswer()}`,
                }
            },
            separator9: { 
                type: 'separatorMessage',
                nextPageId: `followChat19`, 
            },

            followChat19: { 
                nextPageId: `followChat20`, 
                laraMessage: ["laraFollow3"],
                type: 'input',
                options: {
                    type: 'multi',
                    label: 'describeQuestion',
                    subType: 'text',
                }
            },
            followChat20: { 
                nextPageId: `separator9`, 
                laraMessage: ["laraRedirect7", "laraRedirect6"],
                type: 'closeChatBox',
                options: {
                    label: `lblOk${getRandomUserAnswer()}`,
                }
            },
            separator9: { 
                type: 'separatorMessage',
                nextPageId: `followChat21`, 
            },

            followChat21: { 
                nextPageId: `followChat22`, 
                laraMessage: ["laraFollow3"],
                type: 'input',
                options: {
                    type: 'multi',
                    label: 'describeQuestion',
                    subType: 'text',
                }
            },
            followChat22: { 
                nextPageId: `separator10`, 
                laraMessage: ["laraRedirect7", "laraRedirect6"],
                type: 'closeChatBox',
                options: {
                    label: `lblOk${getRandomUserAnswer()}`,
                }
            },
            separator10: { 
                type: 'separatorMessage',
                nextPageId: `followChat23`, 
            },

            followChat23: { 
                nextPageId: `followChat24`, 
                laraMessage: ["laraFollow3"],
                type: 'input',
                options: {
                    type: 'multi',
                    label: 'describeQuestion',
                    subType: 'text',
                }
            },
            followChat24: { 
                nextPageId: `separator11`, 
                laraMessage: ["laraRedirect7", "laraRedirect6"],
                type: 'closeChatBox',
                options: {
                    label: `lblOk${getRandomUserAnswer()}`,
                }
            },
            separator11: { 
                type: 'separatorMessage',
                nextPageId: `followChat25`, 
            },

            followChat25: { 
                nextPageId: `followChat26`, 
                laraMessage: ["laraFollow3"],
                type: 'input',
                options: {
                    type: 'multi',
                    label: 'describeQuestion',
                    subType: 'text',
                }
            },
            followChat26: { 
                nextPageId: `separator12`, 
                laraMessage: ["laraRedirect7", "laraRedirect6"],
                type: 'closeChatBox',
                options: {
                    label: `lblOk${getRandomUserAnswer()}`,
                }
            },
            separator12: { 
                type: 'separatorMessage',
                nextPageId: `followChat27`, 
            },

            followChat27: { 
                nextPageId: `followChat28`, 
                laraMessage: ["laraFollow3"],
                type: 'input',
                options: {
                    type: 'multi',
                    label: 'describeQuestion',
                    subType: 'text',
                }
            },
            followChat28: { 
                nextPageId: `separator13`, 
                laraMessage: ["laraRedirect7", "laraRedirect6"],
                type: 'closeChatBox',
                options: {
                    label: `lblOk${getRandomUserAnswer()}`,
                }
            },
            separator13: { 
                type: 'separatorMessage',
                nextPageId: `followChat29`, 
            },

            followChat29: { 
                nextPageId: `followChat30`, 
                laraMessage: ["laraFollow3"],
                type: 'input',
                options: {
                    type: 'multi',
                    label: 'describeQuestion',
                    subType: 'text',
                }
            },
            followChat30: { 
                nextPageId: `separator14`, 
                laraMessage: ["laraRedirect7", "laraRedirect6"],
                type: 'closeChatBox',
                options: {
                    label: `lblOk${getRandomUserAnswer()}`,
                }
            },
            separator14: { 
                type: 'separatorMessage',
                nextPageId: `followChat31`, 
            },

            followChat31: { 
                nextPageId: `followChat32`, 
                laraMessage: ["laraFollow3"],
                type: 'input',
                options: {
                    type: 'multi',
                    label: 'describeQuestion',
                    subType: 'text',
                }
            },
            followChat32: { 
                nextPageId: `separator15`, 
                laraMessage: ["laraRedirect7", "laraRedirect6"],
                type: 'closeChatBox',
                options: {
                    label: `lblOk${getRandomUserAnswer()}`,
                }
            },
            separator15: { 
                type: 'separatorMessage',
                nextPageId: `followChat5`, 
            },
            endChatRedirect1: { 
                nextPageId: `followChat1pos`, 
                type: 'exitDirect',
            },
        }
    }
}