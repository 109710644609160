import { createSlice } from '@reduxjs/toolkit';

export const insurancesSlice = createSlice({
  name: 'insurances',
  initialState: {
    list : {}
  },
  reducers: {

    setInsurances: (state, action) => {
      if (action.payload && action.payload.insurances) {
        state.list = action.payload.insurances;
      }
    },
    removeData: (state) => {
      state.list = {};
    },
  },
});
export const { setInsurances, removeData } = insurancesSlice.actions;

export const selectInsurances = (state) => state.list;

export default insurancesSlice.reducer;