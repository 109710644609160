import { cloneObject } from "../../utils/GeneralUtils";
import { processBooleanSequence } from "../../utils/UthereExpressionsUtils";
import { formatCurrency } from "../../utils/CurrencyUtils";
import { getAutoProposalMessage } from "./ProcessAutoLogic";
import { getHomeProposalMessage } from "./ProcessHomeLogic";


export function getLaraIntroMessage(value, userTexts, userValues, currentLang) {
    let textToReturn = '';
    let baseMessage = value;
    if (typeof baseMessage === 'object') {
        textToReturn = getConditionalMessages(baseMessage, currentLang, userTexts, userValues);
        textToReturn = textToReturn[0];
    }
    else {
        textToReturn = getStringMessages(baseMessage, currentLang, userTexts, userValues);
    }
    return textToReturn;
};

/**
 * 
 * @param {*} currentPage 
 * @param {*} currentLang 
 */
export function setLang(currentPage, userTexts, userValues, currentLang) {
    for (let i = 0; i < langKeys.length; i++) {

        substituteLangValue(currentPage, langKeys[i], currentLang, userValues, userTexts)
    }
    if (currentPage.laraMessage) {
        getLaraMessages(currentPage, userTexts, userValues, currentLang)
    }

    if (currentPage.desktopMessage) {
        getDesktopMessages(currentPage, userTexts, userValues, currentLang)
    }

    if (currentPage.desktopMessageSecondary) {
        getDesktopMessagesSecondary(currentPage, userTexts, userValues, currentLang)
    }

    if (currentPage.desktopMessageHelper) {
        getDesktopMessagesHelper(currentPage, userTexts, userValues, currentLang)
    }

    if (currentPage.desktopMessageHelperTop) {
        getDesktopMessagesHelperTop(currentPage, userTexts, userValues, currentLang)
    }

    if (currentPage.submit) {
        setLang(currentPage.submit, userTexts, userValues, currentLang);
    }

    if (currentPage.options) {
        setLang(currentPage.options, userTexts, userValues, currentLang);
    }
    else if (currentPage.followup) {
        setLang(currentPage.followup, userTexts, userValues, currentLang);
    }
    else if (currentPage.items) {
        for (let j = 0; j < currentPage.items.length; j++) {
            setLang(currentPage.items[j], userTexts, userValues, currentLang);
        }
    }
    return currentPage;
};

/**
 * 
 * @param {*} baseMessage 
 * @param {*} userTexts 
 * @param {*} userValues 
 * @param {*} currentLang 
 */
export function getLaraMessages(currentPage, userTexts, userValues, currentLang) {
    let currentLaraMessageReference = cloneObject(currentPage.laraMessage);
    if (Array.isArray(currentLaraMessageReference)) {
        currentPage.laraMessage = getStringMessages(currentLaraMessageReference, currentLang, userTexts, userValues);
    }
    else if (typeof currentLaraMessageReference === 'object') {
        if (currentLaraMessageReference.autoProposalMessage) {
            currentPage.laraMessage = getStringMessages(getAutoProposalMessage(userValues), currentLang, userTexts, userValues, null, true)       
        } 
        else if (currentLaraMessageReference.homeProposalMessage) {
                currentPage.laraMessage = getStringMessages(getHomeProposalMessage(userValues), currentLang, userTexts, userValues, null, true)       

        }
        else {
            currentPage.laraMessage = getConditionalMessages(currentLaraMessageReference, currentLang, userTexts, userValues);
        }
    }
}

/**
 * 
 * @param {*} baseMessage 
 * @param {*} userTexts 
 * @param {*} userValues 
 * @param {*} currentLang 
 */
export function getDesktopMessages(currentPage, userTexts, userValues, currentLang) {
    let currentDesktopMessageReference = cloneObject(currentPage.desktopMessage);
    if (Array.isArray(currentDesktopMessageReference)) {
        currentPage.desktopMessage = getStringMessages(currentDesktopMessageReference, currentLang, userTexts, userValues);
    }
    else if (typeof currentDesktopMessageReference === 'object') {
        currentPage.desktopMessage = getConditionalMessages(currentDesktopMessageReference, currentLang, userTexts, userValues);
    }


}

export function getDesktopMessagesSecondary(currentPage, userTexts, userValues, currentLang) {
    let currentDesktopMessageSecondaryReference = cloneObject(currentPage.desktopMessageSecondary);
    if (Array.isArray(currentDesktopMessageSecondaryReference)) {
        currentPage.desktopMessageSecondary = getStringMessages(currentDesktopMessageSecondaryReference, currentLang, userTexts, userValues);
    }
    else if (typeof currentDesktopMessageSecondaryReference === 'object') {
        currentPage.desktopMessageSecondary = getConditionalMessages(currentDesktopMessageSecondaryReference, currentLang, userTexts, userValues);
    }
}

export function getDesktopMessagesHelper(currentPage, userTexts, userValues, currentLang) {
    let currentDesktopMessageHelperReference = cloneObject(currentPage.desktopMessageHelper);
    if (Array.isArray(currentDesktopMessageHelperReference)) {
        currentPage.desktopMessageHelper = getStringMessages(currentDesktopMessageHelperReference, currentLang, userTexts, userValues);
    }
    else if (typeof currentDesktopMessageHelperReference === 'object') {
        if (currentDesktopMessageHelperReference.autoProposalMessage) {
            currentPage.desktopMessageHelper = getStringMessages(getAutoProposalMessage(userValues), currentLang, userTexts, userValues, null, true)       
        }
        else if (currentDesktopMessageHelperReference.homeProposalMessage) {
            currentPage.desktopMessageHelper = getStringMessages(getHomeProposalMessage(userValues, 'desktopMessageHelper'), currentLang, userTexts, userValues, null, true)
            currentPage.desktopMessage = getStringMessages(getHomeProposalMessage(userValues, 'desktopMessage'), currentLang, userTexts, userValues, null, true)       

    }
        else {
            currentPage.desktopMessageHelper = getConditionalMessages(currentDesktopMessageHelperReference, currentLang, userTexts, userValues);
        }
    } 
}

export function getDesktopMessagesHelperTop(currentPage, userTexts, userValues, currentLang) {
    let currentDesktopMessageHelperTopReference = cloneObject(currentPage.desktopMessageHelperTop);
    if (Array.isArray(currentDesktopMessageHelperTopReference)) {
        currentPage.desktopMessageHelperTop = getStringMessages(currentDesktopMessageHelperTopReference, currentLang, userTexts, userValues);
    }
    else if (typeof currentDesktopMessageHelperTopReference === 'object') {
        currentPage.desktopMessageHelperTop = getConditionalMessages(currentDesktopMessageHelperTopReference, currentLang, userTexts, userValues);
    }
}


/**
 * 
 * @param {*} baseMessage 
 * @param {*} userTexts 
 * @param {*} userValues 
 * @param {*} currentLang 
 */
export function getUserMessage(baseMessage, userTexts, userValues, pageId, currentLang) {
    let textToReturn = '';
    if (!baseMessage) {
        textToReturn = getStringMessages(baseMessage, currentLang, userTexts, userValues, pageId);
    }
    else if (typeof baseMessage === 'object') {
        textToReturn = getConditionalMessages(baseMessage, currentLang, userTexts, userValues);
    }
    else {
        textToReturn = getStringMessages(baseMessage, currentLang, userTexts, userValues);
    }
    return textToReturn;
};
/**
 * 
 * @param {*} message 
 * @param {*} gender 
 */
function extractMessageBasedOnGender(message, gender) {
    let messageToReturn = '';
    if (message) {
        if (gender) {
            switch (gender) {
                case 'M':
                    messageToReturn = message['M'];
                    break;
                case 'F':
                    messageToReturn = message['F'];
                    break;
                default:
                    messageToReturn = message['U'];
                    break;
            }
        }
        else {
            messageToReturn = message['U'];
        }
    }
    return messageToReturn;
}
function getConditionalMessages(object, currentLang, texts, values) {
    let sequenceResults = processBooleanSequence(object);
    if (sequenceResults) {
        return getStringMessages(sequenceResults, currentLang, texts, values)
    }
    else {
        return null;
    }
};


/**
 * 
 */
export function getStringMessages(string, currentLang, texts, values, pageId, direct) {
    let messages = extractLang(string, currentLang, values, texts, pageId, direct);
    if (messages) {
        if (Array.isArray(messages)) {
            for (let i = 0; i < messages.length; i++) {
                messages[i] = processExpression(messages[i], texts)
            }
        }
        else {
            ;
            messages = processExpression(messages, texts);
        }
    }
    return messages;
};
/**
 * 
 * @param {*} object 
 * @param {*} key 
 * @param {*} currentLang 
 */
function substituteLangValue(object, key, currentLang, userValues, userTexts) {
    if (object[key]) {
        object[key] = extractLang(object[key], currentLang, userValues, userTexts);
    }
    else {
        delete object[key];
    }
};
/**
 * 
 * @param {*} value 
 * @param {*} currentLang 
 * @returns 
 */
function extractLang(messages, currentLang, userValues, userTexts, pageId, direct) {
    let messagesToReturn = [];
    if (!messages) {
        return substituteTextValues("{" + pageId + "}", userTexts);
    }
    else if (typeof messages === 'string') {
        let currentMessage = currentLang[messages];
        if (typeof currentMessage === 'string') {
            return substituteTextValues(currentMessage, userTexts);
        }
        else {
            return extractMessageBasedOnGender(currentMessage, userValues.gender);
        }
    }
    else if (Array.isArray(messages)) {
        for (let i = 0; i < messages.length; i++) {
            let currentMessage = "";
            if (direct) {
                currentMessage = messages[i];
            }
            else {
                currentMessage =currentLang[messages[i]];
            }

            if (typeof currentMessage === 'string') {
                messagesToReturn.push(currentMessage);
            }
            else {
                messagesToReturn.push(extractMessageBasedOnGender(currentMessage,
                    userValues ? userValues.gender : null));
            }
        }
        return messagesToReturn;
    }
    else if (typeof messages === 'object') {
        return getConditionalMessages(messages, currentLang, userTexts, userValues);
    } else {
        return null;
    }
};

export function processExpression(baseMessage, userTexts) {
    let textToReturn = "";
    let expressionSplit = baseMessage.split('[');
    //É uma expressão
    if (expressionSplit.length > 1) {
        let beforeExpression = substituteTextValues(expressionSplit[0], userTexts);
        let afterExpression = substituteTextValues(expressionSplit[1].split(']')[1]);
        let expressionResult = "";
        let expression = expressionSplit[1].split(']')[0]

        let expressionFunction = expression.split('{')[0];
        let expressionValue = expression.split(expressionFunction)[1];

        switch (expressionFunction) {
            case 'toLower':
                expressionResult = substituteTextValues(expressionValue, userTexts).toLowerCase()
                break;
            case 'capitalize':
                expressionResult = substituteTextValues(expressionValue, userTexts).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
                break;
            case 'capitalizeFirst':
                expressionResult = substituteTextValues(expressionValue, userTexts).replace(/^\w/, (c) => c.toUpperCase());
                break;
            case 'toCurrency':
                expressionResult = formatCurrency(expressionResult = substituteTextValues(expressionValue, userTexts), 2);
                break;
            default:
                expressionResult = substituteTextValues(expressionValue, userTexts);
                break;
        }
        textToReturn = beforeExpression + expressionResult + afterExpression;
    }
    //Não é expressão
    else {
        textToReturn = substituteTextValues(baseMessage, userTexts);
    }
    return textToReturn;
}
/**
 * 
 */
function substituteTextValues(message, userTexts) {
    let valueToReturn = "";
    if (message) {
        let subExpression = message.split('[');
        if (subExpression.length < 2) {
            let expression = message.split('{');
            valueToReturn += expression[0];

            if (expression.length > 1) {
                for (let i = 1; i < expression.length; i++) {
                    let secondPart = expression[i].split('}');
                    let valueToSubstitue = secondPart[0];
                    let prefix = secondPart[1];

                    valueToReturn += getUserTextByObjectKey(userTexts, valueToSubstitue) + prefix;
                }
            }
        }
        else {
            valueToReturn = message;
        }
    }
    return valueToReturn;
}

/**
 * 
 * @param {*} userTexts 
 * @param {*} objectKey 
 * @returns 
 */
function getUserTextByObjectKey(userTexts, objectKey) {
    if (objectKey && userTexts) {
        let objectSplitKeys = objectKey.split('.');
        var valueObject = null;
        for (let i = 0; i < objectSplitKeys.length; i++) {

            if (valueObject && valueObject[objectSplitKeys[i]]) {
                valueObject = valueObject[objectSplitKeys[i]];
            }
            else if (!valueObject) {
                let arraySplit = objectSplitKeys[i].split("|");
                if (arraySplit.length === 3) {
                    let arrayValueObject = userTexts[arraySplit[0]];
                    if (arrayValueObject) {
                        valueObject = arrayValueObject[arraySplit[1]];
                    }
                }
                else {
                    valueObject = userTexts[objectSplitKeys[i]];
                }
            }
            else {
                valueObject = null;
                break;
            }
        }
        if (valueObject) {
            return valueObject;
        }
    }
    return "";
}

/**
 * Keys to substitute with corresponding lang in each page
 */
let langKeys = [
    'title',
    'notSelectedLabel',
    'selectedLabel',
    'label',
    'labelDesktop'
]