import { cloneObject } from '../../utils/GeneralUtils';

let uthereCookies = {};


export function getUthereCookies() {
    return cloneObject(uthereCookies);
}

/**
 * Faz update dos cookies uthere
 * @param {} valuesToReturn 
 */
export function updateCookies(uthereCookiesIn) {
    let cookiesKeys = Object.keys(uthereCookiesIn);
    for (let i = 0; i < cookiesKeys.length; i++) {
        uthereCookies[cookiesKeys[i]] = uthereCookiesIn[cookiesKeys[i]];
        setCookie(cookiesKeys[i], uthereCookies[cookiesKeys[i]], 365);
    }
};
/**
 * Faz set do cookie
 * @param {*} cname 
 * @param {*} cvalue 
 * @param {*} exdays 
 */
export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};


export function setTermsAccepted() {
    setCookie("uthereCookiesAccepted", true);
};

export function getTermsAccepted() {
    return getCookie("uthereCookiesAccepted")
};

/**
 * Obtém o cookie
 * @param {*} cname 
 * @returns 
 */
export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
};