export const lifeFamilyFlow = {
    begin: {
        sequence: [
            {
                result: 'continue',
                type: 'OR',
                conditions: [
                    {
                        comparator: 'PROCESS_OF_SAME_BRANCH_SUB_BRANCH',

                    }
                ],
            },
            {
                result: 'bornDate',
            }]
    },
    laraIntroMessage:
    {
        sequence:
            [{
                result: ['laraIntroMessage'],
                type: 'OR',
                conditions: [
                    {
                        comparator: 'NOT_EMPTY',
                        values: [
                            {
                                type: 'userVariable',
                                value: 'name',
                            },
                        ]
                    }
                ],
            },
            {
                result: ['laraIntroMessage'],
            }]
    },
    pages: {
        continue: {
            type: 'continue',
            nextPageId: 'bornDate',
            userMessage: 'userContinue',
            laraMessage: ['laraContinue'],
            desktopMessage : ['laraContinue'],
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblContinueSimulation',

                    },
                    {
                        value: 2,
                        label: 'lblStartNewSimulation',

                    },
                ]
            }
        },
        bornDate: {
            type: 'date',
            desktopMessage : ['laraBornDateNoNameHelp'],
            laraMessage: ['laraBornDateNoNameHelp'],
            userMessage: 'userBornDate',
            nextPageId: 'civilState',
            options: {
                onBirthday : "Parabéns! 🎉🍾",
                onAlmostBirthday : "Uau. Está quase a fazer anos 😀",
                validation : {
                    type : "AGE",
                    min : 18,
                    max : 65,
                    minText : "Não podemos apresentar uma proposta se não tiver 18 anos",
                    maxText : "Não podemos apresentar uma proposta se tiver mais do que 65 anos"
                },
                label: 'lblBorndDate',
                default:
                {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 40,
                            },
                        ]
                    }
                },
                max: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 17,
                            },
                        ]
                    }
                },
                min: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 125,
                            },
                        ]
                    }
                }
            }
        },
        civilState: {
            type: 'radio',
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'ocupation',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'AGE_GREATER',
                                    bornDateValueId: 'bornDate',
                                    values: [55],
                                }
                            ],

                        },
                        {
                            result: 'children',
                        }
                    ]
            },
            desktopMessage : ['laraCivilState'],
            laraMessage: {
                sequence:
                    [{
                        result: ['laraHappyBirthday', 'laraCivilState'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'BIRTHDAY',
                                bornDateValueId: 'bornDate',
                            },
                            {
                                comparator: 'IS_NOT_SECOND_FLOW',
                                
                            },
                        ],
                    },
                    {
                        result: ['laraAlmostBirthday', 'laraCivilState'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'ALMOST_BIRTHDAY',
                                bornDateValueId: 'bornDate',
                            },
                            {
                                comparator: 'IS_NOT_SECOND_FLOW',
                                
                            },
                        ],
                    },
                    {
                        result: ['laraCivilState'],
                    }]
            },
            userMessage: 'userCivilState',
            options: {
                columns : true,
                items: [
                    {
                        value: 'solteiro',
                        label: 'lblCivilStateSolteiro',

                    },
                    {
                        value: 'casado',
                        label: 'lblCivilStateCasado',

                    },
                    {
                        value: 'uniao',
                        label: 'lblCivilStateUniao',

                    },
                    {
                        value: 'divorciado',
                        label: 'lblCivilStateDivorciado',

                    },
                    {
                        value: 'viuvo',
                        label: 'lblCivilStateViuvo',

                    },

                ]
            }
        },
        children: {
            type: 'radio',
            nextPageId: 'accept',
            laraMessage: ['laraChildren'],
            desktopMessage : ['laraChildren'],
            userMessage: 
            {
                sequence:
                    [{
                        result: 'userChildrenMany',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'children',
                                    },
                                    {
                                        type: 'constant',
                                        value: 4,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'userNoChildren',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'children',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'userChildren',
                    }]
            },
            options: {
                columns : true,
                onDesktopChange : {
                    items: [
                        {
                            value: 2,
                            text: 'Que família fantástica ❤️',
    
                        },
                        {
                            value: 3,
                            text: 'Que família fantástica ❤️',
    
                        },
                        {
                            value: 4,
                            text: 'Que família fantástica ❤️',
    
                        },
    
                    ]  
                },
                items: [
                    {
                        value: 0,
                        label: 'lblChildren0',

                    },
                    {
                        value: 1,
                        label: 'lblChildren1',

                    },
                    {
                        value: 2,
                        label: 'lblChildren2',

                    },
                    {
                        value: 3,
                        label: 'lblChildren3',

                    },
                    {
                        value: 4,
                        label: 'lblChildren4',

                    },

                ]
            }
        },
        accept: {
            type: 'next',
            nextPageId: 'ocupation',
            userMessage: "lblLetsGo",
            laraMessage: {
                sequence:
                    [{
                        result: ['laraFantasticFamily','laraCommentLifeFamilyChildren'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'children',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraCommentLifeFamilyChild'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'children',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraCommentLifeFamily'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraCommentLifeFamilyChildren'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'children',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraCommentLifeFamilyChild'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'children',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraCommentLifeFamily'],
                    }]
            },
            options: {
                label: "lblContinue",
            }
        },
        ocupation: {
            type: 'ocupation',
            nextPageId: 'ocupationActivities',
            desktopMessage : ['laraOcupation'],
            laraMessage:  ['laraOcupation'],
            userMessage: 'userOcupation',
        },
        ocupationActivities: {
            type: 'ocupationActivities',
            nextPageId: 'activities',
            laraMessage: ['laraOcupationActivities'],
            desktopMessage : ['laraOcupationActivities'],
            userMessage: 'userOcupationActivities',
            options: {
                mandatory: false,
                notSelectedLabel: 'lblNo',
                selectedLabel: 'lblConfirm',
                onDesktopChange : {
                    items: [
                        {
                            value: 1,
                            text: 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à sua profissão',
    
                        },
                        {
                            value: 2,
                            text: 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à sua profissão',
    
                        },
                        {
                            value: 3,
                            text: 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à sua profissão',
    
                        },
                        {
                            value: 4,
                            text: 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à sua profissão',
    
                        },
                    ]  
                },
                items:
                    [
                        {
                            value: 4,
                            label: 'lblOcupationActivities4',

                        },
                        {
                            value: 1,
                            label: 'lblOcupationActivities1',

                        },
                        {
                            value: 2,
                            label: 'lblOcupationActivities2',

                        },
                        {
                            value: 3,
                            label: 'lblOcupationActivities3',

                        },
                        
                    ]
            }
        },
        activities : {
            type: 'radio',
            nextPageId: {
                sequence:
                    [{
                        result: 'whatSports',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'activities',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'trip',
                    }]
            },
            laraMessage: ['laraActivitiesSimple'],
            desktopMessage: ['laraActivitiesSimple'],
            userMessage: 'userActivities',
            options: {
                mandatory: false,
                notSelectedLabel: 'lblNo',
                selectedLabel: 'lblConfirm',
                responseToLower: true,
                items: [
                    {
                        value: 1,
                        label: 'lblYes',
                    },
                    {
                        value: 2,
                        label: 'lblNo',
                    },
                ]
            }
        },
        whatSports: {
            laraMessage: ['laraSports'],
            desktopMessage : ['laraSports'],
            userMessage: 'userSports',
            nextPageId: 'trip',
            type: 'fullSwitch',
            options: {
                label: 'lblSelectSports',
                list: 'sportList',
                subType : 'sports',
            }
        },
        trip: {
            type: 'radio',
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'tripWhere',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'trip',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                }
                            ],

                        },
                        {
                            result: 'insureMonthlyValue',
                        }
                    ]
            },
            laraMessage: ['laraTrip'],
            desktopMessage : ['laraTripDesktop'],
            desktopMessageSecondary : ['laraTripDesktopSecondary'],
            userMessage: 'userTrip',
            options: {
                columns : true,
                items: [
                    {
                        value: 1,
                        label: 'lblYes',

                    },
                    {
                        value: 2,
                        label: 'lblNo',

                    },
                    {
                        value: 3,
                        label: 'lblDontKnowYet',

                    },
                ]
            }
        },
        tripWhere: {
            laraMessage: ['laraTripWhere'],
            desktopMessage : ['laraTripWhere'],
            userMessage: 'userTripWhere',
            nextPageId: 'insureMonthlyValue',
            type: 'fullSwitch',
            options: {
                label: 'lblSelectCountries',
                list: 'countryList',
            }
        },
        insureMonthlyValue: {
            type: 'input',
            refreshProposal : true,
            desktopMessage : ['laraInsureMonthlyValue'],
            laraMessage: ['laraInsureMonthlyValue'],
            nextPageId: 'generateProposal',
            userMessage: 'userInsureMonthlyValue',
            options: {
                type: 'currency',
                decimals: 0,
                max: 20000,
                min: 500,
                maxWarn: 'O valor mensal máximo são 20.000€ por mês',
                minWarn: 'O valor mensal mínimo são 500€ por mês',
                label: 'lblInsureMonthlyValue',
            }
        },
        generateProposal: {
            type: 'loader',
            generateProposal: true,
            laraMessage: ['laraProposal'],
            desktopMessage: ['laraProposal'],
            userMessage: {
                sequence:
                    [{
                        result: null,
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EMAIL_USER',
                            }
                        ],
                    },
                    {
                        result: null,
                    }]
            },
            options: {
                barNumber: 4,
                messages: [ "A analisar os <br/><b> seus dados</b>",
                "A procurar a <br/> <b> melhor proposta </b>",
                "A calcular  <br/><b> descontos</b>",
                "Um pouco de  <br/><b> magia uthere 😉</b>",
                "Encontrei a<br/><b>sua proposta</b>"],
                interval: 30
            },
            nextPageId: "showProposal",
        },
        showProposal: {
            allowBack : false,
            type: 'proposal',
            options: {
                subType: 'show',
            },
            nextPageId: "personalData",
            laraMessage: ['laraProposalShow'],
            desktopMessage: ['laraProposalShow'],
            userMessage: 'userProposal',
            userMessageOptions: {
                valueId: 'selectedProposal',
                type: 'window',
                items: [
                    {
                        id: 'selectedProposal',
                        type: 'selectedProposal',
                        titleLabel: 'Proposta'
                    },
                ]
            }

        },
        personalData: {
            allowBack : false,
            type: 'form',
            userMessage: 'userPersonalDataNoName',
            laraMessage: ['laraPersonalDataFamily'],
            desktopMessage : ['laraPersonalDataFamily'],
            nextPageId: 'questionnaire',
            userMessageOptions: {
                valueId: 'personalData',
                type: 'window',
                items: [
                    {
                        id: 'personalData',
                        type: 'form',
                        titleLabel: 'Dados pessoais'
                    },
                ]
            },
            options: {
                showReview : true,
                buttonLabel: 'Dados pessoais',
                items: [
                    {
                        id: 'personalData',
                        type: 'form',
                        titleLabel: 'Dados pessoais',
                        preValues: {
                            firstName: 'name',
                            familyNames: 'familyNames',
                            email : 'email',
                        }
                    },
                ]
            }
        },
        questionnaire: {
            type: 'questionnaire',
            refreshProposal : true,
            userMessage: 'userQuestionnaireNoName',
            desktopMessage : ['laraQuestionaire'],
            laraMessage: ['laraQuestionaire'],
            nextPageId: "paymentData",
            userMessageOptions: {
                valueId: 'questionnaire',
                type: 'window',
                items: [
                    {
                        id: 'questionnaire1',
                        titleLabel: 'Dados gerais',
                        type: 'questionnaire',
                    },
                    {
                        id: 'questionnaire2',
                        titleLabel: 'Doenças e cirurgias',
                        type: 'questionnaire',
                    }
                ]
            },
            options: {
                buttonLabel: 'Dados de saúde',
                items: [
                    {
                        id: 'questionnaire1',
                        titleLabel: 'Dados gerais',
                        text : 'Estamos quase. Vou também precisar de alguns dados de gerais de saúde',
                        type: 'questionnaire',
                    },
                    {
                        id: 'questionnaire2',
                        titleLabel: 'Doenças e cirurgias',
                        text : 'Tem alguma doença ou fez alguma cirurgia?',
                        type: 'questionnaire',
                    },
                ]
            }
        },	
        paymentData: {
            type: 'paymentData',
            laraMessage: ['laraPaymentDataDesktopSecondary', 'laraPaymentDataDesktop'],
            desktopMessageSecondary : ['laraPaymentDataDesktopSecondary'],
            desktopMessage : ['laraPaymentDataDesktop'],
            userMessage: 'userPaymentData',
            nextPageId: 'endSimulation',
            submit : {
                label : "lblSendingPropostal",
                state : 2
            }
        },
        endSimulation: {
            disableExitConfirm: true,
            last : true,
            desktopMessage:['lblEndSimulation'],
            laraMessage: ['lblEndSimulation'],
            hideAvatar : true,
            type: 'rate',
            userMessage: 'userMessageEndSimulation',
            nextPageId: 'exitSimulation',
            allowBack: false,
            allowRestart: false,
        },
        exitSimulation: {
            hideAvatar : true,
            laraMessage: ['laraExitSimulation'],
            desktopMessage: ['laraExitSimulation'],
            disableExitConfirm: true,
            type: 'exitOnly',
            allowBack: false,
            allowRestart: false,
        }
    }
};
