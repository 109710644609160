
import { isMobile } from "../../utils/DeviceUtils";
import { getCurrentHoursMinutes } from '../../utils/DateUtils';
import { getFlow, getLang } from '../../config/proposal/helpChat';
import { getNextPageId } from '../process/ProcessHelper';
import * as ProcessLangLogic from '../process/ProcessLangLogic';
import { getCurrentValues, updateProposalValues } from "../process/ProcessAutoLogic";
import { processBooleanSequence } from "../../utils/UthereExpressionsUtils";
import { saveProcessDetails } from "../../services/ProcessesServices";
const { cloneObject, setTimeoutWait } = require("../../utils/GeneralUtils");
const { getIntialState } = require("../process/ProcessParser");




const emptyProposalChatSate = {
    start: true,
    processing: true,
    showIntroAvatar: false,
    showIntroText: false,
    processId: null,
    currentPage: null,
    currentPageId: null,
    createDate: null,
    updateDate: null,
    lastUserMessageId: null,
    pageIndexRatio: 0,
    currentPageIndex: 0,
    totalPages: 1,
    selectedInsurerId: null,
    selectedInsurerName: null,
    selectedProductId: null,
    numberOfStepsConcluded: 0,
    hasProposal: false,
    proposal: null,
    state: 1,
    branch: 0,
    type: 0,
    service: null,
    subBranch: 0,
    userId: null,
    userValues: {},
    userTexts: {},
    pageHistory: [],
    proposalLoaderState: {

    },
    messages: [],
    inputConfig: {
        type: null,
        disabled: false,
    },
};

export function handleUserMessageNoAnimation(setChatState) {
    setChatState(previousState => {
        const newMessages = [...previousState.messages];
        let messageIndex = null;
        if (newMessages && newMessages.length > 0) {
            for (let i = newMessages.length - 1; i > 0; i--) {
                if (newMessages[i] && newMessages[i].type === "userMessage") {
                    messageIndex = i;
                    break;
                }
            }
            if (messageIndex) {
                newMessages[messageIndex].noAnimation = true;
            }
            previousState.messages = newMessages;
            proposalChatState = cloneObject(previousState);
        }
        return {
            ...previousState,
        };
    })
}

function getPageIndexById(pageId) {

    let flowConfiguration = cloneObject(getFlow(proposalChatState.service));
    let flowConfigurationPagesKeys = Object.keys(flowConfiguration.pages);
    for (let i = 0; i < flowConfigurationPagesKeys.length; i++) {
        if (flowConfigurationPagesKeys[i] === pageId) {
            return i + 1;
        }
    }
    return null;
};

export async function setSeparatorMessage(setChatState, options) {
    setChatState(previousState => {
        let newMessage = {
            type: "separatorMessage",
            id: previousState.currentPageId,
            messagesList: previousState.messages[previousState.messages.length - 1].messagesList,
            noTimeout: true,
            options: options,
            writeState: 0
        };

        const lastMessageIndex = previousState.messages.length - 1;
        previousState.messages.splice(lastMessageIndex - 1, 1); // Remove o item antes do último
        previousState.messages.splice(lastMessageIndex - 1, 0, newMessage);
    

        return {
            ...previousState,
        };
    });
    await setTimeoutWait(100)
    handleUpdateSeparatorState(setChatState);
}

export function handleUpdateSeparatorState(setChatState) {
    setChatState(previousState => {
        let currentMessage = previousState.messages[previousState.messages.length - 2];
        
        if (currentMessage.writeState < 1) {
            currentMessage.writeState += 1;
            
            let timeoutToSet = currentMessage.writeState === 0 ? 1000 : 400;
            
            setTimeout(() => {
                handleUpdateSeparatorState(setChatState);
            }, timeoutToSet);
        } 
        else {
            handleEnableInputAndRemoveAnimations(previousState);
        }

        return {
            ...previousState,
        };
    });
}

export function getNextPage(previousState) {
    let nextPageId = getNextPageId(previousState);
    let flowConfiguration = cloneObject(getFlow(proposalChatState.service));
    let currentLang = cloneObject(getLang());
    if (flowConfiguration && flowConfiguration.pages
        && currentLang
        && nextPageId
        && flowConfiguration.pages[nextPageId]) {
        return {
            id: nextPageId,
            page: ProcessLangLogic.setLang(
                flowConfiguration.pages[nextPageId],
                previousState.userTexts,
                previousState.userValues,
                currentLang)
        }
    }
    else return null;
};

/**
 * 
 * @param {*} values 
 * @returns 
 */
function updateProcessValues(values, previousState, method, texts) {
    let idToSave = proposalChatState.currentPageId;
    if (previousState.currentPage && previousState.currentPage.valueId) {
        idToSave = proposalChatState.currentPage.valueId;
    }
    if (!previousState.userValues[idToSave]) {
        previousState.userValues[idToSave] = {};
    }
    if (values.nif) {
        previousState.userValues.nif = values.nif;
    }

    let valuesKeys = Object.keys(values);
    for (let i = 0; i < valuesKeys.length; i++) {
        if (method && method === "direct") {
            previousState.userValues[valuesKeys[i]] = values[valuesKeys[i]];
            if (texts) {
                previousState.userTexts[valuesKeys[i]] = values[valuesKeys[i]];
            }
        }
        else {
            previousState.userValues[idToSave][valuesKeys[i]] = values[valuesKeys[i]];
            if (texts) {
                previousState.userTexts[idToSave][valuesKeys[i]] = values[valuesKeys[i]];
            }
        }
    }
    proposalChatState = cloneObject(previousState);
};

export function handleUpdateProcessValues(values, setChatState, method, newUser, texts) {
    setChatState(previousState => {
        updateProcessValues(values, previousState, method, texts);
        if (previousState.userValues && previousState.userValues.name) {
            saveProcessDetails(cloneObject(previousState)).then(function () {
            }).catch(() => { });
        }
        return {
            ...previousState,
        };
    });

    return cloneObject(proposalChatState)
};


export function setNextPage(previousState) {
    let nextPage = getNextPage(previousState);
    if (nextPage && nextPage.id && nextPage.page) {
        let currentPageIndex = getPageIndexById(nextPage.id);
        previousState.currentPageIndex = nextPage.last ? 100 : currentPageIndex;
        previousState.pageIndexRatio = nextPage.page.last ? 100 : (currentPageIndex / previousState.totalPages) * 100;
        previousState.currentPage = nextPage.page;
        previousState.currentPageId = nextPage.id;
        previousState.pageHistory = [
            ...previousState.pageHistory,
            { id: nextPage.id, branch: previousState.branch, subBranch: previousState.subBranch, type: previousState.type }
        ]
    }
    else {
        previousState.currentPageId = null;
        previousState.currentPage = null;
    }
};


/**
 * Current process state
 */
let proposalChatState = cloneObject(emptyProposalChatSate);

export function getEmptyChatState() {
    return cloneObject(emptyProposalChatSate);
};

export function initProposalChatState(setChatState, initUserValues, type) {
    proposalChatState = cloneObject(emptyProposalChatSate);
    proposalChatState.userValues = initUserValues;
    proposalChatState.userTexts = initUserValues;
    proposalChatState.service = type;

    let parsedState = getIntialState({ type: 3 },
        proposalChatState.userValues, proposalChatState.userTexts
    );

    proposalChatState.avatar = parsedState.avatar;


    if (parsedState && parsedState.id && parsedState.page) {

        proposalChatState.currentPageId = parsedState.id;
        proposalChatState.introMessage = parsedState.introMessage;
        proposalChatState.currentMessage = [parsedState.introMessage];
        proposalChatState.currentPage = parsedState.page;
        proposalChatState.currentPageIndex = parsedState.pageIndex;
        proposalChatState.pageIndexRatio = parsedState.pageIndexRatio;
        proposalChatState.totalPages = parsedState.totalPages;
        proposalChatState.pageHistory.push(
            {
                id: parsedState.id, branch: proposalChatState.branch,
                subBranch: proposalChatState.subBranch, type: proposalChatState.type
            });
    }


    if (proposalChatState && proposalChatState.currentPage) {
        proposalChatState = {
            ...proposalChatState,
            inputConfig: {
                type: !isMobile() ? proposalChatState.currentPage.type : null,
                currentPageId: proposalChatState.currentPageId,
                valueId: proposalChatState.currentPage.valueId,
                options: proposalChatState.currentPage.options,
                disabled: false,
            },
        }
    }

    setChatState(cloneObject(proposalChatState));
    return;
    // }
};


export function handleUpdateLaraMessageState(index, setChatState) {
    setChatState(previousState => {
        if (index >= 0) {
            let currentMessage = previousState.messages[previousState.messages.length - 1];
            let currentSubMessage = currentMessage.messagesList[index];
            if (currentSubMessage.writeState < 3) {
                currentSubMessage.writeState += 1;
                let timeoutToSet = currentSubMessage.writeState === 3 ? 400 :
                    currentSubMessage.writeState === 1 ? 1000 :
                        currentSubMessage.writeState === 2 ? 100 : 0;
                if (currentSubMessage.writeState < 3) {
                    setTimeout(() => {
                        handleUpdateLaraMessageState(index, setChatState);
                    }, timeoutToSet)
                }
                else if (currentSubMessage.writeState === 3 && currentMessage.messagesList.length > index + 1) {
                    setTimeout(() => {
                        handleUpdateLaraMessageState(index + 1, setChatState);
                    }, timeoutToSet)
                }
                else if (currentSubMessage.writeState === 3) {
                    handleEnableInputAndRemoveAnimations(previousState);
                }
            }

        }
        else {
            handleEnableInputAndRemoveAnimations(previousState);
        }

        proposalChatState = cloneObject(previousState);
        return {
            ...previousState,
        };
    });
};

export function handleEnableInputAndRemoveAnimations(previousState) {


    if (previousState.messages[previousState.messages.length - 1]
        && previousState.messages[previousState.messages.length - 1].messagesList
        && previousState.messages[previousState.messages.length - 1].messagesList.length > 0) {
        previousState.messages[previousState.messages.length - 1].writeState = 2;
    }
    previousState.processing = false;
    previousState.start = false;
    previousState.numberOfStepsConcluded = previousState.numberOfStepsConcluded + 1;
    previousState.inputConfig = {
        ...previousState.inputConfig,
        type: previousState.currentPage && previousState.currentPage.type ?
            previousState.currentPage.type : null,

    };
};

/**
 * 
 * @param {*} textList 
 * @returns 
 */
function getLaraMessages(textList) {
    let messagesToReturn = [];
    if (textList) {
        for (let i = 0; i < textList.length; i++) {
            messagesToReturn.push(
                {
                    text: textList[i],
                    time: getCurrentHoursMinutes(),
                    timestamp: new Date().getTime(),
                    writeState: i === 0 ? 1 : 0,
                }
            )
        }
    }
    return messagesToReturn;
};

export function handleBeginLaraMessages(setChatState, initial) {
    setChatState(previousState => {
        if (!initial) {
            setNextPage(previousState);
        }
        let laraMessagesList = previousState.currentPage.laraMessage
            ? getLaraMessages(Array.isArray(previousState.currentPage.laraMessage)
                ? previousState.currentPage.laraMessage : [previousState.currentPage.laraMessage]) : null;        
        if (previousState.currentPage && previousState.currentPage.laraMessage) {
            //Indica que mensagens da Lara começam a ser escritas
            previousState.messages[previousState.messages.length - 1].writeState = 1;
            previousState.messages[previousState.messages.length - 1].messagesList = laraMessagesList;
        }
        else {
            previousState.writting = false;
        }
        
        previousState.currentMessage = [];

        if (previousState.currentPage) {
            if (previousState.currentPage.desktopMessage) {
                previousState.desktopMessage = previousState.currentPage.desktopMessage;
            }
            if (previousState.currentPage.desktopMessageSecondary) {
                previousState.desktopMessageSecondary = previousState.currentPage.desktopMessageSecondary;
            }
            if (previousState.currentPage.desktopMessageHelper) {
                previousState.desktopMessageHelper = previousState.currentPage.desktopMessageHelper;
            }
            if (previousState.currentPage.desktopMessageHelperTop) {
                previousState.desktopMessageHelperTop = previousState.currentPage.desktopMessageHelperTop;
            }
        }

        previousState.inputConfig = {
            ...previousState.inputConfig,
            type: null,
            currentPageId: previousState.currentPageId,
            valueId: previousState.currentPage ? previousState.currentPage.valueId : null,
            disabled: false,
            options: previousState.currentPage ? previousState.currentPage.options : null
        }
        proposalChatState = cloneObject(previousState);
        return {
            ...previousState,
        };
    });
};

export function handleBeginProcessing(setChatState) {
    setChatState(previousState => {
        previousState.processing = true;
        previousState.manualBack = null;
        previousState.fromBack = false;
        previousState.desktopMessage = "";
        previousState.desktopMessageHelper = "";
        previousState.desktopMessageHelperTop = "";
        previousState.currentMessage = "";
        proposalChatState = cloneObject(previousState);
        return {
            ...previousState,
        };
    })
};

export async function handleUserResponse(responseValues, responseTexts, setChatState) {
    return new Promise(async function (resolve, reject) {
        setChatState(previousState => {
            let nextPage = getNextPage(previousState);
            if (nextPage && nextPage.page) {
                if (nextPage.page.updateProcessValues) {
                    let currentValues = getCurrentValues();
                    if (currentValues) {
                        let currentValuesKeys = Object.keys(currentValues)
                        for (let i = 0; i < currentValuesKeys.length; i++) {
                            previousState.userValues[currentValuesKeys[i]] = currentValues[currentValuesKeys[i]];
                            previousState.userTexts[currentValuesKeys[i]] = currentValues[currentValuesKeys[i]];
                        }
                    }
                }
            }
            let currentUserMessage = previousState.messages[previousState.messages.length - 2];
            //Guarda o valor e texto correspondente à resposta do user
            let message = setUserValuesAndTexts(responseValues, responseTexts, previousState);
            
            nextPage = getNextPage(previousState);
            if (!currentUserMessage || !currentUserMessage.options || currentUserMessage.options.type !== "map" || !previousState.inputConfig
                || previousState.inputConfig.type != "postal") {
                let messagesToAdd = getBaseMessages(previousState, message, responseTexts);
                if (nextPage && nextPage.page && messagesToAdd.laraMessage && messagesToAdd.userMessage) {
                    previousState.messages = [
                        ...previousState.messages,
                        messagesToAdd.userMessage,
                        messagesToAdd.laraMessage
                    ];
                }
                else if (nextPage && nextPage.page && messagesToAdd.userMessage) {
                    previousState.messages = [
                        ...previousState.messages,
                        messagesToAdd.userMessage,
                    ];
                }
                else if (nextPage && nextPage.page && messagesToAdd.laraMessage) {
                    previousState.messages = [
                        ...previousState.messages,
                        messagesToAdd.laraMessage,
                    ];
                }
                else {
                    previousState.messages = [
                        ...previousState.messages,
                        messagesToAdd.userMessage,
                    ];
                }
            }
            if (previousState && previousState.currentPage && previousState.currentPage.userMessageType === "homeProposal") {
                previousState.userValues.showConfetti = null;
            }

            resetScroll();
            previousState.lastUserMessageId = previousState.currentPageId;
            previousState.inputConfig = { ...previousState.inputConfig, disabled: true };
            proposalChatState = cloneObject(previousState);
            resolve();
            return {
                ...previousState,

            };
        })
    });
};


/**
 * 
 * @param {*} values 
 * @param {*} texts 
 * @returns 
 */
function setUserValuesAndTexts(values, texts, previousState) {
    if (values) {
        let valuesKeys = Object.keys(values);

        for (let i = 0; i < valuesKeys.length; i++) {
            previousState.userValues[valuesKeys[i]] = values[valuesKeys[i]];
        }
    }
    if (texts) {
        let textKeys = Object.keys(texts);
        for (let i = 0; i < textKeys.length; i++) {
            previousState.userTexts[textKeys[i]] = texts[textKeys[i]];
        }
    }

    proposalChatState = cloneObject(previousState);
    return getUserMessage(
        previousState.currentPage.userMessage, previousState.userTexts, previousState.userValues, previousState.currentPageId,
        {
            branch: proposalChatState.branch,
            subBranch: proposalChatState.subBranch,
            type: proposalChatState.type,
        });
};

function getBaseMessages(previousState, userMessage, responseTexts, initial) {
    let messageOptions = previousState.currentPage.userMessageOptions;
    if (messageOptions) {
        let messageOptionsKeys = Object.keys(messageOptions);
        if (messageOptionsKeys["sequence"]) {
            messageOptions = processBooleanSequence(messageOptions);
        }
    }
    let baseUserMessage = {
        type: 'userMessage',
        id: previousState.currentPageId,
        text: userMessage ? userMessage : "",
        options: previousState.currentPage.userMessageOptions,
        timestamp: new Date().getTime(),
        time: getCurrentHoursMinutes()
    };

    let baseMapMessage = null;

    if (previousState && previousState.currentPage && previousState.currentPage.userMessageType === "homeProposal" && !initial) {
        baseUserMessage = {
            type: 'homeProposal',
            id: previousState.currentPageId,
            options: previousState.currentPage.userMessageOptions,
            timestamp: new Date().getTime(),
            time: getCurrentHoursMinutes()
        }
    }

    let valueToReturn = {};
    if (userMessage !== null) {
        valueToReturn.userMessage = previousState && previousState.currentPage && previousState.currentPage.type === "postal"
            && !previousState.currentPage.hidePostal ?
            baseMapMessage : baseUserMessage;
    }

    valueToReturn.laraMessage = {
        type: 'laraMessage',
        writeState: 0,
        id: initial ? "initial" : previousState.currentPageId,
        messagesList: [],
    };

    let toReturn = cloneObject(valueToReturn);
    return cloneObject(toReturn);
}

/**
 * Resets chat scroll to the bottom
 */
function resetScroll() {
    let feedContainer = document.getElementById("feedContainer");
    if (feedContainer) {
        feedContainer.scrollTop = 0;
    }
};

/**
 * 
 * @param {*} baseMessage 
 * @param {*} responseText 
 * @returns 
 */
export function getUserMessage(baseMessage, userTexts, userValues, pageId, branchInfo) {
    let currentLang = getLang();
    return ProcessLangLogic.getUserMessage(baseMessage, userTexts, userValues, pageId, currentLang);
};

/**
 * 
 * @returns 
 */
export function getCurrentStateChat() {
    return cloneObject(proposalChatState);
};