import './HomeHowTo.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller } from "swiper";
import { useEffect, useState } from 'react';
import UthereLottie from '../../lottie/UthereLottie';
import compraLottie from '../../../assets/lottie/Compra_frame.json'
import sinistroLottie from '../../../assets/lottie/Sinistro_frame.json'


/**
* HomeHowTo
*/
function HomeHowTo(props) {
    const [swiper, setSwiper] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animationCompra, setAnimationCompra] = useState();
    const [animationSinistro, setAnimationSisitro] = useState();

    useEffect(() => {
        import('./../../../assets/lottie/Compra_frame.json').then(setAnimationCompra);
    }, []);

    useEffect(() => {
        import('../../../assets/lottie/Sinistro_frame.json').then(setAnimationSisitro);
    }, []);


    const swiperProps = {
        allowTouchMove: true,
        initialSlide: 0,
        loop: false,
        allowTouchMove: false,
        speed: 800,
        slidesPerView: "auto",
        spaceBetween: 24,
        centeredSlides: true,
        breakpoints: {
            375: {
                spaceBetween: 32,
            },
            576: {
            },
            768: {
                spaceBetween: 40,
            },
            992: {
                spaceBetween: 48,
            },
        },




    }

    const handleOnSetSwiper = (index) => {
        if (swiper) {
            swiper.slideTo(index)
            setCurrentIndex(index);
        }
    }
    return (
        <div className='how-to-container'>
            {props.branch === 0 ?
                <div className='home-title-container how-to-container-title'>
                    <h2><span className='bold'>É tão simples</span> que está a comprar um seguro</h2>
                    <h6>Fale com a Lara, a nossa assistente digital. É muito simples, e em segundos tem a nossa recomendação personalizada</h6>
                </div> :
                props.branch === 1 ?
                    <div className='home-title-container how-to-container-title'>
                        <h2><span className='bold'>100% digital.</span> É muito fácil e muito rápido</h2>
                        <h6>A Lara, a nossa assistente digital vai ajudá-lo. Simule e compre em minutos</h6>
                    </div> :
                    <div className='home-title-container  how-to-container-title'>
                        <h2><span className='bold'> É fácil comprar. É fácil participar um sinistro </span></h2>
                        <h6>Os nossos agentes digitais estão sempre disponíveis. É muito rápido e muito fácil</h6>
                    </div>
            }
            {props.branch === 0 ?
                <div className='how-to-swipper'>
                    <Swiper onSwiper={setSwiper} modules={[Controller]}
                            {...swiperProps}>

                        <SwiperSlide>
                            <div>
                                {animationCompra ? <UthereLottie src={compraLottie}/> : null}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                {animationSinistro ? <UthereLottie src={sinistroLottie}/> : null}
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                : props.branch === 1 ?
                    <div className='how-to-container-mobile-image'>
                    </div> :
                    <div className='how-to-swipper'>
                        <div className='how-to-choose-animation-container'>
                            <div className='how-to-choose-animation-container-box'>
                                <div onClick={() => handleOnSetSwiper(0)}
                                     className={`how-to-choose-animation-container-box-option ${currentIndex == 0 ?
                                         "how-to-choose-animation-container-box-option-selected" : ""}`}>
                                    simular e comprar
                                </div>
                                <div onClick={() => handleOnSetSwiper(1)}
                                     className={`how-to-choose-animation-container-box-option ${currentIndex == 1 ?
                                         "how-to-choose-animation-container-box-option-selected" : null}`}>
                                    participar sinistro
                                </div>
                            </div>
                        </div>
                        <Swiper onSwiper={setSwiper} modules={[Controller]}
                                {...swiperProps}>

                            <SwiperSlide>
                                <div>
                                    {animationCompra ? <UthereLottie src={compraLottie}/> : null}
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    {animationSinistro ? <UthereLottie src={sinistroLottie}/> : null}
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>}
        </div>
    );
};
export default HomeHowTo;
