import {initFirebase} from './firebase/UthereFirebase';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {lazy, Suspense} from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/home/Home';


/**
 * ROUTER WATCHER
 */
const RouteWatcher = lazy(() => import('./hooks/RouteWatcher'))

/**COOKIES */
const Cookies = lazy(() => import('./components/cookies/Cookies'));
/**SCROLL TOP */
const ScrollToTop = lazy(() => import('./hooks/ScrollToTop'));

/*PAGES */
const HomeHome = lazy(() => import('./pages/home-home/HomeHome'));
const HomeAuto = lazy(() => import('./pages/home-auto/HomeAuto'));
const HomeLife = lazy(() => import('./pages/home-life/HomeLife'));
const Simulation = lazy(() => import('./pages/simulation/Simulation'));
const Claim = lazy(() => import('./pages/claim/Claim'));
const Legal = lazy(() => import('./pages/legal/Legal'));
const Contact = lazy(() => import('./pages/contact/Contact'));
const Login = lazy(() => import('./pages/login/Login'));
const InsuranceDetail = lazy(() => import('./pages/insurance-detail/InsuranceDetail'));
const InsuranceList = lazy(() => import('./pages/insurance-list/InsuranceList'));
const Mensagens = lazy(() => import('./pages/mensagens/Mensagens'));
const CancelEmails = lazy(() => import('./pages/cancel-emails/CancelEmails'));
const CancelInsurance = lazy(() => import('./pages/cancel-insurance/CancelInsurance'));
const Dev =
    process.env.NODE_ENV === "development" ?
        lazy(() => import('./pages/dev/Dev')) : null;

// Route paths
const routes = [
    { path: '/fale-connosco', component: <Contact /> },
    { path: '/vida-credito', component: <HomeLife /> },
    { path: '/vida', component: <HomeLife /> },
    { path: '/casa', component: <HomeHome /> },
    { path: '/auto', component: <HomeAuto /> },
    { path: '/simulacao', component: <Simulation /> },
    { path: '/apolice', component: <InsuranceDetail /> },
    { path: '/apolices', component: <InsuranceList /> },
    { path: '/participacao-sinistro', component: <Claim /> },
    { path: '/login', component: <Login /> },
    { path: '/aspectos-legais', component: <Legal /> },
    { path: '/cancelamento-seguro', component: <CancelInsurance /> },
    { path: '/notificacoes', component: <Mensagens /> },
    { path: '/cancelamento-emails', component: <CancelEmails /> },
    process.env.NODE_ENV === "development" ? { path: '/dev', component: <Dev /> } : null,
].filter(route => route); // Remove null routes

function App() {

    // Init firebase
    initFirebase();
    const pathname = window.location.pathname;

    const getFallbackRoute = () => {
        switch (pathname) {
            case "/vida":
            case "/vida-credito":
                return <HomeLife />;
            case "/casa":
                return <HomeHome />;
            case "/auto":
                return <HomeAuto />;
            default:
                return <Home />;
        }
    };

    return (
        <div>
            <Suspense fallback={null}>
                <Router>
                    <ScrollToTop/>
                    <RouteWatcher/>
                    <div className="route-container">
                        <Routes>
                            {routes.map(({ path, component }) => (
                                <Route key={path} path={path} element={component} />
                            ))}
                            <Route path='*' element={getFallbackRoute()} />
                        </Routes>
                    </div>
                    {pathname === "/cancelamento-seguro" ? null : <Cookies/>}
                </Router>
            </Suspense>
        </div>
    );
}

export default App;
