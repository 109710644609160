import * as ProcessConfig from '../../config/process/ProcessConfig'
import { cloneObject } from '../../utils/GeneralUtils';
import { getNextPageId } from './ProcessHelper';
import { processBooleanSequence } from '../../utils/UthereExpressionsUtils';
import * as ProcessLangLogic from './ProcessLangLogic';

/**
 * 
 * @param {*} branchInfo 
 */
export function getFlowInfo(branchInfo, currentPageId, currentPage) {
    let flowConfiguration = cloneObject(ProcessConfig.getFlow(branchInfo));
    let totalPages = Object.keys(flowConfiguration.pages).length;
    let pageIndex = getPageIndexById(branchInfo, currentPageId);

    return {
        pageIndex: pageIndex,
        pageIndexRatio: currentPage.last ? 100 : ((pageIndex ? pageIndex : 1) / totalPages) * 100,
        totalPages: totalPages
    }
}

export function getDesktopMessage(branchInfo, messages, userValues, userTexts) {
    let currentLang = ProcessConfig.getLang(branchInfo);
    return ProcessLangLogic.getStringMessages(messages, currentLang,
        userTexts,
        userValues)
}

/**
 * 
 * @returns the first page to be processed in the simulation
 */
export function getIntialState(branchInfo, userValues, userTexts) {
    let flowConfiguration = cloneObject(ProcessConfig.getFlow(branchInfo));
    let currentLang = ProcessConfig.getLang(branchInfo);
    let tmpBegin = flowConfiguration.begin  ? typeof flowConfiguration.begin === "object" ? processBooleanSequence(flowConfiguration.begin) : flowConfiguration.begin : null;
    if (flowConfiguration && currentLang
        && tmpBegin
        && flowConfiguration.pages
        && flowConfiguration.pages[tmpBegin]) {

        let totalPages = Object.keys(flowConfiguration.pages).length;
        let pageIndex = getPageIndexById(branchInfo, tmpBegin);
        let currentPage = ProcessLangLogic.setLang(flowConfiguration.pages[tmpBegin],
            userTexts,
            userValues,
            currentLang)
        return {
            introMessage: ProcessLangLogic.getLaraIntroMessage(
                flowConfiguration.laraIntroMessage,
                userTexts,
                userValues,
                currentLang),
            id: tmpBegin,
            desktopMessage: currentPage.desktopMessage,
            desktopMessageSecondary: currentPage.desktopMessageSecondary,
            desktopMessageHelper: currentPage.desktopMessageHelper,
            desktopMessageHelperTop: currentPage.desktopMessageHelperTop,
            avatar: ProcessConfig.getAvatar(branchInfo),
            page: currentPage,
            pageIndex: pageIndex,
            pageIndexRatio: currentPage.last ? 100 : (pageIndex / totalPages) * 100,
            totalPages: totalPages
        }
    }
    else return null;
}
/**
 * 
 * @param {*} pageId 
 * @returns 
 */
export function getPageById(branchInfo, pageId, userTexts, userValues) {
    let flowConfiguration = cloneObject(ProcessConfig.getFlow(branchInfo));
    let currentLang = ProcessConfig.getLang(branchInfo);
    if (flowConfiguration && currentLang
        && flowConfiguration.pages
        && flowConfiguration.pages[pageId]) {
        return ProcessLangLogic.setLang(flowConfiguration.pages[pageId],
            userTexts,
            userValues,
            currentLang);
    }
    return null;
}
/**
 * 
 * @param {*} currentPage 
 * @returns 
 */
export function getNextPage(previousState) {
    let nextPageId = getNextPageId(previousState);
    let branchInfo = {
        branch: previousState.branch,
        subBranch: previousState.subBranch,
        type: previousState.type,
        session: previousState.session
    };
    let flowConfiguration = cloneObject(ProcessConfig.getFlow(branchInfo));
    let currentLang = ProcessConfig.getLang(branchInfo);
    if (flowConfiguration && flowConfiguration.pages
        && currentLang
        && nextPageId
        && flowConfiguration.pages[nextPageId]) {
        return {
            id: nextPageId,
            page: ProcessLangLogic.setLang(
                flowConfiguration.pages[nextPageId],
                previousState.userTexts,
                previousState.userValues,
                currentLang)
        }
    }
    else return null;
};
/**
 * 
 * @param {*} baseMessage 
 * @param {*} responseText 
 * @returns 
 */
export function getUserMessage(baseMessage, userTexts, userValues, pageId, branchInfo) {
    let currentLang = ProcessConfig.getLang(branchInfo);
    return ProcessLangLogic.getUserMessage(baseMessage, userTexts, userValues, pageId, currentLang);
};
/**
 * 
 * @param {*} pageId 
 * @returns 
 */
export function getPageIndexById(branchInfo, pageId) {
    let flowConfiguration = cloneObject(ProcessConfig.getFlow(branchInfo));
    let flowConfigurationPagesKeys = Object.keys(flowConfiguration.pages);
    for (let i = 0; i < flowConfigurationPagesKeys.length; i++) {
        if (flowConfigurationPagesKeys[i] === pageId) {
            return i + 1;
        }
    }
    return null;
};
