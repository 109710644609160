import { getCookie } from "../logic/cookies/CookiesLogic";


const projectId = "jbhswwbpxt";

export function getClarityData() {
    let objectToReturn = {
        userId: null,
        sessionId: null,
    };
    let userId = getCookie("_clck");
    let sessionId = getCookie("_clsk");
    if (userId) {
        objectToReturn.userId = userId.split("|")[0];
    }
    if (sessionId) {
        objectToReturn.sessionId = sessionId.split("|")[0];
    }
    if (objectToReturn.sessionId && objectToReturn.userId) {
        objectToReturn.url = "https://clarity.microsoft.com/player/" + projectId + "/" + objectToReturn.userId + "/" + objectToReturn.sessionId;
    }
    return objectToReturn;
}