import { branchesNames, subBranchesNames } from '../../config/general/branchesConfig';
import { getActiveInsurances } from '../../services/InsuranceServices';

export function getNotifications(processes, type, branch, number, read) {
    let notificationsNewSate = [];
    if (processes) {
        let keys = Object.keys(processes);
        let insurances = getActiveInsurances(null, null, true);
        for (let i = 0; i < keys.length; i++) {
            let currentProcess = processes[keys[i]];
            if ((read || !processes[keys[i]].read) && processes[keys[i]].state > 0 && (!type || type === processes[keys[i]].type) &&
                (!branch || branch.includes(processes[keys[i]].branch))) {

                let apoliceLink = null;
                for (let j = 0; j < insurances.length; j++) {
                    if (insurances[j].processId === currentProcess.processId) {
                        apoliceLink = "/apolice?id=" + insurances[j].id;
                        break;
                    }
                }
                notificationsNewSate.push({
                    notificationLabel: processes[keys[i]].type === 2 ? 'Sinistro' : processes[keys[i]].state === 3 ? 'Apólice' : 'Simulação',
                    type: processes[keys[i]].type,
                    lead: getDescription(currentProcess),
                    notificationType: getNotificationType(currentProcess),
                    title: processes[keys[i]] && processes[keys[i]].branch
                        ? branchesNames[processes[keys[i]].branch] : 'Seguro Vida',
                    subTitle: processes[keys[i]] && processes[keys[i]].branch && processes[keys[i]]
                        && processes[keys[i]].subBranch ? subBranchesNames[processes[keys[i]].branch][processes[keys[i]].subBranch] : '',
                    buttonLabel: getButtonLabel(currentProcess),
                    branch: processes[keys[i]].branch,
                    subBranch: processes[keys[i]].subBranch,
                    processId: processes[keys[i]].processId,
                    lastTimestamp: processes[keys[i]].lastTimestamp,
                    link: processes[keys[i]].state == 4 ? null : processes[keys[i]].state > 1 && processes[keys[i]].branch != 4 ? apoliceLink ? apoliceLink : '/apolice' : null
                });
            }
        }
        let sorter = (a, b) => a.lastTimestamp < b.lastTimestamp ? 1 : -1;
        notificationsNewSate.sort(sorter);
        if (number) {
            notificationsNewSate = notificationsNewSate.slice(0, number);
        }
    }
    return notificationsNewSate;
}

export function getNumberOfNotifications(processes, type) {
    let numberToReturn = 0;
    if (processes) {
        let keys = Object.keys(processes);
        for (let i = 0; i < keys.length; i++) {
            if (processes[keys[i]].state > 0) {
                if (type) {
                    if (processes[keys[i]].type === type) {
                        numberToReturn += 1;
                    }
                }
                else {
                    numberToReturn += 1;
                }
            }
        }
    }
    return numberToReturn;
}

function getButtonLabel(process) {
    let description = "";
    if (process) {
        if (process.type === 2) {
            if (process.currentPageId === 'endClaim' || process.currentPageId === 'endClaimNoReport') {
                description = null;
            }
            else if (process.currentPageId === 'houseRoberyAwaitingReport') {
                description = 'Inserir relatório';
            }
            else {
                description = 'Continuar';
            }
        }
        else {
            if (process.state == 4) {
                description = null;
            }
            else if (process.state > 1 && process.branch != 4) {
                description = 'Consultar';
            }
            else {
                description = 'Continuar';
            }
        }
    }

    return description;
}

function getNotificationType(process) {
    let type = 1;
    if (process) {
        if (process.type === 2) {
            if (process.currentPageId === 'endClaim') {
                type = 3;
            }
            else if (process.currentPageId === 'houseRoberyAwaitingReport' || process.currentPageId === 'endClaimNoReport') {
                type = 2;
            }
            else {
                type = 1;
            }
        }
        else {
            if (process.state === 1) {
                type = 1;
            }
            else if (process.state === 3) {
                type = 3;
            }
            else if (process.state === 4) {
                type = 4;
            }
            else {
                type = 3;
            }
        }
    }
    return type;
}

function getDescription(process) {
    let description = "";
    if (process) {
        if (process.type === 2) {
            if (process.currentPageId === 'endClaim') {
                description = 'Enviei a sua participação para a ' + process.userTexts.insurer;
            }
            else if (process.currentPageId === 'houseRoberyAwaitingReport' || process.currentPageId === 'endClaimNoReport') {
                description = 'Preciso da cópia do relatório da polícia';
            }
            else {
                description = 'Termine a sua participação de sinistro';
            }
        }
        else {
            if (process.state === 1) {
                description = "Guardei a sua simulação. Vamos Continuar?";
            }
            else if (process.state === 3) {
                if (process.processMessage) {
                    description = process.processMessage;
                }
                else {
                    description = "A sua apólice foi emitida com sucesso";
                }
            }
            else if (process.state === 4) {
                description = process && process.email ? "Vamos enviar a sua proposta para " + process.email : "Vamos enviar a sua proposta para o seu email";
            }
            else {
                if (process.branch == 4) {
                    let carDesc = process && process.userValues && process.userValues.brand ? process.userValues.brand : "carro" ;
                    description = 'Parabéns pela compra do seu seguro para o seu ' 
                    + carDesc
                    + '. Já enviei toda a informação para a ' + process.selectedInsurerName;
                }
                else {
                    description = 'Parabéns pela compra do seu seguro. Já enviei toda a informação para a ' + process.selectedInsurerName;
                }
            }
        }
    }
    return description;
}