export const houseClaimFlow = {
    begin:
    {
        sequence: [
            {
                type: 'AND',
                result: 'houseClaimType',
                conditions: [
                    {
                        comparator: 'NOT_EMPTY',
                        values: [
                            {
                                type: 'userVariable',
                                value: 'insuranceNumber',
                            },
                        ]
                    }]
            },
            {
                result: 'insuranceAtUthere',
            }]
    },
    laraIntroMessage:
    {
        sequence: [
            {
                type: 'AND',
                result: ['luisIntroMessageHouseName'],
                conditions: [
                    {
                        comparator: 'NOT_EMPTY',
                        values: [
                            {
                                type: 'userVariable',
                                value: 'insuranceNumber',
                            },
                        ]
                    }]
            },
            {
                result: ['luisIntroMessage'],
            }]
    },
    pages: {
        insuranceAtUthere: {
            type: 'findInsurance',
            nextPageId: 'houseClaimType',
            laraMessage: ['luisInsuranceAtUthere'],
            userMessage: 'userNifClaim',
            options : {
                branch : [3]
            }
        },
        houseClaimType: {
            type: 'radio',
            allowBack: false,
            nextPageId: {
                sequence: [
                    {
                        result: 'houseWaterWhen',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseClaimType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                            {
                                comparator: 'NOT_EMPTY',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceNumber',
                                    },
                                ]
                            },
                            {
                                comparator: 'UTHERE_USER',
                            }
                        ],
                    },
                    {
                        result: 'houseDamageClaimWhatEquipment',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseClaimType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                            {
                                comparator: 'NOT_EMPTY',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceNumber',
                                    },
                                ]
                            },
                            {
                                comparator: 'UTHERE_USER',
                            }
                        ],
                    },
                    {
                        result: 'houseRoberyDontHaveCoverage',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseClaimType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                            {
                                comparator: 'NOT_EMPTY',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceNumber',
                                    },
                                ]
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'hasExtraCoverage',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            },
                            {
                                comparator: 'UTHERE_USER',
                            },
                        ],
                    },
                    {
                        result: 'houseRoberyTakeAnything',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseClaimType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                            {
                                comparator: 'NOT_EMPTY',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceNumber',
                                    },
                                ]
                            },
                            {
                                comparator: 'UTHERE_USER',
                            },
                        ],
                    },
                    {
                        result: 'houseOtherWhen',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseClaimType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 4,
                                    },
                                ]
                            },
                            {
                                comparator: 'NOT_EMPTY',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceNumber',
                                    },
                                ]
                            },
                            {
                                comparator: 'UTHERE_USER',
                            },
                        ],
                    },
                    {
                        result: 'houseOtherWhen',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseClaimType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 5,
                                    },
                                ]
                            },
                            {
                                comparator: 'NOT_EMPTY',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceNumber',
                                    },
                                ]
                            },
                            {
                                comparator: 'UTHERE_USER',
                            },
                        ],
                    },
                    {
                        result: 'addressConfirm',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'HAS_ACTIVE_HOUSE_INSURANCE_ONE',
                            },
                        ],
                    },
                    {
                        result: 'addressConfirm',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'HAS_ACTIVE_HOUSE_INSURANCE_MULTI',
                            },
                        ],
                    },
                    {
                        result: 'address',
                    }
                ]
            },
            laraMessage: {
                sequence:
                    [
                        {
                            type: 'AND',
                            result: ['luisHouseClaimType'],
                            conditions: [
                                {
                                    comparator: 'NOT_EMPTY',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'insuranceNumber',
                                        },
                                    ]
                                }]
                        },
                        {
                            result: 'luisHouseClaimTypeName',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'UTHERE_USER',
                                },
                            ],
                        },
                        {
                            result: 'luisHouseClaimType',
                        }]
            },
            userMessage:
            {
                sequence:
                    [
                        {
                            result: 'userHouseClaimType1',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseClaimType',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'userHouseClaimType2',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseClaimType',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'userHouseClaimType3',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseClaimType',
                                        },
                                        {
                                            type: 'constant',
                                            value: 3,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'userHouseClaimType4',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseClaimType',
                                        },
                                        {
                                            type: 'constant',
                                            value: 4,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'userHouseClaimType5',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseClaimType',
                                        },
                                        {
                                            type: 'constant',
                                            value: 5,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'userHouseClaimType',
                        }]
            },
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblHouseClaimType1',

                    },
                    {
                        value: 2,
                        label: 'lblHouseClaimType2',

                    },
                    {
                        value: 3,
                        label: 'lblHouseClaimType3',

                    },
                    {
                        value: 4,
                        label: 'lblHouseClaimType4',

                    },
                    {
                        value: 5,
                        label: 'lblHouseClaimType5',

                    },
                ]
            }
        },
        addressConfirm: {
            type: 'chooseAddress',
            nextPageId: {
                sequence: [
                    {
                        result: 'address',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'addressConfirm',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'addressConfirm',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'houseWaterWhen',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseClaimType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'houseDamageClaimWhatEquipment',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseClaimType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'houseRoberyTakeAnything',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseClaimType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'houseOtherWhen',
                    }]
            },
            laraMessage:
            {
                sequence:
                    [
                        {
                            result: ['luisConfirmAddress'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'HAS_ACTIVE_HOUSE_INSURANCE_ONE',
                                },
                            ],
                        },
                        {
                            result: ['luisConfirmAddresses'],
                        }]
            },
            userMessage: 'userAddressConfirm',
        },
        address: {
            type: 'postal',
            laraMessage: ['luisAddress4'],
            userMessage: 'userAddressClaim',
            nextPageId: {
                sequence: [
                    {
                        result: 'houseWaterWhen',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseClaimType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'houseDamageClaimWhatEquipment',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseClaimType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'houseRoberyTakeAnything',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseClaimType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'houseOtherWhen',
                    }]
            },
        },
        houseDamageClaimWhatEquipment: {
            type: 'input',
            laraMessage: ['luisHouseDamageClaimWhatEquipment'],
            nextPageId: 'houseDamageWhen',
            userMessage: 'userHouseDamageClaimWhatEquipment',
            options: {
                type: 'multi',
                decimals: 0,
                label: 'lblEquipments',
            }
        },
        houseWaterWhen: {
            type: 'date',
            allowBack: true,
            laraMessage: ['luisHouseWaterWhen1'],
            userMessage: 'userWaterWhen',
            nextPageId: 'houseWaterAffectedZones',
            options: {
                default:
                {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 0,
                            },
                        ]
                    },
                    day: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'dayToday',
                            },
                            {
                                type: 'constant',
                                value: 1,
                            },
                        ]
                    },
                    month: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'monthToday',
                            },
                            {
                                type: 'constant',
                                value: 0,
                            },
                        ]
                    }
                },
                max: {
                    year: {
                        operation: 'PLUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 0,
                            },
                        ]
                    }
                },
                min: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 1,
                            },
                        ]
                    }
                },
                validation: {
                    type: 'NO_FUTURE',
                    text: 'Opps! Parece que escolheu uma data no futuro. Tente novamente'
                }
            }
        },
        houseDamageWhen: {
            type: 'date',
            allowBack: true,
            laraMessage: ['luisHouseDamageWhen'],
            userMessage: 'userHouseDamageWhen',
            nextPageId: 'houseDamageSeenByTechnician',
            options: {
                default:
                {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 0,
                            },
                        ]
                    },
                    day: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'dayToday',
                            },
                            {
                                type: 'constant',
                                value: 1,
                            },
                        ]
                    },
                    month: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'monthToday',
                            },
                            {
                                type: 'constant',
                                value: 0,
                            },
                        ]
                    }
                },
                max: {
                    year: {
                        operation: 'PLUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 0,
                            },
                        ]
                    }
                },
                min: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 1,
                            },
                        ]
                    }
                },
                validation: {
                    type: 'NO_FUTURE',
                    text: 'Opps! Parece que escolheu uma data no futuro. Tente novamente'
                }
            }
        },
        houseOtherWhen: {
            type: 'date',
            allowBack: true,
            laraMessage: ['luisHouseWaterWhen1'],
            userMessage: 'userOtherWhen',
            nextPageId: 'houseOtherClaimDescription',
            options: {
                default:
                {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 0,
                            },
                        ]
                    },
                    day: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'dayToday',
                            },
                            {
                                type: 'constant',
                                value: 1,
                            },
                        ]
                    },
                    month: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'monthToday',
                            },
                            {
                                type: 'constant',
                                value: 0,
                            },
                        ]
                    }
                },
                max: {
                    year: {
                        operation: 'PLUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 0,
                            },
                        ]
                    }
                },
                min: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 1,
                            },
                        ]
                    }
                },
                validation: {
                    type: 'NO_FUTURE',
                    text: 'Opps! Parece que escolheu uma data no futuro. Tente novamente'
                }
            }
        },
        houseRoberyDontHaveCoverage: {
            type: 'exit',
            disableExitConfirm: true,
            last: true,
            allowBack: false,
            nextPageId: 'exit',
            laraMessage: ['luisHouseRoberyDontHaveCoverage'],
            userMessage: 'userHouseRoberyDontHaveCoverage',
            options: {
                label: 'lblExit',
                cancel: true,
            }
        },
        houseRoberyTakeAnything: {
            type: 'radio',
            nextPageId: 'houseRoberyWhen',
            laraMessage: ['luisHouseRoberyTakeAnything'],
            userMessage: 'userHouseRoberyTakeAnything',
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblYes',

                    },
                    {
                        value: 0,
                        label: 'lblNo',

                    },
                ]
            }
        },
        houseRoberyWhen: {
            type: 'date',
            allowBack: true,
            laraMessage:
            {
                sequence:
                    [{
                        result: ['luisHouseRoberyWhen2'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseRoberyTakeAnything',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['luisHouseRoberyWhen'],
                    }]
            },
            userMessage: 'userHouseRoberyWhen',
            nextPageId: 'houseRoberyPolice',
            options: {
                default:
                {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 0,
                            },
                        ]
                    },
                    day: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'dayToday',
                            },
                            {
                                type: 'constant',
                                value: 1,
                            },
                        ]
                    },
                    month: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'monthToday',
                            },
                            {
                                type: 'constant',
                                value: 0,
                            },
                        ]
                    }
                },
                max: {
                    year: {
                        operation: 'PLUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 0,
                            },
                        ]
                    }
                },
                min: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 1,
                            },
                        ]
                    }
                },
                validation: {
                    type: 'NO_FUTURE',
                    text: 'Opps! Parece que escolheu uma data no futuro. Tente novamente'
                }
            }
        },
        houseDamageSeenByTechnician: {
            type: 'radio',
            nextPageId: {
                sequence: [
                    {
                        result: 'houseDamageEquipmentsInHouse',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseDamageSeenByTechnician',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'houseDamageHasRelatory',
                    }]
            },
            laraMessage: ['luisHouseDamageSeenByTechnician'],
            userMessage: 'userHouseDamageSeenByTechnician',
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblYes',

                    },
                    {
                        value: 0,
                        label: 'lblNo',

                    },
                ]
            }
        },
        houseWaterAffectedZones: {
            laraMessage: ['luisHouseWaterAffectedZones3'],
            userMessage: 'userHouseWaterAffectedZones',
            nextPageId: {
                sequence: [
                    {
                        result: 'houseWaterClaimDescription',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'CONTAINS_TRUE',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseWaterAffectedZones',
                                    },
                                    {
                                        type: 'constant',
                                        value: ['Casa de banho'],
                                    },
                                ]
                            },
                            {
                                comparator: 'OBJECT_TRUE_LENGTH_EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseWaterAffectedZones',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'houseWaterClaimDescription',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'hasBensCoverage',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'houseWaterDamagedAssets',
                    }]
            },
            type: 'fullSwitch',
            options: {
                label: 'lblAffectedZones',
                list: 'houseZonesList',
            }
        },
        houseDamageHasRelatory: {
            type: 'radio',
            nextPageId: 'houseDamageEquipmentsInHouse',
            laraMessage: ['luisHouseDamageHasRelatory'],
            userMessage: 'userHouseDamageHasRelatory',
            userMessageOptions: {
                valueId: 'houseRoberyHasRelatoryAttach',
                sequence: [
                    {
                        result: true,
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseDamageHasRelatory',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        result: false
                    }],
                type: 'window',
                items: [
                    {
                        id: 'houseRoberyHasRelatoryAttach',
                        type: 'attach',
                        titleLabel: 'Relatório',
                    },
                ]
            },
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblYes',
                        followup: [
                            {
                                id: 'houseRoberyHasRelatoryAttach',
                                title: 'Tire por favor uma fotografia do relatório de polícia',
                                window: true,
                                type: 'attach',
                                options: {
                                    title: 'Relatório',
                                    description: [
                                        'Tire <b>fotografia</b> ou anexe o <b>relatório</b>',
                                        'Inclua <b>todas as páginas</b>'
                                    ]
                                }
                            }]

                    },
                    {
                        value: 0,
                        label: 'lblNo',

                    },
                ]
            }
        },
        houseRoberyPolice: {
            type: 'radio',
            nextPageId: {
                sequence: [
                    {
                        result: 'houseRoberyAwaitingReport',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseRoberyPolice',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'houseRoberyHasRelatory',
                    }]
            },
            laraMessage: ['luisHouseRoberyPolice'],
            userMessage: 'userHouseRoberyPolice',
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblYes',

                    },
                    {
                        value: 0,
                        label: 'lblNo',

                    },
                ]
            }
        },
        houseWaterDamagedAssets: {
            type: 'radio',
            nextPageId: 'houseWaterClaimDescription',
            laraMessage: ['luisHouseWaterDamagedAssets'],
            userMessage:
            {
                sequence:
                    [
                        {
                            result: 'userHouseWaterWhatDamagedAssets',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseWaterDamagedAssets',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'userHouseWaterDamagedAssets',
                        }]
            },
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblYes',
                        followup: [
                            {
                                id: 'houseWaterWhatDamagedAssets',
                                header: 'Descreva os bens que foram danificados',
                                type: 'text',
                                options: {
                                    maxChars: 160,
                                    type: 'textarea',
                                    label: 'Descrição',
                                }
                            }]

                    },
                    {
                        value: 0,
                        label: 'lblNo',

                    },
                ]
            }
        },
        houseDamageEquipmentsInHouse: {
            type: 'radio',
            nextPageId: 'houseDamageHasBudget',
            laraMessage: {
                sequence:
                    [
                        {
                            result: ['luisHouseDamageEquipmentsInHouse'],
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseDamageSeenByTechnician',
                                        },
                                        {
                                            type: 'constant',
                                            value: 0,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['luisHouseDamageEquipmentsInHouse2', 'luisHouseDamageEquipmentsInHouse'],
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseDamageHasRelatory',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['luisHouseDamageEquipmentsInHouse3', 'luisHouseDamageEquipmentsInHouse'],
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseDamageHasRelatory',
                                        },
                                        {
                                            type: 'constant',
                                            value: 0,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'luisHouseDamageEquipmentsInHouse',
                        }]
            },
            userMessage: 'userHouseDamageEquipmentsInHouse',
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblYes',

                    },
                    {
                        value: 0,
                        label: 'lblNo',

                    },
                ]
            }
        },
        houseRoberyAwaitingReport: {
            type: 'next',
            laraMessage: ['houseRoberyClaimDescription2', 'houseRoberyClaimDescription3'],
            userMessage: "userHouseRoberyHasRelatoryAttach",
            nextPageId: 'houseRoberyHasRelatory',
            options: {
                label: 'lblDonePoliceReport',
            }
        },
        houseRoberyHasRelatory: {
            type: 'radio',
            nextPageId: 'houseRoberyClaimDescription',
            laraMessage: ['luisHouseRoberyHasRelatory'],
            userMessage: 'userHouseRoberyHasRelatory',
            userMessageOptions: {
                valueId: 'houseRoberyHasRelatoryAttach',
                sequence: [
                    {
                        result: true,
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseRoberyHasRelatory',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        result: false
                    }],
                type: 'window',
                items: [
                    {
                        id: 'houseRoberyHasRelatoryAttach',
                        type: 'attach',
                        titleLabel: 'Relatório',
                    },
                ]
            },
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblYes',
                        followup: [
                            {
                                id: 'houseRoberyHasRelatoryAttach',
                                window: true,
                                type: 'attach',
                                options: {
                                    title: 'Relatório polícia',
                                    description: [
                                        'Tire por favo uma <b>fotografia</b> ou anexe o <b>relatório</b> da polícia',
                                        'Inclua <b>todas as páginas</b>'
                                    ]
                                }
                            }]

                    },
                    {
                        value: 0,
                        label: 'lblNo',

                    },
                ]
            }
        },
        houseWaterClaimDescription: {
            type: 'input',
            laraMessage: ['luisHouseWaterClaimDescription1', 'luisHouseWaterClaimDescription2'],
            nextPageId: 'houseWaterRepairedDamagedAssets',
            userMessage: 'userHouseWaterClaimDescription',
            options: {
                type: 'multi',
                decimals: 0,
                label: 'lblDescription',
            }
        },
        houseRoberyClaimDescription: {
            type: 'input',
            laraMessage:
            {
                sequence:
                    [
                        {
                            result: ['houseRoberyClaimDescription6', 'houseRoberyClaimDescription'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseRoberyHasRelatory',
                                        },
                                        {
                                            type: 'constant',
                                            value: 0,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['houseRoberyClaimDescription4'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseRoberyPolice',
                                        },
                                        {
                                            type: 'constant',
                                            value: 0,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['houseRoberyClaimDescription5', 'houseRoberyClaimDescription'],
                        }]
            },
            nextPageId: {
                sequence: [
                    {
                        result: 'claimReview',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'HAS_ACTIVE_HOUSE_AT_LEAST_ONE',
                            },
                            {
                                comparator: 'NOT_EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseAddress',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                            {
                                comparator: 'NOT_EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseAddress',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'insurer',
                    }]
            },
            userMessage: 'userHouseRoberyClaimDescription',
            options: {
                type: 'multi',
                decimals: 0,
                label: 'lblDescription',
            }
        },
        houseOtherClaimDescription: {
            type: 'input',
            laraMessage: ['luisHouseWaterClaimDescription1'],
            nextPageId: {
                sequence: [
                    {
                        result: 'claimReview',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'HAS_ACTIVE_HOUSE_AT_LEAST_ONE',
                            },
                            {
                                comparator: 'NOT_EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseAddress',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                            {
                                comparator: 'NOT_EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseAddress',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'insurer',
                    }]
            },
            userMessage: 'userHouseOtherClaimDescription',
            options: {
                type: 'multi',
                decimals: 0,
                label: 'lblDescription',
            }
        },
        houseWaterRepairedDamagedAssets: {
            type: 'radio',
            nextPageId: 'houseWaterHasBudget',
            laraMessage: ['luisHouseWaterRepairedDamagedAssets'],
            userMessage: 'userHouseWaterRepairedDamagedAssets',
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblYes',

                    },
                    {
                        value: 0,
                        label: 'lblNo',

                    },
                ]
            }
        },
        houseDamageHasBudget: {
            type: 'radio',
            nextPageId: 'houseDamageClaimDescription',
            laraMessage: ['luisHouseDamageHasBudget'],
            userMessage:
            {
                sequence:
                    [
                        {
                            result: 'userHouseDamageBudget',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseDamageHasBudget',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'userHouseDamageHasBudget',
                        }]
            },
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblYes',
                        followup: [
                            {
                                id: 'houseDamageBudget',
                                header: 'Qual o valor do orçamento?',
                                type: 'text',
                                options: {
                                    maxChars: 160,
                                    type: 'currency',
                                    label: 'Valor',
                                }
                            }]

                    },
                    {
                        value: 0,
                        label: 'lblNo',

                    },
                ]
            }
        },
        houseDamageClaimDescription: {
            type: 'input',
            laraMessage:
            {
                sequence:
                    [
                        {
                            result: ['luishouseDamageClaimDescription2', 'luishouseDamageClaimDescription'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseDamageHasBudget',
                                        },
                                        {
                                            type: 'constant',
                                            value: 0,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['luishouseDamageClaimDescription'],
                        }]
            },
            nextPageId: {
                sequence: [
                    {
                        result: 'claimReview',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'HAS_ACTIVE_HOUSE_AT_LEAST_ONE',
                            },
                            {
                                comparator: 'NOT_EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseAddress',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                            {
                                comparator: 'NOT_EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseAddress',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'insurer',
                    }]
            },
            userMessage: 'userHouseDamageClaimDescription',
            options: {
                type: 'multi',
                decimals: 0,
                label: 'lblDescription',
            }
        },
        houseWaterHasBudget: {
            type: 'radio',
            nextPageId: {
                sequence: [
                    {
                        result: 'claimReview',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'HAS_ACTIVE_HOUSE_AT_LEAST_ONE',
                            },
                            {
                                comparator: 'NOT_EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseAddress',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                            {
                                comparator: 'NOT_EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseAddress',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'insurer',
                    }]
            },
            laraMessage: ['luisHouseWaterHasBudget'],
            userMessage:
            {
                sequence:
                    [
                        {
                            result: 'userHouseWaterBudget',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseWaterHasBudget',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'userHouseWaterHasBudget',
                        }]
            },
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblYes',
                        followup: [
                            {
                                id: 'houseWaterBudget',
                                header: 'Qual o valor do orçamento?',
                                type: 'text',
                                options: {
                                    maxChars: 160,
                                    type: 'currency',
                                    label: 'Valor',
                                }
                            }]

                    },
                    {
                        value: 0,
                        label: 'lblNo',

                    },
                ]
            }
        },
        insurer: {
            laraMessage: ['luisInsurer3'],
            userMessage: 'userInsurer',
            nextPageId: {
                sequence: [
                    {
                        result: 'claimReview',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceAtUthere',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'phone',
                    }]
            },
            type: 'fullRadio',
            options: {
                label: 'lblInsurerName',
                list: 'insurerHomeList',
                otherValues: { insurerGender: 'gender' },
            }
        },
        phone: {
            type: 'input',
            laraMessage: ['luisPhone'],
            nextPageId: 'claimReview',
            userMessage: 'userPhone',
            options: {
                type: 'tel',
                label: 'lblPhone',
            }
        },
        claimReview: {
            type: 'review',
            laraMessage: ['luisClaimReview', 'luisClaimReview2'],
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'sendParticipation',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'UTHERE_USER',
                                }
                            ],
                        },
                        {
                            result: 'login',
                        }
                    ]
            },
            userMessage: 'userClaimReview',
            options: {
                reviewFormId: {
                    sequence:
                        [
                            {
                                result: 'claimRoberyDamageReview',
                                type: 'AND',
                                conditions: [
                                    {
                                        comparator: 'EQUALS',
                                        values: [
                                            {
                                                type: 'userVariable',
                                                value: 'houseClaimType',
                                            },
                                            {
                                                type: 'constant',
                                                value: 3,
                                            },
                                        ]
                                    }
                                ],
                            },
                            {
                                result: 'claimHouseDamageReview',
                                type: 'AND',
                                conditions: [
                                    {
                                        comparator: 'EQUALS',
                                        values: [
                                            {
                                                type: 'userVariable',
                                                value: 'houseClaimType',
                                            },
                                            {
                                                type: 'constant',
                                                value: 2,
                                            },
                                        ]
                                    }
                                ],
                            },
                            {
                                result: 'claimWaterDamageReview',
                                type: 'AND',
                                conditions: [
                                    {
                                        comparator: 'EQUALS',
                                        values: [
                                            {
                                                type: 'userVariable',
                                                value: 'houseClaimType',
                                            },
                                            {
                                                type: 'constant',
                                                value: 1,
                                            },
                                        ]
                                    }
                                ],
                            },
                            {
                                result: 'claimHouseFireDamageReview',
                                type: 'AND',
                                conditions: [
                                    {
                                        comparator: 'EQUALS',
                                        values: [
                                            {
                                                type: 'userVariable',
                                                value: 'houseClaimType',
                                            },
                                            {
                                                type: 'constant',
                                                value: 4,
                                            },
                                        ]
                                    }
                                ],
                            },
                            {
                                result: 'claimHouseOtherDamageReview',
                            }
                        ]
                },
            },
            userMessageOptions: {
                valueId: 'claimReview',
                type: 'window',
                items: [
                    {
                        id: 'claimReview',
                        type: 'review',
                        titleLabel: 'Declaração',
                        reviewFormId: {
                            sequence:
                                [
                                    {
                                        result: 'claimWaterDamageReview',
                                        type: 'AND',
                                        conditions: [
                                            {
                                                comparator: 'EQUALS',
                                                values: [
                                                    {
                                                        type: 'userVariable',
                                                        value: 'houseClaimType',
                                                    },
                                                    {
                                                        type: 'constant',
                                                        value: 1,
                                                    },
                                                ]
                                            }
                                        ],
                                    },
                                    {
                                        result: 'claimHouseDamageReview',
                                        type: 'AND',
                                        conditions: [
                                            {
                                                comparator: 'EQUALS',
                                                values: [
                                                    {
                                                        type: 'userVariable',
                                                        value: 'houseClaimType',
                                                    },
                                                    {
                                                        type: 'constant',
                                                        value: 2,
                                                    },
                                                ]
                                            }
                                        ],
                                    },
                                    {
                                        result: 'claimRoberyDamageReview',
                                        type: 'AND',
                                        conditions: [
                                            {
                                                comparator: 'EQUALS',
                                                values: [
                                                    {
                                                        type: 'userVariable',
                                                        value: 'houseClaimType',
                                                    },
                                                    {
                                                        type: 'constant',
                                                        value: 3,
                                                    },
                                                ]
                                            }
                                        ],
                                    },
                                    {
                                        result: 'claimHouseOtherDamageReview',
                                    }
                                ]
                        },
                    },
                ]
            },
        },
        login: {
            type: 'login',
            saveUser: true,
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'endClaimNoReport',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'ARRAY_EMPTY',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: ['claimReview', 'houseRoberyHasRelatoryAttach', 'value'],
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'endClaim',
                        }
                    ]
            },
            laraMessage: ['luisLogin0'],
            userMessage: 'userLogin',
            options:
            {
                askName: true,
                label: 'lblSendClaim',
            }
        },
        sendParticipation: {
            type: 'next',
            laraMessage: ['luisSendParticipation'],
            userMessage: "userSendParticipation",
            submit: {
                label: "lblSendingClaim",
                state: 2
            },
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'endClaimNoReport',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'ARRAY_EMPTY',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: ['claimReview', 'houseRoberyHasRelatoryAttach', 'value'],
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'endClaim',
                        }
                    ]
            },
            options: {
                label: 'lblSendClaim',
            }
        },
        endClaimNoOption: {
            type: 'exit',
            last: true,
            allowBack: true,
            laraMessage: ['luisEndClaimNoOption'],
            options: {
                label: 'lblAteJa',
            }
        },
        endClaimNoReport: {
            type: 'exit',
            disableExitConfirm: true,
            last: true,
            allowBack: false,
            changeState: 2,
            laraMessage: ['luisEndClaimNoReport'],
            options: {
                label: 'lblAteJa',
            }
        },
        endClaim: {
            type: 'exit',
            disableExitConfirm: true,
            last: true,
            allowBack: false,
            changeState: 2,
            laraMessage:
            {
                sequence:
                    [{
                        result: ['luisEndClaim0', 'luisEndClaim'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'LAST_PAGE_DIFFERENT',
                                values: [{
                                    type: 'constant',
                                    value: "login",
                                },]
                            },
                        ],
                    },
                    {
                        result: ['luisEndClaim0', 'luisEndClaim'],
                    }]
            },
            options: {
                label: 'lblAteJa',
            }
        },
        exit: {
            type: 'exit',
            disableExitConfirm: true,
            last: true,
            allowBack: false,
            changeState: 2,
            laraMessage:
            {
                sequence:
                    [{
                        result: ['luisWillAddCoverage'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseRoberyDontHaveCoverage',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: ['luisWillNotAddCoverage'],
                    }]
            },
            options: {
                label: 'lblExit',
                cancel: true,
            }
        },
    }
}