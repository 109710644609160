import './HomeMainGeneral.scss';
import useNotifications from '../../../hooks/useNotifications';
import HomeNotifications from '../../home/home-notifications/HomeNotifications';
import autoImage from '../../../assets/images/home/auto-banner-s.png';
import homeImage from '../../../assets/images/home/home-icon.png';
import lifeImage from '../../../assets/images/home/life-banner.png';
import healthImage from '../../../assets/images/home/saude-icon.png';

/**
 * HomeHeader
 */
const secure = [
    {
        "name": "Automóvel",
        "image": autoImage,
        "link": "https://uthere.eu/simulacao?branch=4"
    },
    {
        "name": "Multirriscos Habitação",
        "image": homeImage,
        "link": "https://uthere.eu/simulacao?branch=3"
    },
    {
        "name": "Vida",
        "image": lifeImage,
        "link": "https://uthere.eu/simulacao?branch=1"
    },
    {
        "name": "Saúde",
        "image": healthImage,
        "link": null
    },
];

function HomeMainGeneral() {
    const notifications = useNotifications();
    return (
        <div className='home-main-general-container'>
            {notifications && notifications.length > 0 ?
                <div className='home-main-general-notifications-container'>
                    <HomeNotifications hideMore={false} />
                </div> :
                null}
            <div className='home-main-general-container-text'>
                <div className='home-main-general-container-text-main'>
                    <h1><span className='bold'>Ajudamos a <span className="nowrap">escolher o</span></span><br/>melhor seguro para si</h1>
                </div>
                <div className='home-main-general-container-text-sec'>
                    <h4>Simule e conheça a nossa recomendação <span>em segundos</span></h4>
                </div>
            </div>
            <div className="home-main-general-secure-list">
                {
                    secure.map((item, index) => (
                        <a href={item.link} draggable="false" key={index} className={`home-main-general-secure-list-item ${item.link ? 'item-simulate' : 'item-coming-soon'}`}>
                            <figure>
                                <img src={item.image} alt={item.name} draggable="false" />
                            </figure>
                            <p>{item.name}</p>
                            <span className={item.link ? 'simulate' : 'coming-soon'}>{item.link ? 'Simular' : 'Brevemente'}</span>
                        </a>
                    ))
                }
            </div>
        </div>
    );
}
export default HomeMainGeneral;
