import './HomeNotifications.scss';
import { useState } from 'react';
import NotifcationList from './components/notification-list/NotificationList';
import useNotifications from '../../../hooks/useNotifications';
import { markNotificationsAsRead } from '../../../services/ProcessesServices';

import UthereNotification from '../../common/uthere-notification/UthereNotification';

/**
* Notifications
*/
function HomeNotifications(props) {

    const notifications = useNotifications(props);
    const [notificationsModal, setNotificationsModal] = useState(false);

    const onNotificationList = () => {
        setNotificationsModal(true);
    }

    const closeNotificationsModal = () => {
        setNotificationsModal(false);
    }

    const closeNotifications = () => {
        markNotificationsAsRead();
    }

    return (
        <div className='notifications-container'>
            <div onClick={closeNotifications} className="notifications-containner-delete">
                <svg width="auto" height="auto" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.33203 16.6665L16.6654 3.33316" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M3.33203 3.3335L16.6654 16.6668" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>

            {notifications.map((item, i) => (
                !props.type || item.type === props.type ?
                    <div className={`${i > 0 ? "notifications-containner-other" : ""}`} key={i}
                        style={
                            i === 1 ? { top: 5 + "px", zIndex: i * 1, right: 10 + "px" } :
                                i >= 2 ? { top: 10 + "px", zIndex: i * 1, right: 20 + "px" } :
                                    null
                        }>
                        <UthereNotification notification={notifications[0]} />
                    </div> : null
            ))}
            {!props.hideMore && notifications && notifications.length > 1 ?
                <div onClick={onNotificationList} className="notifications-containner-more">
                    <div>Tem mais {notifications.length - 1} {notifications.length > 2 ? "notificações" : "notificação"}</div>
                    <svg width="19" height="27" viewBox="0 0 19 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.6236 15.4801C18.8703 15.215 18.8703 14.785 18.6236 14.5199L14.6043 10.1989C14.3576 9.93371 13.9577 9.93371 13.7111 10.1989C13.4644 10.464 13.4644 10.8939 13.7111 11.1591L16.6522 14.321L1.44017 14.321C1.09136 14.321 0.808595 14.625 0.808595 15C0.808595 15.375 1.09136 15.679 1.44017 15.679L16.6522 15.679L13.7111 18.8409C13.4644 19.1061 13.4644 19.536 13.7111 19.8011C13.9577 20.0663 14.3576 20.0663 14.6043 19.8011L18.6236 15.4801Z" fill="#1F3DEB" />
                    </svg>

                </div>

                : null}

            {
                notificationsModal ?
                    <NotifcationList type={props.type} closeNotifications={closeNotificationsModal} />
                    : null
            }
        </div >
    );
};

export default HomeNotifications;