export const monthArray = [
    { id: 1, value: 'JAN' },
    { id: 2, value: 'FEV' },
    { id: 3, value: 'MAR' },
    { id: 4, value: 'ABR' },
    { id: 5, value: 'MAI' },
    { id: 6, value: 'JUN' },
    { id: 7, value: 'JUL' },
    { id: 8, value: 'AGO' },
    { id: 9, value: 'SET' },
    { id: 10, value: 'OUT' },
    { id: 11, value: 'NOV' },
    { id: 12, value: 'DEZ' },
];

export const weekArray = [
    { id: 0, value: 'Domingo' },
    { id: 1, value: 'Segunda-Feira' },
    { id: 2, value: 'Terça-Feira' },
    { id: 3, value: 'Quarta-Feira' },
    { id: 4, value: 'Quinta-Feira' },
    { id: 5, value: 'Sexta-Feira' },
    { id: 6, value: 'Sábado' },
];

const monthsPortugese = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

export function isWorkingDay() {
    let today = new Date();
    let day = today.getDay();
    if (day === 6 || day === 0) {
        return false;
    }
    else {
        return true;
    }
}

export function isNextDayWorkingDay() {
    let today = new Date();
    let day = today.getDay();
    if (day === 5 || day === 6) {
        return false;
    }
    else {
        return true;
    }
}

export function isHourAfter7PM() {
    let today = new Date();
    let hour = today.getHours();
    if (hour > 18) {
        return true;
    }
    else {
        return false;
    }
}

export function getNext2WorkingDay() {
    let today = new Date();
    let day = today.getDay();
    if (day === 5 || day === 6) {
        return weekArray[2].value;
    }
    else if (day === 4) {
        return weekArray[1].value;
    }
    else {
        return weekArray[day + 2].value;
    }
}

export function timestampToShortDate(timestamp) {
    let stringtoReturn = "";

    if (timestamp) {
        let date = new Date(timestamp);
        if (date && date.getDate() && date.getMonth() !== null && date.getMonth() !== undefined) {
            stringtoReturn = date.getDate() + " " + monthArray[date.getMonth()].value;
        }
    }
    return stringtoReturn;
}

export function isoToFullIso(iso) {
    let isoToreturn = iso;
    if (iso) {
        let splitIso = iso.split("-");
        if (splitIso.length === 3) {
            isoToreturn = splitIso[0] + "-" +
                (splitIso[1].length < 2 ? "0" + splitIso[1] : splitIso[1]) + "-" +
                (splitIso[2].length < 2 ? "0" + splitIso[2] : splitIso[2]);
        }
    }
    return isoToreturn;
}

/**
 * 
 * @param {*} timestamp 
 * @returns 
 */
export function getCurrentHoursMinutes(timestamp) {
    let date = timestamp ? new Date(timestamp) : new Date();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let hoursStr = hours < 10 ? '0' + hours : hours;
    let minutesStr = minutes < 10 ? '0' + minutes : minutes;
    return hoursStr + ':' + minutesStr;
}

export function isAlmostBirthDay(day, month) {
    if (day && month) {
        let today = new Date();
        let birthday = new Date("2000-" + month + "-" + day);
        let currentYear = today.getFullYear();
        let birthDayTime = birthday.setFullYear(currentYear);
        let todayTime = today.getTime();
        let todayPlus30 = today.setDate(today.getDate() + 30);
        return (birthDayTime > todayTime && birthDayTime < todayPlus30);
    }
    else {
        return false;
    }
}

export function isBirthDay(day, month) {
    if (day && month) {
        let today = new Date();
        let birthday = new Date("2000-" + month + "-" + day)
        if (today.getDate() === birthday.getDate() &&
            today.getMonth() === birthday.getMonth()) {
            return true;
        }
        else {
            return false;
        }
    } return false;
}

/**
 * 
 * @param {*} month 
 * @param {*} year 
 * @returns 
 */
export function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
}

export function fullDateToUthereDate(date) {
    if (date) {
        let day = date.getDate().toString();
        let month = (date.getMonth() + 1).toString();
        let year = date.getFullYear().toString();
        return (day.length < 2 ? "0" + day : day) + "-" +
            (month.length < 2 ? "0" + month : month) + "-" +
            year;
    }
    else {
        return null;
    }
}

export function uthereDateToIso(uthere) {
    if (uthere) {
        let dateUthereArray = uthere.split('-');
        if (dateUthereArray.length === 3) {
            let intermedy = (dateUthereArray[2].length < 2 ? "0" + dateUthereArray[2] : dateUthereArray[2]) + "-" +
                (dateUthereArray[1].length < 2 ? "0" + dateUthereArray[1] : dateUthereArray[1]) + "-" +
                dateUthereArray[0];
            if (isIsoDate(intermedy))
                return intermedy;
            else return null;
        }
    }
    return null
};

function isIsoDate(str) {
    if (!/^(\d{4})-(\d{2})-(\d{2})/.test(str)) return false;
    const d = new Date(str);
    return d instanceof Date && !isNaN(d); // valid date 
}

export function isoToUthereDate(iso) {
    try {
        if (iso) {
            let dateIsoArray = iso.split('-');
            return (dateIsoArray[2].length < 2 ? "0" + dateIsoArray[2] : dateIsoArray[2]) + "-" +
                (dateIsoArray[1].length < 2 ? "0" + dateIsoArray[1] : dateIsoArray[1]) + "-" +
                dateIsoArray[0];
        }
    } catch (e) { }
    return "";
};

export function normalizeIso(iso) {
    let dateIsoArray = iso.split('-');
    let day = dateIsoArray[2];
    let month = dateIsoArray[1];
    let year = dateIsoArray[0];
    if (day.length === 1) {
        day = "0" + day;
    }
    if (month.length === 1) {
        month = "0" + month;
    }
    return year + "-" + month + "-" + day;
}

export function isoToFullDate(iso) {
    let dateIsoArray = iso.split('-');
    let day = dateIsoArray[2].split('T')[0];

    let dayLength = day.length;
    if (dayLength === 2 && day[0] === '0') {
        day = day[1];
    }

    let monthPosition = parseInt(dateIsoArray[1]) - 1;
    return day + " de " + monthsPortugese[monthPosition] + " de " + dateIsoArray[0];
}
export function dateToFullDate(date) {
    let iso = date.toISOString();
    return isoToFullDate(iso);
};

export function calculateAge(birthday) {
    let ageDifMs = Date.now() - birthday.getTime();
    let ageDate = new Date(ageDifMs);
    let result = Math.abs(ageDate.getUTCFullYear() - 1970);
    return result;
}

export function isOuvertTime(){
    const currentTimeInPortugal = new Date().toLocaleString("en-US", { timeZone: "Europe/Lisbon" });
    const currentDate = new Date(currentTimeInPortugal);

    const startHour = 9;
    const endHour = 19;

    const currentHour = currentDate.getHours();

    if (currentHour >= startHour && currentHour < endHour) {
        return true;
    } else {
        return false;
    }
}

export function isMorningTime(){
    const currentTimeInPortugal = new Date().toLocaleString("en-US", { timeZone: "Europe/Lisbon" });
    const currentDate = new Date(currentTimeInPortugal);

    const startHour = 0;
    const endHour = 9;

    const currentHour = currentDate.getHours();

    if (currentHour >= startHour && currentHour < endHour) {
        return true;
    } else {
        return false;
    }
}

export function isBetweenFridayAndSunday() {
    const currentTimeInPortugal = new Date().toLocaleString("en-US", { timeZone: "Europe/Lisbon" });
    const currentDate = new Date(currentTimeInPortugal);
    if (currentDate.getDay() === 5 && currentDate.getHours() >= 19) {
        return true;
    }

    if (currentDate.getDay() === 0 && currentDate.getHours() >= 23) {
        return true;
    }
    return false;
}
