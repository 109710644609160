export const lifeCreditFlow = {
    begin: 'chooseInsuranceLife',
    laraIntroMessage:
    {
        sequence:
            [{
                result: ['laraIntroMessage'],
                type: 'OR',
                conditions: [
                    {
                        comparator: 'NOT_EMPTY',
                        values: [
                            {
                                type: 'userVariable',
                                value: 'name',
                            },
                        ]
                    }
                ],
            },
            {
                result: ['laraIntroMessage'],
            }]
    },
    pages: {
        chooseInsuranceLife: {
            type: 'chooseInsurance',
            laraMessage: ['laraChooseInsuranceLife'],
            desktopMessage : ['laraChooseInsuranceLife'],
            nextPageId: {
                sequence: [
                    {
                        result: 'continue',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'PROCESS_OF_SAME_BRANCH_SUB_BRANCH',

                            }
                        ],
                    },
                    {
                        result: 'whenHouseContract',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseInsuranceLife',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'bornDate',
                    }]
            },
            userMessage: {
                sequence:
                    [{
                        result: 'userRadioChooseInsurance2',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseInsuranceLife',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'userRadioChooseInsurance3',
                    }]
            },
            options: {
                valuesBranchMapping: {
                    1: { branch: 2, subBranch: 2 },
                    2: { branch: 2, subBranch: 1 },
                },
                items: [
                    {
                        value: 1,
                        label: 'lblRadioChooseInsurance1',

                    },
                    {
                        value: 2,
                        label: 'lblRadioChooseInsurance2',

                    },
                ]
            }
        },
        dummy: {},
        dummy2: {},
        dummy3: {},
        dummy4: {},
        dummy5: {},
        dummy6: {},
        dummy7: {},
        dummy8: {},
        dummy9: {},
        dummy10: {},
        dummy11: {},
        dummy12: {},
        dummy13: {},
    },

};
