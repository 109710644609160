import { doc, collection, onSnapshot } from "firebase/firestore";
import { getDb } from '../../firebase/UthereFirebase';
import { getCurrentProcessId } from "./ProcessLogic";
import { store } from "../../store/store";

let processesListeners = {};
let currentProposals = {};
let currentPercentage = {};

export function getCurrentProposal() {
    let processId = getCurrentProcessId();
    return currentProposals[processId];
}

export function getCurrentPercentage() {
    let processId = getCurrentProcessId();
    return currentPercentage[processId];
}

export function listenToProposal() {
    return new Promise(async function (resolve, reject) {
        try {
            let processId = getCurrentProcessId();
            if (processId) {
                if (!processesListeners[processId]) {
                    let db = await getDb();
                    const rpaProposalsRef = collection(db, "proposals");
                    processesListeners[processId] = onSnapshot(doc(rpaProposalsRef, processId), (doc) => {
                        try {
                            if (doc.data()) {
                                let currentData = getHomeProposalObject(doc.data());
                                if(currentData && currentData.totalPresent && currentData.total){
                                    currentPercentage[processId] = Math.round((currentData.totalPresent / currentData.total) * 100);
                                }
                                else {
                                    currentPercentage[processId] = 0;   
                                }
                                if (currentData.data && currentData.totalPresent === currentData.total) {
                                    currentProposals[processId] = currentData.data;
                                    let payload = {
                                        data: currentData.data,
                                        processId: processId
                                    }
                                    store.dispatch({ type: 'proposal/setProposal', payload: payload });
                                }
                            }
                        } catch (e) { }
                    });
                }
            }
            resolve();
        } catch (e) { resolve() }
    });
};

export function getHomeProposalObject(data) {
    if (data && data.rpaRequest) {
        let bens = data.rpaRequest.bens;
        let paredes = data.rpaRequest.paredes;
        let base = data.rpaRequest.allowBase;
        let configTranquilidade = data.rpaDataTrnquilidade;
        let configAllianz = data.rpaData;

        let totalObjectsPerInsurer = paredes ? 8 : 4;
        let totalPresent = 0;
        let objectToReturn = {
            franquias: [],
            periodoMinimo: 'M',
            bens: bens,
            base: base ? true : false,
            paredes: paredes,
            config: {},
            ok: true,
        };
        let objectToReturnTranquilidade = {
            franquias: [],
            periodoMinimo: 'M',
            bens: bens,
            base: base ? true : false,
            paredes: paredes,
            config: {},
            ok: true,
        };
        try {
            let periodosOk = { A: true, T: true, S: true, M: true };
            if (configTranquilidade && configTranquilidade.prices) {
                let franquiasKeys = Object.keys(configTranquilidade.prices);
                if (franquiasKeys.length < 2) {
                    objectToReturnTranquilidade.franquias = franquiasKeys;
                }
                else {
                    objectToReturnTranquilidade.franquias = ["150", "0"];
                }
                let bensKeys = [];
                let fenomenosKeys = ["fenomenos", "semFenomenos"]
                if (paredes) {
                    bensKeys = ["bens", "semBens"];
                }
                else {
                    bensKeys = ["bens"];
                }
                //Obtem periodos minimos
                for (let i = 0; i < franquiasKeys.length; i++) {
                    let currentFranquia = franquiasKeys[i];
                    objectToReturnTranquilidade.config[currentFranquia] = {};
                    for (let j = 0; j < bensKeys.length; j++) {
                        let currentBens = bensKeys[j];
                        objectToReturnTranquilidade.config[currentFranquia][currentBens] = {};
                        for (let k = 0; k < fenomenosKeys.length; k++) {
                            let currentFenomenos = fenomenosKeys[k];
                            let current = configTranquilidade.prices[franquiasKeys[i]][bensKeys[j]][fenomenosKeys[k]];
                            if (current) {
                                if (current.config) {
                                    objectToReturnTranquilidade.config[currentFranquia][currentBens][currentFenomenos] = current.config;
                                    let periodsKeys = Object.keys(current.config);
                                    for (let i = 0; i < periodsKeys.length; i++) {
                                        let currentPeriod = periodsKeys[i];
                                        let productKey = Object.keys(current.config[currentPeriod])[0];
                                        if (isNaN(current.config[currentPeriod][productKey].precoAnual)) {
                                            current.config[currentPeriod][productKey].precoAnual = null;
                                            periodosOk[currentPeriod] = false;
                                        }
                                    }

                                }
                                else {
                                    break;
                                }
                            }
                            else {
                                break;

                            }
                        }
                    }
                }
                objectToReturnTranquilidade.periodoMinimo = !periodosOk.M && periodosOk.T ? "T" : !periodosOk.T ? "A" : "M";
            }
        } catch (e) {
            objectToReturnTranquilidade.ok = false;
        }

        try {
            if (configAllianz) {
                let franquiasKeys = Object.keys(configAllianz);
                if (franquiasKeys.length < 2) {
                    objectToReturn.franquias = franquiasKeys;
                }
                else {
                    objectToReturn.franquias = ["150", "0"];
                }
                let bensKeys = [];
                let fenomenosKeys = ["fenomenos", "semFenomenos"]
                if (paredes && bens) {
                    bensKeys = ["bens", "semBens"];
                }
                else if (paredes) {
                    bensKeys = ["semBens"];
                }
                else {
                    bensKeys = ["bens"];
                }

                //Obtem periodos minimos
                for (let i = 0; i < franquiasKeys.length; i++) {
                    let currentFranquia = franquiasKeys[i];
                    objectToReturn.config[currentFranquia] = {};
                    for (let j = 0; j < bensKeys.length; j++) {
                        let currentBens = bensKeys[j];
                        objectToReturn.config[currentFranquia][currentBens] = {};
                        for (let k = 0; k < fenomenosKeys.length; k++) {
                            let currentFenomenos = fenomenosKeys[k];
                            let current = configAllianz[franquiasKeys[i]][bensKeys[j]][fenomenosKeys[k]];
                            if (current) {
                                totalPresent += 1;
                                objectToReturn.areaEdificio = current.areaEdificio;
                                objectToReturn.areaAnexos = current.areaAnexos;
                                objectToReturn.bensEdificio = current.bensEdificio;
                                objectToReturn.bensAnexo = current.bensAnexo;
                                objectToReturn.valorEdificio = current.valorEdificio;
                                objectToReturn.valorAnexo = current.valorAnexo;
                                if (current.periodoMinimo === "A") {
                                    objectToReturn.periodoMinimo = "A"
                                }
                                else if (current.periodoMinimo === "T" && objectToReturn.periodoMinimo === "M") {
                                    objectToReturn.periodoMinimo = "T";
                                }
                                else if (current.periodoMinimo === "S" && (objectToReturn.periodoMinimo === "T" || objectToReturn.periodoMinimo === "M")) {
                                    objectToReturn.periodoMinimo = "S";
                                }
                                if (current.config) {

                                    objectToReturn.config[currentFranquia][currentBens][currentFenomenos] = current.config;
                                }
                                else {
                                    break;
                                }
                            }
                            else {
                                break;

                            }
                        }
                    }
                }
            }
        }
        catch (e) {
            return null;
        }
        return {
            data: objectToReturn, total: totalObjectsPerInsurer, totalPresent: totalPresent
        }
    }
}


const planoAllianz = "Recomendo a compra do plano Proteção Mais da Allianz";
const textRecommendForHouse = "Entre todas as opções analisadas, esta é a que tem melhores coberturas e melhor preço para sua casa";
const textRecommendForGoods = "Entre todas as opções analisadas, esta é a que tem melhores coberturas e melhor preço para os seus bens";
const planWithSolarPanels = "Recomendo a compra do plano Protecção Mais da Allianz. Este plano também inclui coberturas para muros e jardins, e para os seus painéis solares";
const houseWithoutSolarPanels = "Recomendo a compra do plano Protecção Mais da Allianz. Este plano também inclui coberturas para muros e jardins";
const ready = "Pronto para ver a proposta?";

export const planosHomeUthere = {
    "0": {
        laraTexts: [ready]
    },
    "11a": {
        laraTexts: [planWithSolarPanels, textRecommendForHouse],
    },
    "12a": {
        laraTexts: [houseWithoutSolarPanels, textRecommendForHouse],
    },
    "1b": {
        laraTexts: [planoAllianz, textRecommendForGoods],
    },
    "2a": {
        laraTexts: [planoAllianz, textRecommendForHouse],
    },
    "2b": {
        laraTexts: [planoAllianz, textRecommendForGoods],
    },
    "11am": {
        laraTexts: [`${planWithSolarPanels}.`],
    },
    "12am": {
        laraTexts: [`${houseWithoutSolarPanels}.`],
    },
    "1bm": {
        laraTexts: [planoAllianz],
    },
    "2am": {
        laraTexts: [planoAllianz],
    },
    "2bm": {
        laraTexts: [planoAllianz],
    },
    "11an": {
        laraTexts: [`${textRecommendForHouse}.`],
    },
    "12an": {
        laraTexts: [`${textRecommendForHouse}.`],
    },
    "1bn": {
        laraTexts: [`${textRecommendForGoods}.`],
    },
    "2an": {
        laraTexts: [`${textRecommendForHouse}.`],
    },
    "2bn": {
        laraTexts: [`${textRecommendForGoods}.`],
    },
}

export function getHomeProposalMessage(userValues, layout) {
    let processId = getCurrentProcessId();
    let processesState = store.getState()

    // if (processId && processesState[processId] && processesState[processId].recomended) {
        let plano = getRecomendedHomeProposal(userValues.houseType, userValues.houseUse, userValues.houseCredit, userValues.houseActiveContract, userValues.houseAnex[5], layout)
        if (plano) {
            return plano.laraTexts;
        }
    // }
    return [ready];
}

export function getRecomendedHomeProposal(houseType, houseUse, houseCredit, houseActiveContract, hasSolarPanels, layout) {
    let code = '';
    if (houseType === 1) {
        code += '1'
    } else if (houseType === 2) {
        code += '2'
    }

    if (houseType === 1 && (houseUse === 1 || houseUse === 2) && hasSolarPanels) {
        code += '1'
    } else if (houseType === 1 && (houseUse === 1 || houseUse === 2) && !hasSolarPanels) {
        code += '2'
    }

    if ((houseUse === 1 || houseUse === 2)) {
        code += 'a'
    } else if (houseUse === 3) {
        code += 'b'
    }
    if (layout === 'desktopMessage') {
        code += 'm'
    } else if (layout === 'desktopMessageHelper') {
        code += 'n'
    }
    // houseType: 1 = moradia, 2 = apartamento
    // houseUse: 1 = proprietário, 2 = a comprar, 3 = a alugar
    // houseCredit: 1 = com crédito, 2 = sem crédito
    // houseActiveContract: 1 = com contrato, 2 = sem contrato
    // houseAnex: houseAnex.5 = true => com painéis
    return planosHomeUthere[code];
}
