import './UthereNotification.scss';
import laraImage from '../../../assets/lara/lara.png';
import luisImage from '../../../assets/luis/luis.png'
import { NavLink } from "react-router-dom";
import { setEventSelectNotification } from '../../../services/AnalyticsServices';
import { timestampToShortDate } from '../../../utils/DateUtils';
import ChatButton from '../../process/chat-basic/chat-button/ChatButton';

/**
* UthereNotification
*/
function UthereNotification(props) {
    return (

        <div
            className="u-notification-desktop">
            <div className='u-notification-desktop-header'>
                <div>
                    {
                    props.notification.branch === 4 ? "Auto" :
                    props.notification.branch === 1 ? "Vida Protecão Famíia" : props.notification.branch === 2 ? "Vida Crédito Habitação" : "Casa"}
                </div>
                <div>
                    {timestampToShortDate(props.notification.lastTimestamp)}
                </div>
            </div>
            <div className='u-notification-desktop-body'>
                <div>
                    {props.notification.type === 2 ?
                        <img src={luisImage} alt="Luís" />
                        : <img src={laraImage} alt="Lara" />}
                </div>
                <div>{props.notification.lead}</div>
                {props.notification.buttonLabel ?
                    <div className='u-notification-desktop-body-button d-md-none d-lg-none d-xl-block d-sm-none d-none '>
                        <ChatButton
                            desktop={true}
                            content={
                                <NavLink
                                    onClick={() => setEventSelectNotification(
                                        props.notification.subTitle, props.notification.lead, 1, props.notification.branch,
                                        props.notification.subBranch, props.notification.notificationType
                                    )}
                                    to={props.notification.link ? props.notification.link : props.notification.type === 2 ? '/participacao-sinistro' : '/simulacao'} state={
                                        {
                                            branch: props.notification.branch,
                                            subBranch: props.notification.subBranch,
                                            processId: props.notification.processId,
                                            insuranceId: props.notification.insuranceId,
                                        }}>
                                    {props.notification.buttonLabel}</NavLink>} />
                    </div> : null}
            </div>
            {props.notification.buttonLabel ?
                <div className='u-notification-desktop-body-button d-md-block d-lg-block d-xl-none '>

                    <ChatButton
                        full={true}
                        desktop={true}
                        content={
                            <NavLink
                                full="true"
                                onClick={() => setEventSelectNotification(
                                    props.notification.subTitle, props.notification.lead, props.notification.type, props.notification.branch,
                                    props.notification.subBranch, props.notification.notificationType
                                )}
                                to={props.notification.link ? props.notification.link : props.notification.type === 2 ? '/participacao-sinistro' : '/simulacao'} state={
                                    {
                                        branch: props.notification.branch,
                                        subBranch: props.notification.subBranch,
                                        processId: props.notification.processId,
                                        insuranceId: props.notification.insuranceId,
                                    }}>
                                {props.notification.buttonLabel}</NavLink>} />
                </div> : null}
        </div>

    );
};
export default UthereNotification;