import { createSlice } from '@reduxjs/toolkit';

import { getEstimatedUserVisits } from '../utils/GeneralUtils';

export const publicInfoSlice = createSlice({
  name: 'public',
  initialState: {
    initial: getEstimatedUserVisits(),
    previous: null,
    numUsers: null
  },
  reducers: {
    setNumberOfUsers: (state, action) => {
      if (state.numUsers) {
        state.previous = state.numUsers;
      }
      if (action.payload > state.initial) {
        state.numUsers = action.payload;
      }
    },
    updatePreviousUsers: (state) => {
      state.previous = state.numUsers;
    },
  },
});
export const { setNumberOfUsers, updatePreviousUsers } = publicInfoSlice.actions;

export default publicInfoSlice.reducer;