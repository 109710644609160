import { getCurrentHoursMinutes } from '../../utils/DateUtils';
import { cloneObject, generateUid, setTimeoutWait } from '../../utils/GeneralUtils';
import * as ProcessParser from './ProcessParser';
import { saveProcessDetails, getProcessWithSameBranchAndSubBranch, storeUserRelevantData, checkNewData, checkRefreshData } from '../../services/ProcessesServices';
import { store } from '../../store/store';
import { getPersonalDataForProcess, getUserType } from '../../services/UserServices';
import { setEventProcessStep } from '../../services/AnalyticsServices';
import { isoToUthereDate } from '../../utils/DateUtils';
import { processBooleanSequence } from '../../utils/UthereExpressionsUtils';
import { getActiveInsurances } from '../../services/InsuranceServices';
import { findGenderByName } from '../../services/NameServices';
import { registerUserInSimulation } from '../../services/UserServices';
import { startGetProposal, updateProposalValues, getCurrentValues, startInstance, generateAutoProposal } from './ProcessAutoLogic';
import { listenToProposal } from './ProcessHomeLogic';
import { getProposal } from '../../services/ProposalServices';
import { isMobile } from '../../utils/DeviceUtils';

const emptyProcessSate = {
    start: true,
    processing: true,
    showIntroAvatar: false,
    showIntroText: false,
    processId: null,
    currentPage: null,
    currentPageId: null,
    createDate: null,
    updateDate: null,
    lastUserMessageId: null,
    pageIndexRatio: 0,
    currentPageIndex: 0,
    totalPages: 1,
    session: false,
    selectedInsurerId: null,
    selectedInsurerName: null,
    selectedProductId: null,
    numberOfStepsConcluded: 0,
    hasProposal: false,
    proposal: null,
    state: 1,
    branch: 0,
    type: 0,
    subBranch: 0,
    userId: null,
    userValues: {},
    userTexts: {},
    pageHistory: [],
    proposalLoaderState: {

    },
    messages: [],
    inputConfig: {
        type: null,
        disabled: false,
    },
};
/**
 * Current process state
 */
let processState = cloneObject(emptyProcessSate);

/**
 * 
 * @param {*} newState 
 */
export function setCurrentState(newState) {
    processState = newState;
};

/**
 * 
 * @returns 
 */
export function getCurrentProcessId() {
    if (processState && processState.processId)
        return processState.processId;
    return null
};

/**
 * 
 * @returns 
 */
export function getCurrentProcessBranch() {
    if (processState && processState.branch)
        return processState.branch;
    return null
};


/**
 * 
 * @returns 
 */
export function getCurrentProcessValues() {
    if (processState && processState.userValues)
        return processState.userValues;
    return null
};


/**
 * 
 * @returns 
 */
export function getCurrentState() {
    return cloneObject(processState);
};

/**
 * 
 * @param {*} id 
 * @param {*} name 
 */
export function setSelectedProposal(setChatState, branch, insurerId, productId, insurerName, proposals) {
    setChatState(previousState => {
        previousState.selectedInsurerId = insurerId;
        previousState.selectedInsurerName = insurerName;
        if (branch !== 3 || branch !== 4) {
            previousState.selectedProductId = productId;
            let clonedProposal = cloneObject(previousState.proposal);
            clonedProposal.productsConfiguration = proposals;
            previousState.proposal = clonedProposal;
        }
        else {
            previousState.selectedProposal = proposals;
            if (proposals && proposals.emissionDate) {
                previousState.userTexts.emissionDate = isoToUthereDate(proposals.emissionDate);
            }
        }
        previousState.userTexts['selectedInsurerName'] = insurerName;
        previousState.userValues['selectedInsurerId'] = insurerId;
        return {
            ...previousState,
        };
    })
}

export function updateSelectedProposal(setChatState, proposalData) {
    if (proposalData) {
        setChatState(previousState => {
            previousState.selectedProposal = proposalData;
            return {
                ...previousState,
            };
        })
    }
}


export function setProposalData(setChatState, proposalData, email, name) {
    setChatState(previousState => {
        setLastTimestampState(previousState);
        if (email) {
            previousState.userValues.email = email;
            previousState.userTexts.email = email;
        }
        if (name) {

            let genderTmp = findGenderByName(name);
            previousState.userValues.name = genderTmp && genderTmp.name ? genderTmp.name : name;
            previousState.userValues.gender = genderTmp && genderTmp.gender ? genderTmp.gender : "U";
            previousState.userValues.familyNames = genderTmp && genderTmp.familyNames ? genderTmp.familyNames : "";
            previousState.userTexts.name = genderTmp && genderTmp.name ? genderTmp.name : name;
            previousState.userTexts.familyNames = genderTmp && genderTmp.familyNames ? genderTmp.familyNames : "";
        }
        return {
            ...previousState,
            hasProposal: true,
            proposal: proposalData
        };
    })
}

/**
 * 
 */
export function continuePreviousProcess(setChatState) {
    let processId = getProcessWithSameBranchAndSubBranch();
    setChatState(cloneObject(store.getState().processes.list[processId]));
    processState = cloneObject(store.getState().processes.list[processId]);
};

/**
 * 
 * @returns 
 */
export function getEmptyState() {
    return cloneObject(emptyProcessSate);
};
/**
 * guarda os detalhes do processo atual (Redux e firestore)
 */
export function saveCurrentProcessDetails(setChatState, changState, emailType, email, name, error) {
    setChatState(previousState => {
        if (previousState.numberOfStepsConcluded > 3) {
            if (error) {
                previousState.error = error;
                previousState.state = 4;
            }
            if (email) {
                previousState.email = email;
                previousState.userValues.email = email;
                previousState.userTexts.email = email;
            }
            if (name) {
                previousState.name = name;
                let genderTmp = findGenderByName(name);
                previousState.name = genderTmp && genderTmp.name ? genderTmp.name : name;
                previousState.userValues.name = genderTmp && genderTmp.name ? genderTmp.name : name;
                previousState.userTexts.name = genderTmp && genderTmp.name ? genderTmp.name : name;
                previousState.gender = genderTmp && genderTmp.gender ? genderTmp.gender : "U";
                previousState.userValues.gender = genderTmp && genderTmp.gender ? genderTmp.gender : "U";
                previousState.userValues.gender = genderTmp && genderTmp.gender ? genderTmp.gender : "U";
                previousState.familyNames = genderTmp && genderTmp.familyNames ? genderTmp.familyNames : "";
                previousState.userValues.familyNames = genderTmp && genderTmp.familyNames ? genderTmp.familyNames : "";
                previousState.userTexts.familyNames = genderTmp && genderTmp.familyNames ? genderTmp.familyNames : "";
            }
            let changeState = false;
            if (previousState.currentPage && previousState.currentPage.changeState) {
                changeState = true;
                previousState.state = previousState.currentPage.changeState;
            }
            else if (changState !== null && changState !== undefined) {
                previousState.state = changState;
                changeState = true;
            }
            let emailToConsider = email ? email : previousState.email;
            previousState.updateDate = new Date().getTime();
            processState = cloneObject(previousState);
            if (!previousState.currentPage.blockSave) {
                saveProcessDetails(cloneObject(previousState), changeState, emailType, emailToConsider, name, error).then(function () {
                }).catch(() => { });
            }
        }
        return {
            ...previousState,
        };
    })
}

export function handleChooseProposalSubmit(chatState, setChatState, insurerId, insurerName, selectedProposal) {
    return new Promise(async function (resolve, reject) {
        try {
            if (getUserType() !== "uthereUser") {
                await registerUserInSimulation(selectedProposal.email)
            }
            let valueToSubmit = cloneObject(chatState);
            valueToSubmit.state = 2;
            valueToSubmit.selectedInsurerId = insurerId;
            valueToSubmit.selectedInsurerName = insurerName;
            valueToSubmit.selectedProposal = selectedProposal;
            valueToSubmit.userTexts.emissionDate = isoToUthereDate(selectedProposal.emissionDate);
            await saveProcessDetails(valueToSubmit, true);
            setChatState(previousState => {
                previousState.selectedInsurerId = insurerId;
                previousState.selectedInsurerName = insurerName;
                previousState.selectedProposal = selectedProposal;
                previousState.state = 2;
                previousState.userTexts.emissionDate = isoToUthereDate(selectedProposal.emissionDate);
                previousState.userTexts['selectedInsurerName'] = insurerName;
                previousState.userValues['selectedInsurerId'] = insurerId;
                return {
                    ...previousState,
                };
            })
            resolve()

        }
        catch (e) {
            reject(e);
        }

    })
}

/**
 * Sets the initial state of the process
 * @returns current process state
 */
export function setInitalState(loadInfo, setChatState) {
    initProcessState(loadInfo, setChatState);
    return cloneObject(processState);
};
/**
 * Returns the process new component state corresponding of starting processing next step
 * (input goes down with the writting varibale set to true, and user message previous animation 
 * is removed)
 * @returns process new sate
 */
export function handleBeginProcessing(setChatState) {
    setChatState(previousState => {
        let setProposalToFalse = false;
        if (previousState.currentPage && previousState.currentPage.refreshProposal) {
            setProposalToFalse = true;
        }
        previousState.processing = true;
        previousState.manualBack = null;
        previousState.fromBack = false;
        previousState.desktopMessage = "";
        previousState.desktopMessageHelper = "";
        previousState.desktopMessageHelperTop = "";
        previousState.currentMessage = "";
        previousState.hasProposal = setProposalToFalse ? false : previousState.hasProposal;
        processState = cloneObject(previousState);
        return {
            ...previousState,
        };
    })
};

export function handleUserMessageNoAnimation(setChatState) {
    setChatState(previousState => {
        const newMessages = [...previousState.messages];
        let messageIndex = null;
        if (newMessages && newMessages.length > 0) {
            for (let i = newMessages.length - 1; i > 0; i--) {
                if (newMessages[i] && newMessages[i].type === "userMessage") {
                    messageIndex = i;
                    break;
                }
            }
            if (messageIndex) {
                newMessages[messageIndex].noAnimation = true;
            }
            previousState.messages = newMessages;
            processState = cloneObject(previousState);
        }
        return {
            ...previousState,
        };
    })
}
function getBaseMessages(previousState, userMessage, responseTexts, initial) {
    let messageOptions = previousState.currentPage.userMessageOptions;
    if (messageOptions) {
        let messageOptionsKeys = Object.keys(messageOptions);
        if (messageOptionsKeys["sequence"]) {
            messageOptions = processBooleanSequence(messageOptions);
        }
    }
    let baseUserMessage = {
        type: 'userMessage',
        id: previousState.currentPageId,
        text: userMessage ? userMessage : "",
        options: previousState.currentPage.userMessageOptions,
        timestamp: new Date().getTime(),
        time: getCurrentHoursMinutes()
    };

    let baseMapMessage = null;
    if (previousState && previousState.currentPage && previousState.currentPage.type === "postal"
        && !previousState.currentPage.hidePostal && responseTexts) {
        let mapValues = responseTexts[previousState.currentPageId];
        baseMapMessage = {
            id: previousState.currentPageId,
            timestamp: new Date().getTime(),
            time: getCurrentHoursMinutes(),
            type: 'userMessage',
            options: {
                type: 'map',
                lat: mapValues.lat,
                lng: mapValues.lng,
                angle: mapValues.angle,
                description: mapValues.description,
                showPhoto: true,
                closed: false,
            }
        }
    }
    else if (previousState && previousState.currentPage && previousState.currentPage.userMessageType === "homeProposal" && !initial) {
        baseUserMessage = {
            type: 'homeProposal',
            id: previousState.currentPageId,
            options: previousState.currentPage.userMessageOptions,
            timestamp: new Date().getTime(),
            time: getCurrentHoursMinutes()
        }
    }

    let valueToReturn = {};
    if (userMessage !== null) {
        valueToReturn.userMessage = previousState && previousState.currentPage && previousState.currentPage.type === "postal"
            && !previousState.currentPage.hidePostal ?
            baseMapMessage : baseUserMessage;
    }

    valueToReturn.laraMessage = {
        type: 'laraMessage',
        writeState: 0,
        id: initial ? "initial" : previousState.currentPageId,
        messagesList: [],
    };
    let toReturn = cloneObject(valueToReturn);
    return cloneObject(toReturn);
}
export function getInitialAvatarMessage(setChatState) {
    setChatState(previousState => {
        let avatarMessage = cloneObject(getAvatarInitialMessage());
        let baseMessages = cloneObject(getBaseMessages(previousState, null, null, true));
        if (previousState.messages.length === 0) {
            let messagesToSet = [];
            if (avatarMessage) {
                messagesToSet.push(avatarMessage);
            }
            if (baseMessages && baseMessages.userMessage) {
                messagesToSet.push(baseMessages.userMessage);
            }
            if (baseMessages && baseMessages.laraMessage) {
                messagesToSet.push(baseMessages.laraMessage);
            }
            return {
                ...previousState,
                messages: messagesToSet
            };
        }
        else {
            return {
                ...previousState,
            }
        }
    })
}
export function handleChangeState(chatState, responseValues, responseTexts, newState) {
    return new Promise(async function (resolve, reject) {
        let clonedState = cloneObject(chatState);
        clonedState.state = newState;
        setUserValuesAndTexts(responseValues, responseTexts, clonedState);
        try {
            await saveProcessDetails(clonedState, true);
            resolve();
        }
        catch (e) {
            reject();
        }
        resolve();
    });
}
export function handleToogleInput(setChatState) {
    setChatState(previousState => {
        previousState.processing = !previousState.processing
        return {
            ...previousState,
        };
    })
}

export async function updateLoaderMessage(setChatState, options) {
    setChatState(previousState => {
        if (!previousState.proposalLoaderState[previousState.currentPageId]) {
            previousState.proposalLoaderState[previousState.currentPageId] = {};
        }
        previousState.proposalLoaderState[previousState.currentPageId].fast = true;
        return {
            ...previousState,

        };
    });
}

export async function setLoaderMessage(setChatState, handleLoaderCompleted, hanldeLoaderTimeout, options) {
    setChatState(previousState => {
        previousState.inputConfig = { ...previousState.inputConfig, disabled: true };
        previousState.processing = true;
        if (!previousState.proposalLoaderState[previousState.currentPageId]) {
            previousState.proposalLoaderState[previousState.currentPageId] = {};
        }
        previousState.proposalLoaderState[previousState.currentPageId].completed = false;
        resetScroll();
        return {
            ...previousState,

        };
    })
    await setTimeoutWait(1000);
    setChatState(previousState => {
        let newMessage = {
            type: "loaderMessage",
            id: previousState.currentPageId,
            noTimeout: true,
            options: options,
            handleCompleted: handleLoaderCompleted,
            handleTimeout: hanldeLoaderTimeout,
        }
        previousState.messages.push(newMessage);
        return {
            ...previousState,

        };
    })
}
export function setProposalLoaderState(setChatState, completed) {
    setChatState(previousState => {
        if (!previousState.proposalLoaderState[previousState.currentPageId]) {
            previousState.proposalLoaderState[previousState.currentPageId] = {};
        }
        previousState.proposalLoaderState[previousState.currentPageId].completed = completed;
        previousState.processing = false;
        return {
            ...previousState,
        };
    })
}
export async function setUserMap(messageInput, setChatState) {
    if (messageInput) {
        setChatState(previousState => {
            let currentUserMessage = previousState.messages[previousState.messages.length - 2];
            if (currentUserMessage.options && currentUserMessage.options.type === "map") {
                previousState.messages[previousState.messages.length - 2].options.lat = messageInput.lat;
                previousState.messages[previousState.messages.length - 2].options.lng = messageInput.lng;
                previousState.messages[previousState.messages.length - 2].options.description = messageInput.description;
                previousState.messages[previousState.messages.length - 2].options.pin = messageInput.pin;
                previousState.messages[previousState.messages.length - 2].options.placeId = messageInput.placeId;
                previousState.messages[previousState.messages.length - 2].options.showPhoto = messageInput.showPhoto;
                previousState.messages[previousState.messages.length - 2].options.closed = messageInput.closed;
            }
            else {
                let message = {
                    type: 'userMessage',
                    id: previousState.currentPageId,
                    options: {
                        lng: messageInput.lng,
                        lat: messageInput.lat,
                        description: messageInput.description,
                        pin: messageInput.pin,
                        showPhoto: messageInput.showPhoto,
                        closed: messageInput.closed,
                        type: "map"
                    },
                    timestamp: new Date().getTime(),
                    time: getCurrentHoursMinutes()
                }

                let laraDummy = {
                    type: 'laraMessage',
                    writeState: 0,
                    id: previousState.currentPageId,
                    messagesList: [],
                }
                previousState.messages = [
                    ...previousState.messages,
                    message,
                    laraDummy
                ];
                previousState.inputConfig = { ...previousState.inputConfig, disabled: true };

                resetScroll();
            }

            return {
                ...previousState,

            };
        })
        await setTimeoutWait(100);

        setChatState(previousState => {
            const newMessages = [...previousState.messages];
            let messageIndex = null;
            for (let i = newMessages.length - 1; i > 0; i--) {
                if (newMessages[i].type === "userMessage") {
                    messageIndex = i;
                    break;
                }
            }
            if (messageIndex) {
                newMessages[messageIndex].noAnimation = true;
            }
            previousState.messages = newMessages;
            processState = cloneObject(previousState);
            return {
                ...previousState,
            };
        })
    } else {
        setChatState(previousState => {
            let numberOfMessagesToRemove = 2;
            let previousPageId = getPreviousPageId(previousState);
            for (let i = previousState.messages.length - 1; i > -1; i--) {
                if (previousState.messages[i].id === previousPageId) {
                    numberOfMessagesToRemove += 1;
                }
                else {
                    break;
                }
            }

            previousState.messages = (previousState.messages.slice(0, -numberOfMessagesToRemove));
            return {
                ...previousState,
            };
        });
    }
}

/**
 * Handles the user response and returns the process new state
 * @param {*} responseValues 
 * @param {*} responseTexts 
 * @returns process new sate
 */
export async function handleUserResponse(responseValues, responseTexts, setChatState) {
    return new Promise(async function (resolve, reject) {
        setChatState(previousState => {

            let nextPage = ProcessParser.getNextPage(previousState);
            if (nextPage && nextPage.page) {
                if (nextPage.page.updateProcessValues) {
                    let currentValues = getCurrentValues();
                    if (currentValues) {
                        let currentValuesKeys = Object.keys(currentValues)
                        for (let i = 0; i < currentValuesKeys.length; i++) {
                            previousState.userValues[currentValuesKeys[i]] = currentValues[currentValuesKeys[i]];
                            previousState.userTexts[currentValuesKeys[i]] = currentValues[currentValuesKeys[i]];
                        }
                    }
                }
            }
            let currentUserMessage = previousState.messages[previousState.messages.length - 2];
            //Guarda o valor e texto correspondente à resposta do user
            let message = setUserValuesAndTexts(responseValues, responseTexts, previousState);

            if (previousState && previousState.currentPage && previousState.currentPage.updateProposalValue
                && previousState.userValues) {
                updateProposalValues(previousState.userValues).then(function () {
                }).catch(() => { });
            }

            nextPage = ProcessParser.getNextPage(previousState);
            if (nextPage && nextPage.page) {
                if (nextPage.page.proposalAsync) {
                    listenToProposal().then().catch();
                }
                if (nextPage.page.startAutoInstance) {
                    startInstance().then().catch();
                }
                if (nextPage.page.generateAutoProposal) {
                    generateAutoProposal().then().catch();
                }
                if (nextPage.page.generateProposal) {
                    getProposal("generate").then().catch();
                }
            }
            if (!currentUserMessage || !currentUserMessage.options || currentUserMessage.options.type !== "map" || !previousState.inputConfig
                || previousState.inputConfig.type != "postal") {
                let messagesToAdd = getBaseMessages(previousState, message, responseTexts);
                if (nextPage && nextPage.page && messagesToAdd.laraMessage && messagesToAdd.userMessage) {
                    previousState.messages = [
                        ...previousState.messages,
                        messagesToAdd.userMessage,
                        messagesToAdd.laraMessage
                    ];
                }
                else if (nextPage && nextPage.page && messagesToAdd.userMessage) {
                    previousState.messages = [
                        ...previousState.messages,
                        messagesToAdd.userMessage,
                    ];
                }
                else if (nextPage && nextPage.page && messagesToAdd.laraMessage) {
                    previousState.messages = [
                        ...previousState.messages,
                        messagesToAdd.laraMessage,
                    ];
                }
                else {
                    previousState.messages = [
                        ...previousState.messages,
                        messagesToAdd.userMessage,
                    ];
                }
            }
            if (previousState && previousState.currentPage && previousState.currentPage.userMessageType === "homeProposal") {
                previousState.userValues.showConfetti = null;
            }

            resetScroll();
            previousState.lastUserMessageId = previousState.currentPageId;
            previousState.inputConfig = { ...previousState.inputConfig, disabled: true };
            processState = cloneObject(previousState);
            resolve();

            manageUserData(processState, responseValues, setChatState);
            return {
                ...previousState,

            };
        })
    });
};
/**
 * 
 * @param {*} branchInfo 
 */
export const handleUpdateProcessBranch = (branchInfo, setChatState) => {
    setChatState(previousState => {
        let flowInfo = ProcessParser.getFlowInfo(branchInfo,
            previousState.currentPageId, previousState.currentPage
        );
        previousState.branch = branchInfo.branch;
        previousState.subBranch = branchInfo.subBranch;
        previousState.session = branchInfo.session;
        previousState.totalPages = flowInfo.totalPages;
        previousState.pageIndexRatio = flowInfo.pageIndexRatio;
        processState = cloneObject(previousState);
        return {
            ...previousState,
        };
    })
};

/**
 * Returns the process new state corresponding to Lara starting to write
 * @returns process new sate
 */
export function handleBeginLaraMessages(setChatState, initial) {
    setChatState(previousState => {
        if (!initial) {
            setNextPage(previousState);
        }
        let laraMessagesList = previousState.currentPage.laraMessage ? getLaraMessages(Array.isArray(previousState.currentPage.laraMessage)
            ? previousState.currentPage.laraMessage : [previousState.currentPage.laraMessage]) : null;
        if (previousState.currentPage && previousState.currentPage.laraMessage) {
            //Indica que mensagens da Lara começam a ser escritas
            previousState.messages[previousState.messages.length - 1].writeState = 1;
            previousState.messages[previousState.messages.length - 1].messagesList = laraMessagesList;
        }
        else {
            previousState.writting = false;
        }
        previousState.currentMessage = [];

        if (previousState.currentPage) {
            if (previousState.currentPage.desktopMessage) {
                previousState.desktopMessage = previousState.currentPage.desktopMessage;
            }
            if (previousState.currentPage.desktopMessageSecondary) {
                previousState.desktopMessageSecondary = previousState.currentPage.desktopMessageSecondary;
            }
            if (previousState.currentPage.desktopMessageHelper) {
                previousState.desktopMessageHelper = previousState.currentPage.desktopMessageHelper;
            }
            if (previousState.currentPage.desktopMessageHelperTop) {
                previousState.desktopMessageHelperTop = previousState.currentPage.desktopMessageHelperTop;
            }
        }

        previousState.inputConfig = {
            ...previousState.inputConfig,
            type: null,
            currentPageId: previousState.currentPageId,
            valueId: previousState.currentPage ? previousState.currentPage.valueId : null,
            disabled: false,
            options: previousState.currentPage ? previousState.currentPage.options : null
        }
        processState = cloneObject(previousState);
        return {
            ...previousState,
        };
    });
};
/**
 * Returns the process new state corresponding of removing the previous messages
 * and puting the current input down
 * @returns process new sate
 */
export function handleRemovePreviousMessages(setChatState) {
    setChatState(previousState => {
        let numberOfMessagesToRemove = 0;
        let previousPageId = getPreviousPageId(previousState);
        for (let i = previousState.messages.length - 1; i > -1; i--) {
            if (previousState.messages[i].id === previousPageId) {
                numberOfMessagesToRemove += 1;
            }
            else {
                break;
            }
        }
        if (numberOfMessagesToRemove > 0) {
            previousState.messages = (previousState.messages.slice(0, -numberOfMessagesToRemove));
            for (let i = previousState.messages.length - 1; i > -1; i--) {
                if (previousState.messages[i].type === 'userMessage') {
                    previousState.lastUserMessageId = previousState.messages[i].id;
                    break;
                }
                else if (!previousState.messages[i]) {
                    continue;
                }
                else if (previousState.messages[i].id === "initial") {
                    previousState.lastUserMessageId = previousState.messages[i].id;
                }
            }
        }
        goBackOneStep(previousState);
        previousState.processing = true;
        previousState.inputConfig.disabled = true;
        processState = cloneObject(previousState);

        return {
            ...previousState,
        };
    });
}
/**
 * 
 */
export function handleEnableInputAndRemoveAnimations(previousState) {

    if (previousState && previousState.currentPage && previousState.currentPage.startAutoProposal) {
        startGetProposal().then(function () {
        }).catch(() => { });
    }

    setLastTimestampState(previousState);
    if (previousState.messages[previousState.messages.length - 1]
        && previousState.messages[previousState.messages.length - 1].messagesList
        && previousState.messages[previousState.messages.length - 1].messagesList.length > 0) {
        previousState.messages[previousState.messages.length - 1].writeState = 2;
    }
    previousState.processing = false;
    previousState.start = false;
    previousState.numberOfStepsConcluded = previousState.numberOfStepsConcluded + 1;
    previousState.inputConfig = {
        ...previousState.inputConfig,
        type: previousState.currentPage && previousState.currentPage.type ?
            previousState.currentPage.type : null,

    };
};
/**
 * Refresca o componente. Casos em que o tipo do componente seguinte é o mesmo que 
 * o anterior
 */
export function handleRefreshInput(setChatState) {
    setChatState(previousState => {
        return {
            ...previousState,
            inputConfig: {
                ...previousState.inputConfig,
                type: null,
            },
        };
    });
};
/**
 * Change the imput type for the input corresponding of the current page
 * @returns process new sate
 */
export function handleEnableInputAfterPreivousStep(setChatState) {
    setChatState(previousState => {
        let currentPage = previousState.currentPage;
        let currentMessages = previousState.messages;

        previousState.currentMessage = [];
        previousState.desktopMessage = [];
        if (!currentMessages || currentMessages.length === 0) {
            previousState.currentMessage = [previousState.introMessage];
        }
        else if (currentMessages.length === 1) {

            previousState.currentMessage.push(currentMessages[0].text)
        }
        else if (currentMessages.length > 1) {
            let lastMessage = currentMessages[currentMessages.length - 1];
            if (lastMessage.messagesList) {
                for (let i = 0; i < lastMessage.messagesList.length; i++) {
                    if (i + 1 === lastMessage.messagesList.length) {
                        previousState.currentMessage.push(lastMessage.messagesList[i].text);
                    }
                    else {
                        previousState.desktopMessage.push(lastMessage.messagesList[i].text);
                    }
                }
            }
        }

        if (previousState && previousState.currentPage) {
            if (previousState.currentPage.desktopMessage) {
                previousState.desktopMessage = previousState.currentPage.desktopMessage;
            }
            if (previousState.currentPage.desktopMessageSecondary) {
                previousState.desktopMessageSecondary = previousState.currentPage.desktopMessageSecondary;
            }
            if (previousState.currentPage.desktopMessageHelper) {
                previousState.desktopMessageHelper = previousState.currentPage.desktopMessageHelper;
            }
        }
        return {
            ...previousState,
            processing: false,
            fromBack: true,
            inputConfig: {
                ...previousState.inputConfig,
                type: currentPage ? currentPage.type : null,
                disabled: false,
                valueId: previousState.currentPage.valuesId ? previousState.currentPage.valuesId : null,
                currentPageId: previousState.currentPageId,
                options: currentPage ? currentPage.options : null
            },
        };
    });
};
/**
 * Atualiza as mensagens da Lara à medida que vão sendo escritas
 * Quando última mensagem é escrita atualiza estado para ser levantado input do utilizador
 * @param {index da mensagem da Lara a atualizar} index 
 * @returns process new sate
 */
export function handleUpdateLaraMessageState(index, setChatState, mobile, changState, email, name) {
    setChatState(previousState => {
        if (index >= 0) {
            let currentMessage = previousState.messages[previousState.messages.length - 1];
            let currentSubMessage = currentMessage.messagesList[index];
            if (currentSubMessage.writeState < 3) {
                currentSubMessage.writeState += 1;
                let timeoutToSet = !mobile ? 0 : currentSubMessage.writeState === 3 ? 400 :
                    currentSubMessage.writeState === 1 ? 1000 :
                        currentSubMessage.writeState === 2 ? 100 : 0;
                if (currentSubMessage.writeState < 3) {
                    setTimeout(() => {
                        handleUpdateLaraMessageState(index, setChatState, mobile, changState, email, name);
                    }, timeoutToSet)
                }
                else if (currentSubMessage.writeState === 3 && currentMessage.messagesList.length > index + 1) {
                    setTimeout(() => {
                        handleUpdateLaraMessageState(index + 1, setChatState, mobile, changState, email, name);
                    }, timeoutToSet)
                }
                else if (currentSubMessage.writeState === 3) {
                    handleEnableInputAndRemoveAnimations(previousState);
                    saveCurrentProcessDetails(setChatState, changState, null, email, name)
                }
            }

        }
        else {
            handleEnableInputAndRemoveAnimations(previousState);
            saveCurrentProcessDetails(setChatState, changState, null, email, name)
        }

        processState = cloneObject(previousState);
        return {
            ...previousState,
        };
    });
};
/**
 * Faz update dos valores do processo
 * @param {} values 
 */
export function handleUpdateProcessValues(values, setChatState, method, newUser, texts) {
    setChatState(previousState => {
        updateProcessValues(values, previousState, method, texts);
        if (previousState.userValues && previousState.userValues.name) {
            saveProcessDetails(cloneObject(previousState)).then(function () {
            }).catch(() => { });
        }
        return {
            ...previousState,
        };
    });

    return cloneObject(processState)
};

export async function manageUserData(processData, responseValues, setChatState) {    
    let {newData, key} = await isPivotValue(responseValues)

    if (newData) {
        handleUpdateProcessValues(refreshData[key], setChatState, 'direct', null, refreshData[key]);
    }

    await storeUserRelevantDataRedux(processData, newData);
}

async function isPivotValue(responseValues) {
    let newData;
    let key = Object.keys(responseValues)[0]
    if (responseValues.postal || responseValues.address) {
        newData = await checkRefreshData('postal', responseValues);
        key = newData
    }
    if (key === 'plate') {
        newData = await checkRefreshData('plate', responseValues);
    }
    if (key === 'carOwner') {
        newData = await checkRefreshData('carOwner', responseValues);
    }
    return {newData, key}
}

const refreshData = {
    plate: {
        brand: null,
        carConfirm: null,
        carOwner: null,
        usualDriver: null,
        carDescritpion: null,
        carDescritpionShort: null,
        carExtras: null,
        carGarage: null,
        carNumberOfDoors: null, 
        carUse: null,
        carVersion: null,
        consideredVersion: null, 
        consideredVersionPage: null,
        hasDanosProprios: null,
        licenseDate: null,
        versionPage: null,
    },
    postal: {
        hasBens: null,
        houseAnex: null,
        houseArea: null,
        houseBathrooms: null,
        houseCredit: null,
        houseEquipments: null,
        houseHasFenomenos: null,
        houseLive: null,
        houseMaterials: null,
        houseType: null,
        houseUse: null,
        houseYear: null,
        recomendedMaterial: null, 
    },
    number: {
        hasBens: null,
        houseAnex: null,
        houseArea: null,
        houseBathrooms: null,
        houseCredit: null,
        houseEquipments: null,
        houseHasFenomenos: null,
        houseLive: null,
        houseMaterials: null,
        houseType: null,
        houseUse: null,
        houseYear: null,
        recomendedMaterial: null, 
    },
    apartment: {
        hasBens: null,
        houseAnex: null,
        houseArea: null,
        houseBathrooms: null,
        houseCredit: null,
        houseEquipments: null,
        houseHasFenomenos: null,
        houseLive: null,
        houseMaterials: null,
        houseType: null,
        houseUse: null,
        houseYear: null,
        recomendedMaterial: null, 
    },
    carOwner: {
        carUse: null,
        nif: null,
        address: null,
        postal: null,
        usualDriver: null,
    }
}

export async function storeUserRelevantDataRedux(processData, newData) {
    return await storeUserRelevantData(processData, newData);
}

export function hasProcess(processId) {
    let result = store.getState().processes.list[processId];
    if (result)
        return true;
    return false;
}

/**
 * Resets chat scroll to the bottom
 */
function resetScroll() {
    let feedContainer = document.getElementById("feedContainer");
    if (feedContainer) {
        feedContainer.scrollTop = 0;
    }
};
/**
 * 
 * @param {*} textList 
 * @returns 
 */
function getLaraMessages(textList) {
    let messagesToReturn = [];
    if (textList) {
        for (let i = 0; i < textList.length; i++) {
            messagesToReturn.push(
                {
                    text: textList[i],
                    time: getCurrentHoursMinutes(),
                    timestamp: new Date().getTime(),
                    writeState: i === 0 ? 1 : 0,
                }
            )
        }
    }
    return messagesToReturn;
};
/**
 * Set the state values to the state corresponding on the next step,
 * including the currentPageId, the currentPage, the pageHistory and 
 * the feedMessages array
 * @returns the currentPage after the change of state
 */
export function setNextPage(previousState) {
    let nextPage = ProcessParser.getNextPage(previousState);
    setEventProcessStep(previousState.type, previousState.branch,
        previousState.subBranch, previousState.currentPageId, nextPage.id, previousState.state, false, previousState.session);

    if (nextPage && nextPage.id && nextPage.page) {


        let currentPageIndex = ProcessParser.getPageIndexById(
            {
                branch: previousState.branch,
                subBranch: previousState.subBranch,
                type: previousState.type,
                session: previousState.session
            },
            nextPage.id);
        previousState.currentPageIndex = nextPage.last ? 100 : currentPageIndex;
        previousState.pageIndexRatio = nextPage.page.last ? 100 : (currentPageIndex / previousState.totalPages) * 100;
        previousState.currentPage = nextPage.page;
        previousState.currentPageId = nextPage.id;
        previousState.pageHistory = [
            ...previousState.pageHistory,
            { id: nextPage.id, branch: previousState.branch, subBranch: previousState.subBranch, type: previousState.type }
        ]
    }
    else {
        previousState.currentPageId = null;
        previousState.currentPage = null;
    }
};
/**
 * 
 * @returns 
 */
function setLastTimestampState(previousState) {
    previousState.lastTimestamp = new Date().getTime();
};
/**
 * Set the state values to the state corresponding to the previous step,
 * including the currentPageId, the currentPage, the pageHistory and 
 * the feedMessages array
 * @param {new feed messages} previousState 
 */
function goBackOneStep(previousState) {
    previousState.pageHistory = (previousState.pageHistory.slice(0, -1));
    let previousPage = previousState.pageHistory[previousState.pageHistory.length - 1];
    setEventProcessStep(processState.type, processState.branch,
        processState.subBranch, processState.currentPageId, previousPage.id, processState.state, true, processState.session);
    previousState.currentPageId = previousPage.id;

    previousState.currentPageIndex = ProcessParser.getPageIndexById(
        {
            branch: previousPage.branch,
            subBranch: previousPage.subBranch,
            type: previousPage.type,
            session: previousPage.session
        }
        , previousState.currentPageId);

    let flowInfo = ProcessParser.getFlowInfo({
        branch: previousPage.branch,
        subBranch: previousPage.subBranch,
        type: previousPage.type,
        session: previousPage.session
    },
        previousState.currentPageId,
        previousState.currentPage
    );
    previousState.currentMessage = "";
    previousState.desktopMessage = "";
    previousState.desktopMessageSecondary = "";
    previousState.desktopMessageHelper = "";
    previousState.desktopMessageHelperTop = "";

    let newPage = ProcessParser.getPageById(
        {
            branch: previousPage.branch,
            subBranch: previousPage.subBranch,
            type: previousPage.type,
            session: previousPage.session
        }, previousState.currentPageId,
        previousState.userTexts,
        previousState.userValues);

    if (newPage && newPage.valueId) {
        previousState.inputConfig.valueId = newPage.valueId;
    }
    previousState.inputConfig.options = newPage.options;
    previousState.branch = previousPage.branch;
    previousState.subBranch = previousPage.subBranch;
    previousState.session = previousPage.session;
    previousState.totalPages = flowInfo.totalPages;
    previousState.pageIndexRatio = flowInfo.pageIndexRatio;
    previousState.numberOfStepsConcluded = previousState.numberOfStepsConcluded - 1;
    previousState.currentPage = newPage;
};

/**
 * inits the process state based on the selected load info
 * @param {*} loadInfo 
 */
function initProcessState(loadInfo, setChatState) {
    if (loadInfo && loadInfo.processId) {
        if (store.getState().processes.list[loadInfo.processId] === undefined) {
            processState = cloneObject(emptyProcessSate);
            setChatState(cloneObject(emptyProcessSate));
        }
        else {
            let loadedState = cloneObject(store.getState().processes.list[loadInfo.processId]);
            processState = cloneObject(loadedState);
            setChatState(cloneObject(loadedState));
        }
    }
    else {
        processState = cloneObject(emptyProcessSate);
        processState.branch = loadInfo.branch ? loadInfo.branch : 0;
        processState.type = loadInfo.type;
        processState.subBranch = loadInfo.subBranch && loadInfo.branch != 4 ? loadInfo.subBranch : 0;
        processState.session = loadInfo.session;
        let initialValuesAndTexts = getInitialValuesAndTexts(loadInfo);
        processState.userValues = initialValuesAndTexts.userValues;
        processState.userTexts = initialValuesAndTexts.userTexts;
        processState.processId = generateUid();
        processState.createDate = new Date().getTime();
        let parsedState = ProcessParser.getIntialState(
            {
                type: processState.type,
                branch: processState.branch,
                subBranch: processState.subBranch,
                session: processState.session
            },
            processState.userValues, processState.userTexts
        );

        processState.avatar = parsedState.avatar;


        if (parsedState && parsedState.id && parsedState.page) {
            if (parsedState.page.startAutoInstance) {
                startInstance().then().catch();
            }

            processState.currentPageId = parsedState.id;
            processState.desktopMessage = parsedState.desktopMessage;
            processState.desktopMessageSecondary = parsedState.desktopMessageSecondary;
            processState.desktopMessageHelper = parsedState.desktopMessageHelper;
            processState.desktopMessageHelperTop = parsedState.desktopMessageHelperTop;
            processState.introMessage = parsedState.introMessage;
            processState.currentMessage = [parsedState.introMessage];
            processState.currentPage = parsedState.page;
            processState.currentPageIndex = parsedState.pageIndex;
            processState.pageIndexRatio = parsedState.pageIndexRatio;
            processState.totalPages = parsedState.totalPages;
            processState.pageHistory.push(
                {
                    id: parsedState.id, branch: processState.branch,
                    subBranch: processState.subBranch, type: processState.type,
                    session: processState.session
                });
        }
        setEventProcessStep(processState.type, processState.branch,
            processState.subBranch, null, processState.currentPageId, 1, false, processState.session);
        if (processState && processState.currentPage) {
            processState = {
                ...processState,
                inputConfig: {
                    type: !isMobile() ? processState.currentPage.type : null,
                    currentPageId: processState.currentPageId,
                    valueId: processState.currentPage.valueId,
                    options: processState.currentPage.options,
                    disabled: false,
                },
            }
        }
        if (store.getState().userData?.data?.userValues && Object.keys(store.getState().userData.data.userValues).length > 0) {
            let {userValues, userTexts} = store.getState().userData.data;
            if (!userValues.isPersonalData && processState.branch !== 4) {
                
            } else {
                setUserValuesAndTexts(userValues, userTexts, processState);
            }
        }
        setChatState(cloneObject(processState));
    }
};

/***
 * 
 */
export function getAvatarInitialMessage() {
    return {
        type: 'introLaraMessage',
        text: processState.introMessage,
        time: getCurrentHoursMinutes(),
        timestamp: new Date().getTime(),
    }
}

/**
 * 
 * @param {*} values 
 * @returns 
 */
function updateProcessValues(values, previousState, method, texts) {
    let idToSave = processState.currentPageId;
    if (previousState.currentPage && previousState.currentPage.valueId) {
        idToSave = processState.currentPage.valueId;
    }
    if (!previousState.userValues[idToSave]) {
        previousState.userValues[idToSave] = {};
    }
    if (values.nif) {
        previousState.userValues.nif = values.nif;
    }

    let valuesKeys = Object.keys(values);
    for (let i = 0; i < valuesKeys.length; i++) {
        if (method && method === "direct") {
            previousState.userValues[valuesKeys[i]] = values[valuesKeys[i]];
            if (texts) {
                previousState.userTexts[valuesKeys[i]] = values[valuesKeys[i]];
            }
        }
        else {
            previousState.userValues[idToSave][valuesKeys[i]] = values[valuesKeys[i]];
            if (texts) {
                previousState.userTexts[idToSave][valuesKeys[i]] = values[valuesKeys[i]];
            }
        }
    }
    processState = cloneObject(previousState);
};
/**
 * 
 * @returns previous page id
 */
function getPreviousPageId(previousState) {
    if (previousState.pageHistory && previousState.pageHistory.length > 1) {
        return previousState.pageHistory[previousState.pageHistory.length - 2].id;
    }
    else {
        return null;
    }
};

/**
 * 
 * @param {*} values 
 * @param {*} texts 
 * @returns 
 */
function setUserValuesAndTexts(values, texts, previousState) {
    if (values) {
        let valuesKeys = Object.keys(values);

        for (let i = 0; i < valuesKeys.length; i++) {
            previousState.userValues[valuesKeys[i]] = values[valuesKeys[i]];
        }
    }
    if (texts) {
        let textKeys = Object.keys(texts);
        for (let i = 0; i < textKeys.length; i++) {
            previousState.userTexts[textKeys[i]] = texts[textKeys[i]];
        }
    }
    processState = cloneObject(previousState);
    return ProcessParser.getUserMessage(
        previousState.currentPage.userMessage, previousState.userTexts, previousState.userValues, previousState.currentPageId,
        {
            branch: processState.branch,
            subBranch: processState.subBranch,
            type: processState.type,
            session: processState.session
        });
};
/**
 * 
 * @returns 
 */
function getInitialValuesAndTexts(loadInfo) {
    let objectToReturn = getPersonalDataForProcess();
    if (loadInfo.insuranceNumber) {
        let insurances = getActiveInsurances(loadInfo.insuranceNumber, loadInfo.branch);
        if (insurances && insurances.length > 0) {
            if (loadInfo.branch == 1) {
                objectToReturn.userValues.insururerId = insurances[0].insurer;
                objectToReturn.userValues.insuranceNumber = insurances[0].insuranceNumber;
                objectToReturn.userTexts.insuranceNumber = insurances[0].insuranceNumber;
                objectToReturn.userTexts.name = insurances[0].name;
            }
            else if (loadInfo.branch == 3) {
                objectToReturn.userValues.insururerId = 1;
                objectToReturn.userTexts.insurer = "Allianz";
                if (insurances && insurances.length > 0) {
                    objectToReturn.userTexts.name = insurances[0].name;
                    objectToReturn.userValues.name = insurances[0].name;
                    objectToReturn.userValues.nif = insurances[0].nif;
                    objectToReturn.userTexts.nif = insurances[0].nif;
                    objectToReturn.userValues.phone = insurances[0].tel;
                    objectToReturn.userTexts.phone = insurances[0].tel;
                    objectToReturn.userValues.insuranceNumber = insurances[0].insuranceNumber;
                    objectToReturn.userTexts.insuranceNumber = insurances[0].insuranceNumber;
                    if (insurances[0].postal) {
                        objectToReturn.userValues.address = {
                            address: insurances[0].postal.street,
                            city: insurances[0].postal.locality,
                        }
                    }
                    if (insurances[0].config && insurances[0].config.bens === "bens") {
                        objectToReturn.userValues.hasBensCoverage = 1;
                    }
                    else {
                        objectToReturn.userValues.hasBensCoverage = 0;
                    }
                    if (insurances[0].config && insurances[0].config.produto === "Extra") {
                        objectToReturn.userValues.hasExtraCoverage = 1;
                    }
                    else {
                        objectToReturn.userValues.hasExtraCoverage = 0;
                    }
                }
            }
        }
    }
    else {
        if (loadInfo.type === 2) {
            let insurances = getActiveInsurances(null, 3);
            if (insurances && insurances.length > 0) {
                objectToReturn.userValues.insurances = insurances;
                objectToReturn.userTexts.name = insurances[0].name;
                objectToReturn.userValues.name = insurances[0].name;

                if (insurances.length === 1 && insurances[0].postal) {
                    objectToReturn.userTexts.addressDescription = insurances[0].postal.description;
                }
            }
        }

        if (loadInfo.restart == 1) {
            objectToReturn.userValues.restart = 1;
        }
    }
    return objectToReturn;
}
