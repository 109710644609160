export const lang = {

    /**
     * LARA
     */
    laraIntro: "Olá {name}, tem alguma dúvida?",
    laraFollow1: "Quer saber mais sobre coberturas, franquias, transferência do seguro ou outro tema?",
    laraFollow1pos: "Claro, nós vamos ajudar. Que dúvida tem?",
    laraFollow2: "Olá novamente, como podemos ajudar?",
    laraFollow3: "Olá {name}, como posso ajudar?",
    laraFollow4: "Quer deixar-me o seu telemóvel para que ele o possa contactar?",
    laraRedirect1: "Eu vou passar este tema ao João Pedro, um dos nossos especialistas. Ele é a pessoa indicada para responder à sua dúvida",
    laraRedirect2: "Ele vai entrar em contacto consigo nos próximos minutos",
    laraRedirect2pos: "Ele vai entrar em contacto consigo amanhã logo de manhã",
    laraRedirect3pos: "Ele vai entrar em contacto consigo logo de manhã",
    laraRedirect4pos: "Ele vai entrar em contacto consigo na 2ª feira logo de manhã",
    laraRedirect3auto: "Entretanto, pode continuar a ver a proposta para o seu {carDescritpionShort}.",  
    laraRedirect3home: "Entretanto, pode continuar a ver a proposta para a sua casa",
    laraRedirect3end: "Estou à disposição se tiver outra dúvida.",
    laraRedirect4: "Vou passar o que me disse ao João Pedro.",
    laraRedirect5: "Ele está em reunião com um cliente mas entra em contacto consigo logo que terminar.",
    laraRedirect6: "Ele vai entrar em contacto consigo",
    laraRedirect7: "Obrigada. Vou passar o que me disse ao João Pedro",

    // laraEndChatRedirect: "Ok, clique abaixo e eu vou te redirecionar para o chat da uthere",
    /**LABELS */
    lblNotNow : "Para já não",
    lblHaveQuestion : "Sim",
    lblOpt1 : "Coberturas",
    lblOpt2 : "Franquias",
    lblOpt3 : "Transferência do seguro",
    lblOpt4 : "Outra tema",
    lblOk1: "Combinado",
    lblOk2: "Está bem",
    lblOk3: "Perfeito",
    lblOk4: "De acordo",
    lblOk5: "Entendido",
    lblConfirm : "Ok, obrigado",
    lblBackToProposal: "Voltar à proposta",
    lblPhoneNumber: "Número de telemóvel",
    describeQuestion: "Descreva a sua dúvida",
    /* 
    * USER
     */
    userAddress: 'user teste',
}