import { createSlice } from '@reduxjs/toolkit';

export const proposalSlice = createSlice({
  name: 'proposals',
  initialState: {
    list : {}
  },
  reducers: {
    setProposal: (state, action) => {
      state.list[action.payload.processId] = action.payload.proposal;
    },
  },
});
export const { setProposal } = proposalSlice.actions;

export const selectProposals = (state) => state.list;

export default proposalSlice.reducer;