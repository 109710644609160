import { createSlice } from "@reduxjs/toolkit";

export const userDataSlice = createSlice({
    name: "userData",
    initialState: {
        data: {},
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        removeData: (state) => {
            state.data = {};
        },
    },
});

export const { setData, removeData } = userDataSlice.actions;

export default userDataSlice.reducer;