import { createSlice } from '@reduxjs/toolkit';

export const documentsSlice = createSlice({
    name: 'documents',
    initialState: {
        list: {}
    },
    reducers: {
        addDocuments: (state, action) => {
            if (action.payload && action.payload.documents) {
                let documentsKeysToAppend = Object.keys(action.payload.documents);
                for (let i = 0; i < documentsKeysToAppend.length; i++) {
                    state.list[documentsKeysToAppend[i]] = action.payload.documents[documentsKeysToAppend[i]];
                }
            }
        },
        removeData: (state) => {
            state.list = {};
        },
        setSaved: (state, action) => {
            if (action.payload && action.payload.processId) {
                let keys = Object.keys(state.list);
                for (let i = 0; i < keys.length; i++) {
                    if (state.list[keys[i]].processId === action.payload.processId) {
                        state.list[keys[i]].saved = 1;
                    }
                    break;
                }
            }
        },
    },
});
export const { addDocuments, removeData, setSaved } = documentsSlice.actions;

export const selectDocuments = (state) => state.list;

export default documentsSlice.reducer;