import lifeCreditIcon from '../../assets/insurances/life-credit.png';
import lifeFamilyIcon from '../../assets/insurances/life-family.png';
import houseIcon from '../../assets/insurances/house.png';



export const icons = {
  1: {
    icon: lifeFamilyIcon,
    alt: "Vida Protecção Família"
  },
  2: {
    icon: lifeCreditIcon,
    alt: "Vida Crédito Habitação"
  },
  3: {
    icon: houseIcon,
    alt: "Multirrisco Habitação"
  },
};


export const insurerName = {
  1: "MetLife",
  2: "Allianz",
  3: "Liberty",
  4: "Real Vida",
  5: "Tranquilidade",
};

export const insurerTel = {
  1: "808 500 005",
  2: "213 108 300",
  3: "808 243 000",
  4: "808 20 14 20",
}

export const insurerEmail = {
  1: "apoiocliente@metlife.pt",
  2: "info@allianz.pt",
  3: "geral@libertyseguros.pt",
  4: "info.clientes@realvidaseguros.pt",
}

export const insurerShortName = {
  1: "MetLife",
  2: "Allianz",
  3: "Liberty",
  4: "Real",
};

export const insurerColor = {
  1: "#A4CE4E",
  2: "#00318A",
  3: "#003D7D",
  4: "#0FABE3",
};

export const insurerSubColor = {
  1: "#96BF43",
  2: "#00318A",
  3: "#012850",
  4: "#098ebd",
};

export const headerLogos = {
  1: "result-metlife-header.png",
  2: "result-allianz-header.png",
  3: "result-liberty-header.png",
  4: "result_proposal-real.png",
};

export const nips = {
  1: "NIP_Vida_MetLife.pdf",
  3: "NIP_Vida_Liberty.pdf",
  4: "NIP_Vida_Real.pdf",
};

export const switchNames = {
  1: "uthere-switch-metlife",
  3: "uthere-switch-liberty",
  4: "uthere-switch-real",
};

export const insurersAbout = {
  1: "<span style='font-weight: 700'>A MetLife</span>  é especialista em seguros de Vida e Acidentes Pessoais, e uma das maiores seguradoras a nível internacional. Está em Portugal há mais de 35 anos e tem mais de 500 Mil Clientes",
  2: "",
  3: "A <span style='font-weight: 700'>Liberty</span> é uma das maiores seguradoras a nível mundial. Fundada em 1912, está presente em Portugal há mais de 30 anos",
  4: "A <span style='font-weight: 700'>Real Vida Seguros</span> é uma seguradora portuguesa, especialista em seguros de Vida e Acidentes Pessoais",
  5: "​Desde 1808 que a Fidelidade protege o futuro das famílias, das empresas e de Portugal. Líder de mercado, vida e não vida, a seguradora junta à dimensão, solidez e credibilidade a inovação como um dos seus maiores valores. A sua estratégia incinde no desenvolvimento de produtos inovadores, capazes de fazer a diferença na vida das pessoas​",
};