export function isNewPostalData(formerData, responseValues) {
    if (
        formerData &&
        formerData.userValues &&
        formerData.userValues.postal?.postalCode &&
        responseValues &&
        (
            (responseValues.postal?.postalCode && formerData.userValues.postal.postalCode === responseValues.postal.postalCode) ||
            (responseValues.address && 
             formerData.userValues.address?.postalCode && 
             formerData.userValues.address.postalCode === responseValues.address.postalCode)
        )
    ) {
        if (
            (responseValues.postal?.number && 
             formerData.userValues.postal?.number && 
             responseValues.postal.number === formerData.userValues.postal.number) ||
            (responseValues.address?.number && 
             formerData.userValues.address?.number && 
             responseValues.address.number === formerData.userValues.address.number)
        ) {
            console.log(responseValues, formerData)
            if (!responseValues.postal?.floor && 
                !responseValues.address?.floor &&
                !responseValues.postal?.block &&
                !responseValues.address?.block &&
                !responseValues.postal?.side &&
                !responseValues.address?.side) {
                return false;
                
            }
            let apartmentData = {side: 0, block: 0, floor: 0};
            if (
                (responseValues.postal?.side && 
                 formerData.userValues.postal?.side && 
                 responseValues.postal.side !== formerData.userValues.postal.side) ||
                (responseValues.address?.side && 
                 formerData.userValues.address?.side && 
                 responseValues.address.side !== formerData.userValues.address.side) ){
                    apartmentData.side = 1;
                 }
            if (
                (responseValues.postal?.block && 
                formerData.userValues.postal?.block && 
                responseValues.postal.block !== formerData.userValues.postal.block) ||
                (responseValues.address?.block && 
                formerData.userValues.address?.block && 
                responseValues.address.block !== formerData.userValues.address.block)) {
                    apartmentData.block = 1;
                }
            if (
                (responseValues.postal?.floor && 
                formerData.userValues.postal?.floor && 
                responseValues.postal.floor !== formerData.userValues.postal.floor) ||
                (responseValues.address?.floor && 
                formerData.userValues.address?.floor && 
                responseValues.address.floor !== formerData.userValues.address.floor)) {
                    apartmentData.floor = 1;
                }
            if (apartmentData.side === 0 && apartmentData.block === 0 && apartmentData.floor === 0) {
                return false;
            } 
            return 'apartment';
        }
        return 'number'; 
    }
    if (formerData.userValues.postal === null || formerData.userValues.postal === undefined) {
        return false;
    }
    return 'postal';
}

export function checkPersonalData(processData){
    return processData.branch === 3 ? true : (processData.branch === 4 ? processData.userValues.carOwner === 2 ? false : true : true)
}