import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import userReducer from '../reducers/userSlice';
import processesReducer from '../reducers/processesSlice';
import insurancesReducer from '../reducers/insurancesSlice';
import publicInfoReducer from '../reducers/publicInfoSlice';
import proposalReaducer from '../reducers/proposalSlice';
import documentsReducer from '../reducers/documentsSlice';
import autoReducer from '../reducers/autoSlice';
import userDataReducer from '../reducers/userDataSlice';

const rootReducer = combineReducers({
	user: userReducer,
    processes : processesReducer,
    public : publicInfoReducer,
    insurances : insurancesReducer,
    proposals : proposalReaducer,
    documents : documentsReducer,
    auto : autoReducer,
    userData: userDataReducer
})

export const store = configureStore(
    {reducer: rootReducer});
  