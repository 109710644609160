import { nameGenderList } from '../config/lists/NameGenderList';

export function findGenderByName(searchName) {
    let valuesToReturn = {
        gender: 'U',
        name: '',
        familyNames: '',
    };
    if (searchName) {
        let nameParts = searchName.split(' ');
        let cleanNameParts = [];
        for (let i = 0; i < nameParts.length; i++) {
            if (nameParts[i]) {
                let currentNamePart = nameParts[i].replace(/\s/g, "");
                cleanNameParts.push(currentNamePart);
            }
        }
        //Verfify if first name is composed of two names
        let nameToSearch = '';
        if (cleanNameParts.length > 1) {
            nameToSearch = [cleanNameParts[0], cleanNameParts[1]];
        }
        else {
            nameToSearch = [cleanNameParts[0]];
        }
        let findResult = findName(nameToSearch);
        if(findResult && findResult.size !== nameParts.length)
        {
            for(let i=findResult.size; i < nameParts.length; i++)
            {
                valuesToReturn.familyNames += nameParts[i] + " ";   
            }
        }
        valuesToReturn.familyNames = valuesToReturn.familyNames.trim();
        valuesToReturn.gender = findResult.gender;
        valuesToReturn.name = findResult.name;
    }
    return valuesToReturn;
};
function findName(searchName) {
    let result = { gender: 'U', name: null, size: 1 };
    let nameToSearch = '';
    if (searchName.length > 1) {
        nameToSearch = searchName[0] + searchName[1];
        let tempName = removeAccentsConvertToUpper(nameToSearch);
        let genderResult = nameGenderList[tempName];
        //find the two names together
        if (genderResult !== undefined) {
            result.gender = genderResult;
            result.size = 2;
            result.name = searchName[0] + ' ' + searchName[1];
        }
        //findo two names in separate
        else {
            let firstName = findName([searchName[0]]);
            let secondName = findName([searchName[1]]);
            result.gender = firstName.gender;
            result.name = firstName.name;
            if (secondName.gender !== 'U') {
                result.size = 2;
                result.name += " " + secondName.name;
            };
        }
    }
    else {
        nameToSearch = searchName[0];
        result.name = searchName[0];
        let tempName = removeAccentsConvertToUpper(nameToSearch);
        let genderResult = nameGenderList[tempName];
        if (genderResult !== undefined) {
            result.gender = genderResult;
            result.name = nameToSearch;
        }
    }
    return result;
};
function removeAccentsConvertToUpper(text) {
    let portugueseChars = ['Ç', "Á", "Â", "Ã", "À", "É", "Ê", "Í", "Ó", "Õ", "Ô", "Ú"];
    let portugueseOther = ['C', "A", "A", "A", "A", "E", "E", "I", "O", "O", "O", "U"];

    text = text.toUpperCase();
    for (var i = 0; i < portugueseChars.length; i++) {
        text = text.split(portugueseChars[i]).join(portugueseOther[i]);
    }
    return text;
};