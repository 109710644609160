import { cloneObject } from '../../../utils/GeneralUtils';
import { formatCurrency } from '../../../utils/CurrencyUtils';

const faqs = {
    nib: {
        id: "nib",
        title: "Homebanking / Apps",
        text: 'Consegue saber em 3seg.... Procure no ecrã inicial ou no menu de "dados de contas" ou "contas"'
    },
    nib2: {
        id: "nib2",
        title: "Caixas Multibanco",
        text: 'A definir'
    },
    nib3: {
        id: "nib3",
        title: "Extratos e documentos",
        text: 'A definir'
    },
    nib4: {
        id: "nib4",
        title: "Outras contas em débito",
        text: 'A definir'
    },
    vctPrestacaoAumentarNao: {
        id: "prestacaoAumentarNao",
        title: "A prestação que pago pelo meu empréstimo vai aumentar?",
        text: "Não. A sua prestação vai manter-se"
    },
    vctPrestacaoAumentarSim: {
        id: "prestacaoAumentarSim",
        title: "A prestação que pago pelo meu empréstimo vai aumentar?",
        text: "Eu sei que esta é talvez a maior dúvida que tem. A nossa equipa de especialistas fez as contas para garantir que vai poupar.<br/><br/>" +
            "Para o seu empréstimo de {capital} {bank} poderá aumentar a prestação mensal até {maxMorgageIncrease} por mês. Com a proposta da {insurer} vai poupar <b>{savings}</b> por mês.<br/><br/>" +
            "Isto quer dizer que vai conseguir diminuir o custo do seu empréstimo entre <b>{minSavings}</b> e <b>{maxSavings}</b> por ano. Fica sempre a ganhar. Todos os anos e até final do seu empréstimo.<br/><br/>" +
            "Ainda tem dúvidas? Ligue para um dos nossos especialistas <a style='text-decoration:none' href='tel:+351 914 399 883'>(+351 914 399 883)</a>",
    },
    vctPrestacaoAumentarNim: {
        id: "vctPrestacaoAumentarNim",
        title: "A prestação que pago pelo meu empréstimo vai aumentar?",
        text: "Eu sei que esta é talvez a maior dúvida que tem. A nossa equipa de especialistas fez as contas para garantir que vai poupar.<br/><br/>" +
            "Para o seu empréstimo de {capital} {bank} vai conseguir diminuir o custo entre <b>{minSavings}</b> por ano e <b>{maxSavings}</b> por ano se {bank2} não aumentar o seu spread em mais do que {saveUntilSpread}. Este é muito provavelmente o seu caso.<br/><br/>Mas não deverá transferir o seu seguro se o seu contrato mencionar um aumento do seu spread superior a {saveUntilSpread2} - é pouco provável mas pode acontecer.<br/><br/>" +
            "Ainda tem dúvidas? Ligue para um dos nossos especialistas <a style='text-decoration:none' href='tel:+351 914 399 883'>(+351 914 399 883)</a>",
    },
    vctPrestacaoAumentarSemAumentoSpread: {
        id: "vctPrestacaoAumentarSemAumentoSpread",
        title: "A prestação que pago pelo meu empréstimo vai aumentar?",
        text: "Eu sei que esta é talvez a maior dúvida que tem. A nossa equipa de especialistas fez as contas para garantir que vai poupar.<br/><br/>" +
            "Só vale apena mudar o seu seguro se {bank2} não aumentar o spread. Basta que cumpra as condições do seu contrato. Existem várias forma de o fazer.<br/><br/>" +
            "Ainda tem dúvidas? Ligue para um dos nossos especialistas <a style='text-decoration:none' href='tel:+351 914 399 883'>(+351 914 399 883)</a>",
    },
    vctPrestacaoAumentarNoPrice: {
        id: "vctPrestacaoAumentarNoPrice",
        title: "A prestação que pago pelo meu empréstimo vai aumentar?",
        text: "Eu sei que esta é talvez a maior dúvida que tem. A nossa equipa de especialistas fez as contas para garantir que vai poupar.<br/><br/>" +
            "Mas sem informação sobre o preço do preço actual do seu seguro os nossos especialistas não conseguem avaliar o impacto na sua prestação em comparação com a poupança no preço do seu seguro."
            + " Aconselho que <a style='text-decoration:none' href='tel:+351 914 399 883'>fale com um dos nossos especialistas</a>",
    },
    vctFalarGestor: {
        id: "vctFalarGestor",
        title: "Vale a pena falar com o meu gestor de conta?",
        text: "Da nossa experiência, o gestor de conta demora demasiado tempo a responder - muitas vezes 3 semanas. E na grande maioria das vezes confirma-se o que dissemos.<br/><br/>" +
            "Se decidir comprar o seguro hoje, tem 2 meses para obter a confirmação do seu gestor de conta. E pode cancelar a compra em qualquer momento durante este período - nós devolvemos tudo o que pagou.",
    },
    vctDepoisComprar: {
        id: "vctDepoisComprar",
        title: "E o que acontece depois de eu comprar o seguro proposto pela uthere?",
        text: "Informamos o seu banco e a sua seguradora da transferência do seguro. Enviamos-lhe um email 5 dias antes de o seguro entrar em vigor.<br/><br/>" +
            "E tem 2 meses para mudar de opinião. Basta um clique e mantém o seu seguro actual. Devolveremos tudo o que pagou",
    },
    vctCoberturasIguais: {
        id: "vctCoberturasIguais",
        title: "Os coberturas propostas são iguais às coberturas do meu seguro actual?",
        text: "Todas as propostas incluem as coberturas pedidas pelo seu banco.",
    },
    vctMudarIdeias: {
        id: "vctMudarIdeias",
        title: "E se mudar de ideias?",
        text: "Se comprar hoje, tem 2 meses para mudar de ideias. Basta um clique e mantém o seu seguro actual. Devolveremos tudo o que pagou",
    },
    vctAjudarCancelar: {
        id: "vctAjudarCancelar",
        title: "Ajudam-me a cancelar o meu seguro actual?",
        text: "Sim, ajudamos. Nós tratamos de tudo. Enviamos uma carta de cancelamento para a sua seguradora actual",
    },
    vctAjudarInformarBanco: {
        id: "vctAjudarInformarBanco",
        title: "Ajuda-me a informar o meu banco?",
        text: "Claro que sim, nós tratamos de tudo. Enviamos uma carta para o seu banco a pedir a alteração do seu seguro de vida",
    },
    vccMesmoBanco: {
        id: "vccMesmoBanco",
        title: "Sou obrigado a ter o seguro no banco onde fiz o empréstimo?",
        text: "Não. Pode fazer o seu seguro no banco ou em qualquer seguradora. Nós aconselhamos que compre o seguro fora do banco onde vai fazer o seu empréstimo - vai agradecer-nos mais tarde",
    },
    vpfFilhos: {
        id: "vpf1Filhos",
        title: "Devo comprar um seguro de vida?",
        text: "Claro que sim. Imagine que fica doente ou morre. Se isto acontecer, para além da tristeza pelo que aconteceu, a sua família deixa de poder contar com o seu rendimento. É para estas alturas que o seguro de vida serve. Mais vale comprar um seguro 5 anos antes do que precisar 1 dia tarde de mais.",
    },
    vpfSemFilhos: {
        id: "vpf1SemFilhos",
        title: "Devo comprar um seguro de vida?",
        text: "Se ninguém depende do seu rendimento, então só vale a pena comprar um seguro de vida para se proteger de uma invalidez.<br/><br/>" +
            "Mas se tem pessoas que dependem do seu rendimento, então compre um seguro de vida. Imagine que fica doente ou morre. Se isto acontecer, para além da tristeza pelo que aconteceu, a sua família deixa de poder contar com o seu rendimento. É para estas alturas que o seguro de vida serve. Mais vale comprar um seguro 5 anos antes do que precisar 1 dia tarde de mais.",
    },
    devoComprarProteccaoFamilia: {
        id: "devoComprarProteccaoFamilia",
        title: "<b>Devo comprar</b> um seguro Vida Protecção Família?",
        text: "A resposta é sim, sempre que tiver família que dependa financeiramente de si. Por exemplo o seu marido ou mulher, os seus filhos ou os seus pais.<br/><br/>" +
            "Deve comprar um seguro Vida Protecção Família mesmo que já tenha um seguro de Vida associado ao empréstimo para compra de casa.<br/><br/>" +
            "Imagine que tem um acidente ou uma doença (por exemplo, um cancro) que provoquem a sua morte. Nesse caso, a sua família ou os seus filhos deixam de podem contar com o seu rendimento. E isso pode fazer toda a diferença nos seus estudos, universidade e na qualidade de vida do dia a dia.<br/><br/>",
    },
    proteccaoFamiliaCoberturas: {
        id: "proteccaoFamiliaCoberturas",
        title: "<b>Que coberturas tem</b> um seguro Vida Protecção Família?",
        text: "O seguro de Vida Protecção Família pode incluir as coberturas de morte, invalidez e doenças graves.<br/><br/>" +
            "A cobertura de morte garante que a sua família recebe o capital que contratou no caso da sua morte.<br/><br/>" +
            "A cobertura de invalidez garante que recebe o capital que contratou caso tenha uma doença ou um acidente que o impossibilitem de continuar a desempenhar as suas funções, e por isso reduzir o seu rendimento mensal.<br/><br/>" +
            "A cobertura de doenças graves (por exemplo, cancro) garante que recebe o capital que contratou logo que seja diagnosticado. Por exemplo, se contratar um capital de 100,000€, a seguradora entrega-lhe 100,000€ logo que lhe seja diagnosticado um cancro<br/><br/>",
    },
    lifeSavings: {
        id: "lifeSavings",
        title: "<b>Como posso ter a certeza de que vou poupar</b> se transferir o meu seguro de vida associado ao meu empréstimo?",
        text: "Nós fazemos as contas por si. Diga-nos qual é o valor que ainda deve ao banco, em que banco tem o seu empréstimo e quanto paga pelo seu seguro actual.<br/><br/>" +
            "Se não tiver comprado o seguro de vida no banco onde fez o empréstimo, pode transferir sem nenhuma preocupação. A prestação do seu empréstimo não se vai alterar.<br/><br/>" +
            "Se tiver comprado o seguro de vida no banco onde fez o empréstimo, nós fazemos as contas e dizemos-lhe se vale a pena. E se não valer a pena, nós dizemos-lhe. Queremos que tome uma decisão com toda a confiança<br/><br/>",
    },
    lifeOutsideBank: {
        id: "lifeOutsideBank",
        title: "<b>Posso comprar um seguro de vida</b> associado ao meu empréstimo <b>fora do banco?</b>",
        text: "Pode",
    },
    vpfAjustarCoberturas: {
        id: "vpfAjustarCoberturas",
        title: "Posso ajustar as coberturas em qualquer altura?",
        text: "Claro que sim. Em qualquer momento pode aumentar ou diminuir o capital que quer garantir à sua família, adicionar ou anular qualquer cobertura. Nós tratamos de tudo.",
    },
    vpfMudarIdeias: {
        id: "vpfMudarIdeias",
        title: "E se mudar de ideias e quiser anular o seguro?",
        text: "Se comprar hoje, tem 30 dias para mudar de ideias. Basta um clique. Devolveremos tudo o que pagou",
    },
    vpfDoençasGraves: {
        id: "vpfDoençasGraves",
        title: "Posso comprar a cobertura de doenças graves já depois de ter comprado o seguro de vida?",
        text: "Claro que sim, mas sempre dentro dos limites da seguradora. Basta dizer-nos e nós tratamos de tudo",
    },
    generalSeguroUthere: {
        id: "generalSeguroUthere",
        title: "Posso comprar o seguro na uthere?",
        text: "Pode comprar o seu seguro na uthere. A uthere é um mediador certificado pela autoridade de seguros.<br/><br/>" +
            "É muito simples. Aliás, mais de 50,000 pessoas procuraram a ajuda da uthere nos últimos 6 meses",
    },
    vpfSeguroUthere: {
        id: "generalSeguroUthere",
        title: "Posso comprar o seguro na uthere?",
        text: "Pode comprar o seu seguro na uthere. A uthere é um mediador certificado pela autoridade de seguros.<br/><br/>" +
            "É muito simples. Aliás, mais de 50,000 pessoas procuraram a ajuda da uthere nos últimos 6 meses",
    },
    house1Edificio: {
        id: "house1edificio",
        title: "Porque preciso de um seguro para a minha casa?",
        text: "Imagine que tem um incêndio em sua casa que destrói uma parte da sua casa. Ou que se rompe um cano e que estraga a sua parede. E são situações que podem acontecer mais frequentemente do que gostaria.<br/><br/>" +
            "Sabia que, por exemplo, o custo médio de um sinistro de incêndio é de 9.500€?<br/><br/>" +
            "Se tiver um seguro, a seguradora paga a reconstrução da parte da casa que foi danificada. Se não tiver um seguro, terá que retirar este dinheiro às suas poupança ou pedir dinheiro emprestado ao banco ou a amigos",
    },
    house1Bens: {
        id: "house1Bens",
        title: "Porque preciso de um seguro para os meus bens?",
        text: "Imagine que tem um incêndio em sua casa que destrói uma parte da sua casa e dos seus bens. Ou que se rompe um cano e que estraga o seu sofá e a sua mesa de jantar. E são situaçºoes que podem acontecer mais frequentemente do que gostaria.<br/><br/>" +
            "Sabia que, por exemplo, o custo médio de um sinistro de rotura de canalização é de 930€?<br/><br/>" +
            "Se tiver um seguro, a Allianz paga a reconstrução da parte da casa que foi danificada. Se não tiver um seguro, terá que retirar este dinheiro às suas poupança ou pedir dinheiro emprestado ao banco ou a amigos",
    },
    house1EdificioBens: {
        id: "house1EdificioBens",
        title: "Porque preciso de um seguro para os meus bens?",
        text: "Imagine que tem um incêndio em sua casa que destrói uma parte da sua casa e dos seus bens. Ou que se rompe um cano e que estraga o seu sofá e a sua mesa de jantar. E são situações que podem acontecer mais frequentemente do que gostaria.<br/><br/>" +
            "Sabia que, por exemplo, o custo médio de um sinistro de rotura de canalização é de 930€?<br/><br/>" +
            "Se tiver um seguro, a Allianz paga a reconstrução da parte da casa que foi danificada. Se não tiver um seguro, terá que retirar este dinheiro às suas poupança ou pedir dinheiro emprestado ao banco ou a amigos",
    },
    house2: {
        id: "house2",
        title: "O que influencia o preço do meu seguro?",
        text: "A definir",
    },
    house3: {
        id: "house3",
        title: "Vale a pena comprar a opção protecção extra para minha casa?",
        text: "Claro que sim. Com a cobertura Protecção Extra duplica o capital das coberturas de Riscos Eléctricos e Responsabilidade Civil.<br/><br/>" +
            "E no caso de ter também contratado a cobertura de bens, o seu carro também passa a estar protegido sempre que estiver estacionado na sua garagem.<br/><br/>" +
            "Muito Importante: também passa a ter acesso a um médico online. Este é um serviço gratuito, que pode utilizar sempre que precisar para consultas, prescrição de exames e medicamentos. Imagine o tempo e custo que pode poupar."
    },
    house4: {
        id: "house4",
        title: "Vale a pena compra a opção para protecção dos meus bens?",
        text: "A definir",
    },
    house5Edificio: {
        id: "house5Edificio",
        title: "Vale a pena comprar a opção para protecção de fenómenos sísmicos?",
        text: "Apesar de os terramotos serem muito pouco frequentes, quando acontecem destroem tudo ou praticamente tudo o que temos.<br/><br/>" +
            "Esta cobertura serve exactamente para esses casos. Para poder reconstruir a nossa casa. E pode fazer toda a diferença.",
    },
    house5Bens: {
        id: "house5Bens",
        title: "Vale a pena comprar a opção para protecção de fenómenos sísmicos?",
        text: "Apesar de os terramotos serem muito pouco frequentes, quando acontecem destroem tudo ou praticamente tudo o que temos.<br/><br/>" +
            "Esta cobertura serve exactamente para esses casos. Para poder ter capacidade para voltar a comprar os seus bens. E pode fazer toda a diferença.",
    },
    house5EdificoBens: {
        id: "house5EdifioBens",
        title: "Vale a pena comprar a opção para protecção de fenómenos sísmicos?",
        text: "Apesar de os terramotos serem muito pouco frequentes, quando acontecem destroem tudo ou praticamente tudo o que temos.<br/><br/>" +
            "Esta cobertura serve exactamente para esses casos. Para poder ter capacidade para voltar a comprar os seus bens. E pode fazer toda a diferença.",
    },
    house6: {
        id: "house6",
        title: "Tive um sinistro. E agora?",
        text: "É muito simples. Participe o seu sinistro com a uthere, e o nosso assistente virtual ajuda-o no resto. Depois enviamos a participação de sinistro para a seguradora e acompanhamos a resolução do seu sinistro.",
    },
    house7: {
        id: "house7",
        title: "O que é uma franquia? Como funciona?",
        text: "A franquia do seu seguro é o valor que fica a seu cargo quando tiver um sinistro.<br/><br/>" +
            "Imagine quem tem uma rotura de uma canalização, e que o custo do sinistro (custo para arranjar todos os estragos) é de 750 euro.<br/><br/>" +
            "A franquia é o valor que corresponde á sua participação no pagamento deste valor. A Allianz pagará a diferença entre o custo do sinistro e o valor da sua comparticipação.<br/><br/>" +
            "Neste exemplo, e como tem uma franquia de 150€, a a Allianz pagará 600€ e a sua comparticipação será de 150€.",
    },
    house8: {
        id: "house8",
        title: "O perito disse-me que o processo de sinistro seria aceite pela Allianz. È assim?",
        text: "Os peritos são técnicos a quem a Allianz pede para, em nome da Allianz, visitar a casa dos clientes em caso de sinistro.<br/><br/>" +
            "O objectivo de um perito é avaliar os danos que teve em sua casa e ajudar a recolher algum informação que possa estar em falta na participação de sinistro.<br/><br/>" +
            "A decisão final é tomada pela Allianz. Só a Allianz pode informar os clientes dos próximos passos e da sua decisão.<br/><br/>" +
            "A uthere está à sua disposição sempre que tiver dúvidas. Nós falamos com a Allianz e ajudamos a esclarecê-lo.",
    },
    house9: {
        id: "house9",
        title: "Quanto tempo demora desde a participação do sinistro até eu receber a indemnização?",
        text: "Para sinistros muito simples, por exemplo uma pequena rotura da canalização, a resolução do sinistro é normalmente muito rápida - pode demorar apenas alguns dias.<br/><br/>" +
            "E para diminuir o tempo de resolução do sinistro é muito importante que partilhe com a uthere toda a informação necessária.<br/><br/>" +
            "Nós tornámos tudo muito simples. Participe o seu sinistro com a uthere, e o nosso assistente virtual ajuda-o no resto. Depois enviamos a participação de sinistro para a Allianz e acompanhamos a resolução do seu sinistro..<br/><br/>" +
            "Os nossos especialistas estão disponíveis sempre que precisar.",
    },
    house10: {
        id: "house10",
        title: "Quando é que a seguradora não aceita o sinistro?",
        text: "A definir",
    },
    house11: {
        id: "house11",
        title: "Se eu tiver um seguro do condomínio vale a pena ter um seguro para a minha casa?",
        text: "A definir",
    },
    house12: {
        id: "house12",
        title: "O que é um crédito Habitação Multirriscos?",
        text: "O Crédito Habitação Multirriscos é um seguro associado a um financiamento imobiliário que protege a casa contra diversos riscos como por exemplo incêndio, inundações, roubo e roturas de canalização",
    },
    house13: {
        id: "house13",
        title: "O que cobre um Seguro Multirriscos Habitação?",
        text: "Um seguro Multirriscos Habitação protege-o de surpresas negativas como por exemplo incêndio, inundações, roubo e roturas de canalização na sua casa.<br/><br/>" +
            "Sabia que, por exemplo, o custo médio de um sinistro de incêndio é de 9,500€? E que o custo médio de um sinistro de rotura de canalização é de 930€?<br/><br/>" +
            "Se não tiver um Seguro Multirrisco Habitação, terá que pagar estes imprevistos na totalidade",
    },
    house14: {
        id: "house14",
        title: "Quanto custa mudar de Seguro Multirriscos na uthere?",
        text: "O nosso serviço é gratuito para si. A uthere procura a melhor seguro para si ao melhor preço. Depois é só decidir",
    },
    house15: {
        id: "house15",
        title: "Que bens são considerados como parte do edifício?",
        text: "Todos os bens móveis ligados materialmente ao imóvel com caráter de permanência, tais como: móveis de cozinha e roupeiros embutidos nas paredes, louças sanitárias, vitrais e pedras, portas, portões e janelas, persianas e toldos fixos, esquentadores, termoacumuladores, bombas de água, sistemas de aquecimento e ar condicionado, e outros eletrodomésticos encastrados, sistemas de vigilância e alarme, painéis solares, antenas, ascensores e monta-cargas.",
    },
    house16: {
        id: "house16",
        title: "É necessário incluir na apólice o valor dos painéis solares/fotovoltaicos instalados na habitação?",
        text: "Não! O valor dos painéis solares / fotovoltaicos deverá fazer parte do capital do Edifício.",
    },

    generalLife1: {
        id: "generalLife1",
        title: "Quem é a uthere?",
        text:
            "A uthere é um mediador digital de seguros, construído com uma voz e um coração humano." + "<br/><br/>" +
            "A uthere foi desenhada a pensar em si e inspirada por si. Transparente, rápida e sempre do seu lado!"
    },
    generalLife2: {
        id: "generalLife2",
        title: "Porque devo confiar na uthere?",
        text:
            "Porque somos transparentes. Dizemos-lhe o que o seguro cobre e não cobre, a partir do seu estilo de vida e das coisas que valoriza." + "<br/><br/>" +
            "Porque somos rápidos. Pode simular, escolher e comprar um seguro em minutos." + "<br/><br/>" +
            "Porque estamos sempre do seu lado. Dizemos-lhe quando vale a pena comprar o seguro e quando não é o melhor para si. E se precisar de nós, a nossa equipa especializada está sempre disponível para o ouvir e para o ajudar"
    },
    generalLife3: {
        id: "generalLife3",
        title: "Quanto custa um seguro de vida?",
        text: "Pode comprar um seguro a partir de 10€ por mês. Mas o preço final vai depender da sua idade, do seu estado de saúde, das actividades que faz e do valor que quiser garantir à sua família. Nós ajudamos a calcular o capital mais indicado para si"
    },
    generalLife4: {
        id: "generalLife4",
        title: "Posso transferir o meu seguro de Vida do Banco?",
        text:
            "Nos contratos mais recentes - contratos assinados a partir de 2009, o banco é obrigado a aceitar a transferência do seu seguro." + "<br/><br/>" +
            "Mas nos contratos mais antigos - contratos anteriores a 2009, o banco poderá não ser obrigado a aceitar a transferência de contrato. Deverá ler o que ficou acordado" + "<br/><br/>" +
            "A uthere ajuda-o a fazer a melhor escolha. E se o seu banco for mesmo muito chato e não o deixar transferir o seu seguro de vida, nós devolvemos o que pagou se cancelar nos primeiros 30 dias." + "<br/>"
    },
    claim1: {
        id: "claim1",
        title: "Quem é a uthere?",
        text:
            "A uthere é um mediador digital de seguros, construído com uma voz e um coração humano." + "<br/><br/>" +
            "A uthere foi desenhada a pensar em si e inspirada por si. Transparente, rápida e sempre do seu lado!"
    },
    claim2: {
        id: "claim2",
        title: "Posso participar o meu sinistro não sendo cliente uthere?",
        text:
            "Pode, claro que sim. Pode participar qualquer sinistro, independentemente da sua seguradora. Nós ajudamos."
    },
    claim3: {
        id: "claim3",
        title: "Com quem posso falar se tiver uma dúvida?",
        text:
            "Pode falar diretamente com a sua seguradora ou, se preferir, com um dos nossos especialistas. Eles estão sempre ao seu dispor." + "<br/><br/>" +
            "Ainda tem dúvidas? Ligue para um dos nossos especialistas <a style='text-decoration:none' href='tel:+351 914 399 883'>(+351 914 399 883)</a>",
    },
    claim4: {
        id: "claim4",
        title: "A uthere acompanha o meu processo até o fim?",
        text:
            "Sim, acompanhamos o seu processo até ao fim. Se tiver alguma dúvida, a nossa equipa de especialistas está disponível para o ajudar."
    },
    auto1: {
        id: "auto1",
        title: "<b>Como escolher</b> o melhor seguro automóvel para mim?",
        text:
            "Nós ajudamos. Procuramos no mercado a melhor proposta para si e para o seu carro, e explicamos quais as coberturas que melhor se ajustam.<br/><br/>Deve pensar se precisa apenas de protecção base (Responsabilidade Civil Obrigatória) ou se também quer estar protegido de incidentes como roubo, incêndio ou colisões. Outras coberturas importantes são assistência em viagem, veículo de substituição e despesas de tratamento para o condutor e os ocupantes"
    },
    auto2: {
        id: "auto2",
        title: "<b>Como transferir</b> o meu seguro automóvel para a uthere?",
        text:
            "É muito simples, Compre o seguro na uthere e nós tratamos da anulação do seu seguro anterior. Não poderia ser mais simples"
    },
    auto3: {
        id: "auto3",
        title: "Posso ter um <b>veículo de substituição?</b>",
        text:
            "Claro que sim. Dependendo da opção de seguro que escolher terá um veículo de substituição quando tiver uma avaria (plano base) e quando carro tiver um acidente (plano completo). Faça a simulação e nós ajudamos a escolher a melhor opção para si"
    },
    auto4: {
        id: "auto4",
        title: "Existem serviços adequados para <b>o meu carro eléctrico?</b>",
        text:
            "Claro. Por exemplo, se ficar parado sem bateria o serviço de assistência em viagem levará o seu carro até à estação de carregamento mais próxima"
    }
}

export function getFaqsNib() {
    let list = [faqs.nib, faqs.nib2, faqs.nib3, faqs.nib4];
    return list;
}

export function getFaqs(type, branch, subBranch, proposalValues, recomended, selection, site) {
    let list = [];
    if (type === 2) {
        list = getClaimQuestions();
    }
    else if (branch === 0) {
        list = getGeneralProcessQuestions();
    }
    else if (branch === 1 && subBranch !== 0) {
        list = getLifeBranchQuestions(proposalValues);
    }
    else if (branch === 3) {
        list = getHouseQuestions(proposalValues, selection, site);
    }
    else if (branch === 4) {
        list = getAutoQuestions(proposalValues, selection, site);
    }
    else if (branch === 2) {
        list = getLifeCreditBranchQuestions(subBranch, proposalValues, recomended);
    }
    else if (branch === 1 && site) {
        list = getLifeGenericQuestions();
    }
    else if (branch === 1) {
        list = getLifeStep1Questions();
    }
    return list;
}

function getAutoQuestions(proposalValues, selection, site) {
    let objectToReturn = [];
    objectToReturn.push(faqs.auto1);
    objectToReturn.push(faqs.auto2);
    objectToReturn.push(faqs.auto3);
    objectToReturn.push(faqs.auto4);
    return objectToReturn;
}

function getHouseQuestions(proposalValues, selection, site) {
    let objectToReturn = [];
    if (site) {
        objectToReturn.push(faqs.house12);
        objectToReturn.push(faqs.house1Edificio);
        objectToReturn.push(faqs.house13);
        objectToReturn.push(faqs.house14);
        objectToReturn.push(faqs.house6);
    }
    else
        if (proposalValues && selection) {
            if (proposalValues && proposalValues.houseUse && proposalValues.houseUse == "3") {
                objectToReturn.push(faqs.house1Bens);
            }
            else {
                if (selection.bens === "bens") {
                    objectToReturn.push(faqs.house1EdificioBens);
                }
                else {
                    objectToReturn.push(faqs.house1Edificio)
                }
            }
            objectToReturn.push(faqs.house3);
            objectToReturn.push(faqs.house5Edificio);
            objectToReturn.push(faqs.house6);
            objectToReturn.push(faqs.house7);
            objectToReturn.push(faqs.house8);
            objectToReturn.push(faqs.house9);
        }
        else {
            objectToReturn.push(faqs.house12);
            objectToReturn.push(faqs.house1Edificio);
            objectToReturn.push(faqs.house13);
            objectToReturn.push(faqs.house14);
            objectToReturn.push(faqs.house1Bens);
            objectToReturn.push(faqs.house15);
            objectToReturn.push(faqs.house16);
        }

    return objectToReturn;
}

function getGeneralProcessQuestions() {
    return [faqs.vpfSeguroUthere];
}

function getLifeBranchQuestions(proposalValues) {
    if (proposalValues && proposalValues.children > 0) {
        return [faqs.vpfFilhos, faqs.vpfSeguroUthere, faqs.vpfAjustarCoberturas, faqs.vpfMudarIdeias, faqs.vpfDoençasGraves];
    }
    else {
        return [faqs.vpfSemFilhos, faqs.vpfSeguroUthere, faqs.vpfAjustarCoberturas, faqs.vpfMudarIdeias, faqs.vpfDoençasGraves];
    }
}

function getLifeCreditBranchQuestions(subBranch, proposalValues, recomended) {
    if (subBranch === 1) {
        return [faqs.vccMesmoBanco, faqs.generalSeguroUthere];
    }
    else {

        let vctCoberturasIguais = null;
        let vctPrestacaoAumentar = null;

        if (proposalValues) {
            vctCoberturasIguais = cloneObject(faqs.vctCoberturasIguais);
            vctCoberturasIguais.text = vctCoberturasIguais.text.replace("{bank}",
                (proposalValues.bankGender === 'F' ? "pela " : "pelo ") + recomended.creditBankName);
        }

        //Disse o preço do seguro atual
        if (proposalValues && proposalValues.knowCurrentInsuranceCost === 1) {
            //Seguro não está onde está o crédito
            if (proposalValues.insuranceInBank === 2) {
                vctPrestacaoAumentar = cloneObject(faqs.vctPrestacaoAumentarNao);
            }
            else {
                let spreadValues = getSpreadValues(recomended);
                if (spreadValues.savings) {
                    if (spreadValues.alwaysSave) {
                        vctPrestacaoAumentar = cloneObject(faqs.vctPrestacaoAumentarSim);
                        vctPrestacaoAumentar.text = vctPrestacaoAumentar.text.replace("{capital}", spreadValues.total);
                        vctPrestacaoAumentar.text = vctPrestacaoAumentar.text.replace("{bank}",
                            (proposalValues.bankGender === 'F' ? "a " : "o ") + spreadValues.bank);
                        vctPrestacaoAumentar.text = vctPrestacaoAumentar.text.replace("{spread}", spreadValues.maxSpread);
                        vctPrestacaoAumentar.text = vctPrestacaoAumentar.text.replace("{insurer}", spreadValues.insurerName);
                        vctPrestacaoAumentar.text = vctPrestacaoAumentar.text.replace("{savings}", spreadValues.savingsMontlhy);
                        vctPrestacaoAumentar.text = vctPrestacaoAumentar.text.replace("{maxSavings}", spreadValues.maxSavings);
                        vctPrestacaoAumentar.text = vctPrestacaoAumentar.text.replace("{minSavings}", spreadValues.minSavings);
                        vctPrestacaoAumentar.text = vctPrestacaoAumentar.text.replace("{maxMorgageIncrease}", spreadValues.maxMorgageIncrease);
                    }
                    else {
                        if (spreadValues.maxSavings === spreadValues.minSavings) {
                            vctPrestacaoAumentar = cloneObject(faqs.vctPrestacaoAumentarSemAumentoSpread);
                        }
                        else {
                            vctPrestacaoAumentar = cloneObject(faqs.vctPrestacaoAumentarNim);
                        }
                        vctPrestacaoAumentar.text = vctPrestacaoAumentar.text.replace("{capital}", spreadValues.total);
                        vctPrestacaoAumentar.text = vctPrestacaoAumentar.text.replace("{bank}",
                            (proposalValues.bankGender === 'F' ? "na " : "no ") + spreadValues.bank);
                        vctPrestacaoAumentar.text = vctPrestacaoAumentar.text.replace("{bank2}",
                            (proposalValues.bankGender === 'F' ? "a " : "o ") + spreadValues.bank);
                        vctPrestacaoAumentar.text = vctPrestacaoAumentar.text.replace("{maxSavings}", spreadValues.maxSavings);
                        vctPrestacaoAumentar.text = vctPrestacaoAumentar.text.replace("{minSavings}", spreadValues.minSavings);
                        vctPrestacaoAumentar.text = vctPrestacaoAumentar.text.replace("{saveUntilSpread}", spreadValues.saveUntilSpread);
                        vctPrestacaoAumentar.text = vctPrestacaoAumentar.text.replace("{saveUntilSpread2}", spreadValues.saveUntilSpread);
                    }
                }
            }
        }
        //Não disse o preço do seguro atual
        else {
            vctPrestacaoAumentar = cloneObject(faqs.vctPrestacaoAumentarNoPrice);
        }

        let arrayToReturn = [
            faqs.vctFalarGestor,
            faqs.vctDepoisComprar,
            faqs.generalSeguroUthere, faqs.vctMudarIdeias, faqs.vctAjudarCancelar, faqs.vctAjudarInformarBanco];

        if (vctPrestacaoAumentar) {
            arrayToReturn = [vctPrestacaoAumentar, ...arrayToReturn];
        }
        if (vctCoberturasIguais) {
            arrayToReturn = [vctCoberturasIguais, ...arrayToReturn];
        }

        return arrayToReturn;
    }
}

function getLifeGenericQuestions() {
    return [faqs.generalLife1, faqs.generalLife2, faqs.generalLife3, faqs.generalLife4];
}

function getClaimQuestions() {
    return [faqs.claim1, faqs.claim2, faqs.claim3, faqs.claim4];
}

function getLifeStep1Questions() {
    return [faqs.devoComprarProteccaoFamilia, faqs.proteccaoFamiliaCoberturas, faqs.lifeSavings, faqs.lifeOutsideBank];
}


function getSpreadValues(recomended) {

    let spreadsArray = [0, 0.15, 0.25, 0.5];
    let spreadsArrayString = ["0%", "0,15%", "0,25%", "0,5%"];
    let spreadsValues = [];
    let maxMorgageIncrease = null;
    for (let i = 0; i < spreadsArray.length; i++) {
        let valueToPush = calculateSpread(recomended, spreadsArray[i]);
        spreadsValues.push(valueToPush);
        if (spreadsArray.length === (i + 1)) {
            maxMorgageIncrease = valueToPush.morgageIncrease;
        }
    }

    let savings = false;
    let savingsMaxSpread = null;
    let alwaysSave = true;

    for (let i = 0; i < spreadsValues.length; i++) {
        if (spreadsValues[i].savingsValue > 0) {
            savings = true;
            savingsMaxSpread = i;
        }
        else {
            alwaysSave = false;
        }
    }

    let objectToReturn = {
        total: formatCurrency(recomended.baseTotal),
        bank: recomended.creditBankName,
        savings: savings,
        alwaysSave: alwaysSave,
        maxSpread: spreadsArrayString[3],
        insurerName: recomended.insurerName
    }

    if (!savings) {
        return objectToReturn;
    }

    if (alwaysSave) {
        objectToReturn["savingsMontlhy"] = formatCurrency(spreadsValues[0].savingsValue);
        objectToReturn["maxSavings"] = formatCurrency((spreadsValues[0].savingsValue * 12));
        objectToReturn["minSavings"] = formatCurrency(spreadsValues[3].savingsValue * 12);
        objectToReturn["maxMorgageIncrease"] = formatCurrency(maxMorgageIncrease, 2);
    }
    else {
        objectToReturn["saveUntilSpread"] = spreadsArrayString[savingsMaxSpread];
        objectToReturn["minSavings"] = formatCurrency((spreadsValues[savingsMaxSpread].savingsValue * 12));
        objectToReturn["maxSavings"] = formatCurrency((spreadsValues[0].savingsValue * 12));
    }

    return objectToReturn;
}

function calculateSpread(proposal, spreadPercentage) {
    let newPrice = parseFloat(proposal.price);
    let oldPrice = proposal.monthlyCurrent;
    let objectToReturn = {
        morgageIncrease: 0,
        savingsValue: null,
    }
    let euriborPercentage = -0.527;
    let euribor = euriborPercentage / 100;
    let spread = spreadPercentage / 100;
    let remainingMonths = 30 * 12;
    let tanWithoutSpread = euribor.toFixed(4) / 12;
    let tanWithSpread = (euribor + spread).toFixed(4) / 12;
    let tempWihtout =
        (tanWithoutSpread * 12) /
        (1 - Math.pow(1 + tanWithoutSpread, -remainingMonths));
    let tempWith =
        (tanWithSpread * 12) /
        (1 - Math.pow(1 + tanWithSpread, -remainingMonths));
    let montlhyWitoutSpread =
        (tempWihtout * proposal.total) / 12;
    let montlhyWithSpread = (tempWith * proposal.total) / 12;
    objectToReturn.morgageIncrease = montlhyWithSpread - montlhyWitoutSpread;
    objectToReturn.savingsValue = oldPrice - newPrice - objectToReturn.morgageIncrease;
    return objectToReturn;
}
