/**
 * 
 * @param {*} text 
 * @returns 
 */
export function capitalizeText(text) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function capitalizeWords(text) {
    let lower = text.toLowerCase();
    const arr = lower.split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

    }
    //Join all the elements of the array back into a string 
    //using a blankspace as a separator 
    const str2 = arr.join(" ");
    return str2;
}

export function containsNumbers(str) {
    try {
        let value = parseInt(str);
        if (!isNaN(value)) {
            if (value > 0)
                return true;
            return false;
        }
        else {
            return false;
        }

    }
    catch (e) {
        return false;
    }
    //return /\d/.test(str);
}

/**
 * 
 * @param {*} elements 
 * @returns 
 */
export function getListText(elements, capitalize) {
    let textToReturn = '';
    for (let i = 0; i < elements.length; i++) {
        if (i === 0) {
            textToReturn += String(elements[i]);
        }
        else if ((i + 1) === elements.length) {
            textToReturn += ' e ' + String(elements[i]);
        }
        else {
            textToReturn += ', ' + String(elements[i]);
        }
    }
    if (capitalize) {
        return capitalizeText(textToReturn);
    }
    else {
        return textToReturn;
    }
}

/**
 * 
 * @param {*} values 
 * @returns 
 */
export function composeSwitchText(values, texts, capitalize) {
    let responseOptions = [];
    let valuesKeys = Object.keys(values);
    for (let i = 0; i < valuesKeys.length; i++) {
        if (values[valuesKeys[i]]) {
            if (texts) {
                responseOptions.push(texts[valuesKeys[i]]);
            }
            else {
                responseOptions.push(valuesKeys[i]);
            }

        }
    }
    return getListText(responseOptions, capitalize ? capitalize : false);
}


export function composeSwitchSportsText(values, texts, sportsMode) {
    let responseOptions = [];
    let valuesKeys = Object.keys(values);
    for (let i = 0; i < valuesKeys.length; i++) {
        if (values[valuesKeys[i]]) {
            if (texts) {
                let tempText = texts[valuesKeys[i]];
                if (sportsMode && sportsMode[valuesKeys[i]]) {
                    tempText += " em competição"
                }
                responseOptions.push(tempText);
            }
            else {
                responseOptions.push(valuesKeys[i]);
            }

        }
    }
    return getListText(responseOptions, false);
}