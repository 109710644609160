import './HomeFaqs.scss';
import Faq from '../../home/home-faq/faq/Faq';
import { getFaqs } from '../../proposal/proposal-config/ProposalFaqConifg';

/**
* HomeFaqs
*/
function HomeFaqs(props) {
    const faqList = {
        1: getFaqs(1, props.branch ? props.branch : 3, 0, null, null, null, true)
    };
    return (
        <div className='faqs-container'>
            <div className='home-title-container'>
                {props.main ? <h2><span className='bold'>Esclarecemos</span> as suas dúvidas
                </h2> :
                <h2><span className='bold'>Ainda tem dúvidas?</span>
                </h2>}
                 {props.main ? <h6>Os seguros podem ser confusos. Nós ajudamos a explicar e a tornar tudo mais simples</h6> : props.branch == 4 ? <h6>O seguro auto pode pode ás vezes ser confuso. Nós ajudamos a explicar e a tornar tudo simples</h6> : null}
            </div>
            <div>
                <Faq faqList={faqList} type={1} />
            </div>
        </div>
    );
};
export default HomeFaqs;
