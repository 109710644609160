import { processBooleanSequence } from '../../utils/UthereExpressionsUtils'


export function getNextPageId(previousState) {
    if (previousState && previousState.currentPage) {
        let nextPageId = previousState.currentPage.nextPageId;
        if (nextPageId) {
            if (typeof nextPageId === 'string' || nextPageId instanceof String) {
                return nextPageId;
            }
            else if (typeof nextPageId === 'object') {
                return processNextPageIdFormula(nextPageId, previousState);
            }
            else
                return null
        }
        else {
            return null;
        }
    }
    else {
        return null;
    }
}
/**
 * 
 * @param {*} simulationState 
 * @returns 
 */
function processNextPageIdFormula(nextPageIdObject, simulationState) {
    return processBooleanSequence(nextPageIdObject);
};
