import { useEffect, useState, useRef } from "react";
import { getUserType, getUserData } from "../services/UserServices";
import { store } from "../store/store";


export default function useUser() {


    const [user, setUser] = useState(() => initUser());
    let unsubscribe = useRef(null);

    useEffect(() => {
        unsubscribe.current = store.subscribe(() => {
            if (store.getState()) {
                setUser(initUser());
            }
        });
        return () => {
            if (unsubscribe.current) {
                unsubscribe.current();
            }
        }
    }, []);

    return user;
}


function initUser() {
    let dataToReturn = {};
    let userType = getUserType();
    let userData = getUserData();
    dataToReturn.type = userType;
    if (userData.email) {
        dataToReturn.email = userData.email;
    }
    if (userData.personalData) {
        dataToReturn.name = userData.personalData.name;
        dataToReturn.gender = userData.personalData.gender;
        dataToReturn.lastName = userData.personalData.lastName;
    }
    return dataToReturn;
}

