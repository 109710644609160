export const lifeClaimFlow = {
    begin:
    {
        sequence: [
            {
                type: 'AND',
                result: 'inform',
                conditions: [
                    {
                        comparator: 'NOT_EMPTY',
                        values: [
                            {
                                type: 'userVariable',
                                value: 'insuranceNumber',
                            },
                        ]
                    }]
            },
            {
                result: 'insuranceAtUthere',
            }]
    },
    pages: {
        insuranceAtUthere: {
            type: 'findInsurance',
            blockSave: true,
            nextPageId: {
                sequence: [
                    {
                        result: 'phoneUthere',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'NOT_UTHERE_USER',
                            },
                        ],
                    },
                    {
                        result: 'inform',
                    }]
            },
            laraMessage: ['luisInsuranceAtUthere'],
            userMessage: 'userNifClaim',
            options: {
                branch: [1, 2]
            }
        },
        insurer: {
            laraMessage: ['luisInsurer2', 'luisInsurer4'],
            blockSave: true,
            userMessage: 'userInsurer',
            nextPageId: 'exit',
            type: 'fullRadio',
            blockSave: true,
            options: {
                label: 'lblInsurerName',
                list: 'insurerLifeList',
                otherValues: { insurerGender: 'gender', insurerPhone : 'phone' , insurerEmail : "email" },
            }
        },
        phoneUthere: {
            type: 'phone',
            last : true,
            allowBack: false,
            blockSave: true,
            laraMessage: ['luisPhoneUthere', 'luisPhoneUthere2'],
            options: {
                label: 'lblPhoneTo',
                type: 'uthere'
            }
        },
        exit: {
            type: 'exit',
            blockSave: true,
            last: true,
            allowBack: false,
            laraMessage : ['luisInsurerData', 'luisInsurerData2'],
            options: {
                label: 'lblExit',
                cancel: true,
            }
        },
        inform: {
            type: 'informClaim',
            blockSave: true,
            last: true,
            allowBack: false,
            laraMessage: {
                sequence:
                    [
                        {
                            result: ['luisWillPhone1','luisWillPhoneNextWorkingDay'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'NOT_WORKING_DAY',
                                },
                            ],
                        },
                    {
                        result: ['luisWillPhone1','luisWillPhoneTommorrow'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'IS_AFTER_7',
                            },
                        ],
                    },
                    {
                        result: ['luisWillPhone1','luisWillPhone'],
                    }]
            },
            options: {
                label: 'lblExit',
                branch : [1, 2]
            }
        },
    }
}