import './NotifcationList.scss';
import closeWhite from '../../../../../assets/svg/closeWhite.svg';
import UthereNotification from '../../../../common/uthere-notification/UthereNotification';
import useNotifications from '../../../../../hooks/useNotifications';

/**
* NotifcationList
*/
function NotifcationList(props) {

    const notifications = useNotifications(props);

    return (
        <div>
            <div className='notification-modal-list'>
                <div className='notification-modal-list-header'>

                    <div onClick={props.closeNotifications}
                        className={'notification-modal-list-close rotate'}>
                        <img src={closeWhite} alt="Fechar" />
                    </div>
                </div>
                <div className='notification-modal-list-header-fader'></div>
                <div className='notification-modal-list-container'>
                    {notifications.map((item, i) => (
                        !props.type || item.type === props.type ?
                            <div key={i}>
                                <UthereNotification notification={item} />
                            </div> : null
                    ))}
                </div>
            </div>
            <div className='notification-modal-background'>

            </div>
        </div>
    );
};
export default NotifcationList;