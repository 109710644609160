import { doc, collection, setDoc, onSnapshot } from "firebase/firestore";
import { getDb } from '../../firebase/UthereFirebase';
import { startPlateSearch, searhPlate, startAutoProposal, getAutoProposal } from "../../services/AutoServices";
import { getCurrentProcessId, getCurrentState, getCurrentProcessValues } from "./ProcessLogic";
import { capitalizeWords } from "../../utils/StringUtils";
import { store } from "../../store/store";
import { setPortuguesStringFormatToFloat } from "../../utils/CurrencyUtils";
import { isoToUthereDate } from "../../utils/DateUtils";

let processesListeners = {};
let plateListeners = {};


export function startInstance() {
    return new Promise(async function (resolve, reject) {
        try {
            let processId = getCurrentProcessId();
            if (processId) {
                startPlateSearch(processId).then().catch();
                if (!processesListeners[processId]) {
                    let db = await getDb();
                    const rpaProcessesRef = collection(db, "rpaprocesses");
                    processesListeners[processId] = onSnapshot(doc(rpaProcessesRef, processId), (doc) => {
                        try {
                            if (doc.data()) {
                                let payload = {
                                    data: doc.data(),
                                    processId: processId
                                }
                                store.dispatch({ type: 'auto/setProcessState', payload: payload });
                            }
                        } catch (e) { }
                    });
                }
            }
            resolve();
        } catch (e) { resolve() }
    });
};

export function generateAutoProposal() {
    return new Promise(async function (resolve, reject) {
        let processId = getCurrentProcessId();
        let processesState = store.getState().auto.processesState;
        if (!processesState || !processesState[processId] || processesState[processId].proposalStatus == 3 || !processesState[processId].proposalStatus) {
            let currentProcessState = getCurrentState();
            getAutoProposal(processId, currentProcessState.userValues).then().catch(error => { })
        }
        resolve()
    });
}

/**
 * 
 * @param {*} plate 
 */
export function searchPlate(inputPlate) {
    return new Promise(async function (resolve, reject) {
        let processId = getCurrentProcessId();
        if (inputPlate && processId) {
            let db = await getDb();
            const rpaprocessesCollectionRef = collection(db, "rpaprocesses");
            const rpaprocessesDocRef = doc(rpaprocessesCollectionRef, processId);
            let inputPlateUpper = inputPlate.toUpperCase();
            setDoc(rpaprocessesDocRef, { plate: inputPlateUpper }, { merge: true }).then().catch();
            let payload = {
                processId: processId,
                plate: inputPlateUpper
            }
            store.dispatch({ type: 'auto/setCurrentPlate', payload: payload });
            let processesState = store.getState().auto.processesState;
            if (!processesState || !processesState[processId] || processesState[processId].status != 1 || processesState[processId].allianzStatus != 1) {
                //Não está RPA a correr
                let plates = store.getState().auto.plates;
                if (!plates
                    || !plates[inputPlateUpper] || !plates[inputPlateUpper].tranquilidade || !plates[inputPlateUpper].allianz) {
                    let mode = !processesState || !processesState[processId] ? 1 :
                        processesState[processId].status != 1
                            && processesState[processId].allianzStatus != 1 ? 1 :
                            processesState[processId].status != 1 ? 2 : 3;
                    searhPlate(processId, inputPlateUpper, mode).then().catch(error => { })
                }
            }
            if (!plateListeners[inputPlateUpper]) {
                const platesCollectionRef = collection(db, "plates");
                plateListeners[inputPlateUpper] = onSnapshot(doc(platesCollectionRef, inputPlateUpper), (doc) => {
                    if (doc.data()) {
                        let plateData = doc.data();
                        let payload = {
                            data: plateData,
                            plate: inputPlateUpper,
                            processId: processId,
                        }
                        store.dispatch({ type: 'auto/setPlateInfo', payload: payload });
                    }
                });
            }
        }
        resolve()
    });
};

function getCurrentPlate() {
    let plateToReturn = null;
    let processId = getCurrentProcessId();
    let currentProcessState = getCurrentState();
    let processesState = store.getState().auto.processesState;
    plateToReturn = currentProcessState && currentProcessState.userValues &&
        currentProcessState.userValues.plate ? currentProcessState.userValues.plate :
        processesState && processesState[processId] && processesState[processId].plate ? processesState[processId] && processesState[processId].plate
            : null;
    return plateToReturn
}

function getCurrentPlateInfo() {
    let plates = store.getState().auto.plates;
    let currentPlate = getCurrentPlate();
    if (currentPlate && plates[currentPlate]) {
        return plates[currentPlate]
    };

    return null;
}

export function getCurrentValues() {
    let valuesToReturn = {};
    let currentPlateInfo = getCurrentPlateInfo()
    if (currentPlateInfo) {
        if (currentPlateInfo.needManualModel !== null && currentPlateInfo.needManualModel !== undefined) {
            valuesToReturn.needManualModel = currentPlateInfo.needManualModel;
        }
        if (currentPlateInfo.needManualVersion !== null && currentPlateInfo.needManualVersion !== undefined) {
            if (currentPlateInfo && currentPlateInfo.manualModelSelected && currentPlateInfo.models) {
                let modelToUpper = currentPlateInfo.manualModelSelected.toUpperCase();
                if (currentPlateInfo.models[modelToUpper] && currentPlateInfo.models[modelToUpper].versions
                    && currentPlateInfo.models[modelToUpper].versions.length < 2
                ) {
                    valuesToReturn.needManualVersion = false;
                    valuesToReturn.version = currentPlateInfo.models[modelToUpper].versions[0].name;
                }
                else {
                    valuesToReturn.needManualVersion = currentPlateInfo.needManualVersion;
                }
            }
            else {
                valuesToReturn.needManualVersion = currentPlateInfo.needManualVersion;
            }
        }
        if (currentPlateInfo.manualModelSelected !== null && currentPlateInfo.manualModelSelected !== undefined) {
            valuesToReturn.manualModelSelected = currentPlateInfo.manualModelSelected;
        }
        if (currentPlateInfo.invalidCategory) {
            valuesToReturn.invalidCategory = true;
            if (currentPlateInfo.category) {
                switch (currentPlateInfo.category) {
                    case "MOTOCICLO":
                        valuesToReturn.autoType = 2;
                        break;
                    case "PESADO":
                        valuesToReturn.autoType = 4;
                        break;
                    default: break;
                }
            }
        }
        if (currentPlateInfo.carInfo) {
            valuesToReturn.brand = capitalizeWords(currentPlateInfo.carInfo.brand);
            let carDescObject = getCarDescritpion();
            if (carDescObject) {
                if (carDescObject.long) {
                    valuesToReturn.carDescritpion = carDescObject.long;
                }
                if (carDescObject.short) {
                    valuesToReturn.carDescritpionShort = carDescObject.short;
                }
            }
            if (currentPlateInfo.listOfVersions) {
                let listOfVersionRef = currentPlateInfo.listOfVersions;
                let currentSelected = null;
                let currentSelectedPage = null;
                let currentValue = 0;
                for (let i = 0; i < listOfVersionRef.length; i++) {
                    let currentVers = listOfVersionRef[i];
                    if (currentVers.priceNew) {
                        let priceNew = setPortuguesStringFormatToFloat(currentVers.priceNew);
                        if (priceNew > currentValue) {
                            currentValue = priceNew;
                            currentSelected = currentVers.version;
                            currentSelectedPage = currentVers.page;
                        }
                    }
                }
                valuesToReturn.consideredVersion = currentSelected;
                valuesToReturn.consideredVersionPage = currentSelectedPage;
            }
        }
    }
    return valuesToReturn;
}

export function getCarCancelCardText() {
    let currentValues = getCurrentProcessValues();
    let currentProcessState = getCurrentState();
    let textToReturn = "Exmos. Senhores,<br/><br/>"
    textToReturn += "Solicito que seja anulada a apólice do meu seguro automóvel ({carDescription}, matrícula {plate}), a partir do próximo dia {emissionDate}.<br/><br/>"
    textToReturn += "Mais informo que não autorizo que seja efectuado mais nenhum débito na minha conta de nenhum valor associado à apólice cuja anulação estou agora a comunicar.<br/><br/>"
    textToReturn += "Obrigado,</br></br>"
    textToReturn += "{name} {familyNames}";
    textToReturn = textToReturn.replaceAll("{carDescription}", currentValues.carDescritpionShort)
    textToReturn = textToReturn.replaceAll("{plate}", currentValues.plate)
    if (currentProcessState && currentProcessState.selectedProposal) {
        if (currentProcessState.selectedProposal.emissionDate) {
            textToReturn = textToReturn.replaceAll("{emissionDate}", isoToUthereDate(currentProcessState.selectedProposal.emissionDate))
        }
        textToReturn = textToReturn.replaceAll("{name}", currentProcessState.selectedProposal.name)
        textToReturn = textToReturn.replaceAll("{familyNames}", currentProcessState.selectedProposal.familyNames)
    }
    return textToReturn;
}

export function getCarDescritpion() {
    let descriptionToReturn = {
        short: null,
        long: null
    };
    let versionNames = []
    let plateInfo = getCurrentPlateInfo();
    if (plateInfo && plateInfo.carInfo && plateInfo.carInfo.brand) {
        descriptionToReturn.short = capitalizeWords(plateInfo.carInfo.brand);
        descriptionToReturn.long = capitalizeWords(plateInfo.carInfo.brand);
    }
    if (plateInfo && plateInfo.hasVersions && plateInfo.listOfVersions && plateInfo.listOfVersions.length > 0) {
        for (let i = 0; i < plateInfo.listOfVersions.length; i++) {
            versionNames.push(plateInfo.listOfVersions[i].version)
        }

        let commonVersionDesc = getCommonDescription(versionNames);
        if (commonVersionDesc) {
            descriptionToReturn.long += " " + commonVersionDesc;
            descriptionToReturn.short += " " + commonVersionDesc;
        }

    }
    else if (plateInfo && plateInfo.manualModelSelected) {
        descriptionToReturn.long += " " + plateInfo.manualModelSelected;
        descriptionToReturn.short += " " + plateInfo.manualModelSelected;
    }
    if (plateInfo && plateInfo.carInfoAllianz && plateInfo.carInfoAllianz.anyMatri) {
        descriptionToReturn.long += " de " + plateInfo.carInfoAllianz.anyMatri;
    }

    return descriptionToReturn;
}

function getCommonDescription(descritpions) {
    let stringToReturn = "";
    if (descritpions) {
        let canditate = [];
        for (let i = 0; i < descritpions.length; i++) {
            let descriptionSplit = descritpions[i].split(" ");
            if (canditate.length == 0) {
                for (let j = 0; j < descriptionSplit.length; j++) {
                    canditate.push(descriptionSplit[j])
                }
            }
            else {
                let positionsToPop = -1;
                for (let j = 0; j < canditate.length; j++) {
                    if (!descriptionSplit[j] || descriptionSplit[j] != canditate[j]) {
                        positionsToPop = j;
                        break;
                    }
                }
                if (positionsToPop > -1) {
                    for (let j = canditate.length; j > positionsToPop; j--) {
                        canditate.pop();
                    }

                    if (canditate.length == 0) {
                        break;
                    }
                }
            }
        }
        if (canditate.length > 0) {
            for (let i = 0; i < canditate.length; i++) {
                if (i == 0) {
                    stringToReturn += canditate[i];
                }
                else {
                    stringToReturn += " " + canditate[i];
                }
            }
        }
    }
    return stringToReturn;
}

export function versionsHasDoors() {
    let currentProcessState = getCurrentState();
    let userValues = currentProcessState.userValues;
    let versionToConsider = null;
    if (userValues && userValues.version) {
        if (userValues.version == 99) {
            versionToConsider = userValues.consideredVersion;
        }
        else {
            versionToConsider = userValues.version;
        }
    }

    let versions = {}
    let plateInfo = getCurrentPlateInfo();
    if (plateInfo && plateInfo.listOfVersions) {
        let currentListOfVersions = plateInfo.listOfVersions;
        for (let i = 0; i < currentListOfVersions.length; i++) {
            let currentVersionName = currentListOfVersions[i].version;
            if (!versionToConsider || versionToConsider == currentVersionName) {
                if (!versions[currentVersionName]) {
                    versions[currentVersionName] = {
                        doors: currentListOfVersions[i].doors,
                    };
                }
                else if (versions[currentVersionName].doors != currentListOfVersions[i].doors) {
                    return true;
                }
            }
        }


    }
    return false;
}



export function getSinistros() {
    let valueToReturn = -1;
    let processId = getCurrentProcessId();
    let processesState = store.getState().auto.processesState;
    if (processesState && processesState[processId] && processesState[processId].sinistrosSixYears != null && processesState[processId].sinistrosSixYears != undefined) {
        return processesState[processId].sinistrosSixYears;
    }
    return valueToReturn;
}

export function getCurrentListOfModels() {
    let listOfModelsToReturn = [];
    let plates = store.getState().auto.plates;
    let currentPlate = getCurrentPlate();
    if (currentPlate && plates[currentPlate]) {
        if (plates[currentPlate].allianz && plates[currentPlate].models) {
            let modelsKeys = Object.keys(plates[currentPlate].models);
            for (let i = 0; i < modelsKeys.length; i++) {
                listOfModelsToReturn.push({
                    value: modelsKeys[i],
                    label: capitalizeWords(modelsKeys[i])
                })
            }
        }
    }
    return listOfModelsToReturn;
}

export function getCurrentListOfVersions() {
    let versions = {}
    let listOfVersionsToReturn = [];
    let plateInfo = getCurrentPlateInfo();
    if (plateInfo && plateInfo.listOfVersions && plateInfo.listOfVersions.length > 0
        && plateInfo.hasVersions && !plateInfo.needManualVersion) {
        let currentListOfVersions = plateInfo.listOfVersions;

        for (let i = 0; i < currentListOfVersions.length; i++) {
            let currentVersionName = currentListOfVersions[i].version;
            if (!versions[currentVersionName]) {
                versions[currentVersionName] = {
                    value: currentListOfVersions[i].version,
                    label: currentListOfVersions[i].version,
                    otherValues: {
                        page: currentListOfVersions[i].page,
                    }
                };
            }
        }
        let versionsKeys = Object.keys(versions)

        for (let i = 0; i < versionsKeys.length; i++) {
            listOfVersionsToReturn.push(versions[versionsKeys[i]]);
        }

        listOfVersionsToReturn.push({
            value: 99,
            label: "Não sei"
        });
    }
    else if (plateInfo && plateInfo.needManualVersion && plateInfo.allianz && plateInfo.models) {
        let processInfo = getCurrentState();
        if (processInfo && processInfo.userValues && (processInfo.userValues.carModel || processInfo.userValues.manualModelSelected)) {
            let carModelNormalized = processInfo.userValues.carModel ? processInfo.userValues.carModel.toUpperCase() : processInfo.userValues.manualModelSelected.toUpperCase();
            if (plateInfo.models[carModelNormalized]) {
                let selectedModel = plateInfo.models[carModelNormalized];
                let currentListId = {};
                if (selectedModel.versions) {
                    for (let i = 0; i < selectedModel.versions.length; i++) {
                        if (!currentListId[selectedModel.versions[i].name]) {
                            currentListId[selectedModel.versions[i].name] = true;
                            listOfVersionsToReturn.push({
                                value: selectedModel.versions[i].name,
                                label: capitalizeWords(selectedModel.versions[i].name)
                            });
                        }
                    }
                }
            }
        }
    }
    return listOfVersionsToReturn;
}

export function updateProposalValues(proposalValues) {
    return new Promise(async function (resolve, reject) {
        if(proposalValues && proposalValues.address && !proposalValues.address.streetPostal){
            proposalValues.address.streetPostal = proposalValues.address.postalCode
        }
        let processId = getCurrentProcessId();
        if (processId) {
            let db = await getDb();
            const rpaprocessesCollectionRef = collection(db, "rpaprocesses");
            const rpaprocessesDocRef = doc(rpaprocessesCollectionRef, processId);
            setDoc(rpaprocessesDocRef, proposalValues, { merge: true }).then(response => {
            }).catch(error => {
            })
        }
        resolve()
    });
}


export function startGetProposal() {
    return new Promise(async function (resolve, reject) {
        let processId = getCurrentProcessId();
        if (processId) {
            if (processesListeners[processId]) {
                processesListeners[processId]();
            }
            let db = await getDb();
            const rpaProcessesRef = collection(db, "rpaprocesses");
            processesListeners[processId] = onSnapshot(doc(rpaProcessesRef, processId), (doc) => {
                if (doc.data()) {
                    let payload = {
                        data: doc.data(),
                        processId: processId
                    }
                    store.dispatch({ type: 'auto/setProcessState', payload: payload });
                }
            });
            startAutoProposal(processId).then().catch(error => { })
        }
        resolve();
    });
};

const planoBaseText = "Recomendo a compra do Plano Protecção Base da Tranquilidade";
const planoMaisText = "Recomendo a compra do Plano Mais Protecção da Tranquilidade, que inclui a cobertura de roubo";
const planoTopText = "Recomendo a compra do Plano Protecção Completa da Tranquilidade, que inclui as cobertura que inclui as coberturas de Danos Próprios";
const adjustText = "Entre todas as opções analisadas, esta é a que tem o melhor preço e é a mais ajustada para o seu {brand}";
const noRecomend = "Eu não aconselho a compra da cobertura de danos próprios. É muito mais cara para a protecção garantida para o seu {brand}";
const ready = "Pronto para ver a proposta?";

const textProposalRecomendTop = "Recomendo a compra do Plano Protecção Completa - veja em baixo as coberturas incluídas";
const textProposalRecomendTop2 = "Já inclui o pacote de Danos Próprios, ficando protegido de incidentes como roubo, choque ou colisão, e actos de vandalismo";
const textProposalRecomendTop3 = "O pacote Danos Próprios também melhora a cobertura de veículo de substituição, que passa a ficar disponível logo a partir do momento do acidente e não apenas em caso de avaria e só quando o seu {carDesc} estiver na oficina";
const textProposalRecomendBase = "Recomendo a compra do Plano Protecção Base - veja em baixo as coberturas incluídas";
const textProposalMaisAdd = "Por apenas mais {addPrice} / {period} pode ainda adicionar o Pacote Mais Protecção, passando a ficar protegido de incidentes como roubo e ao seu {carDesc}"
const textProposalMaisAdd2 = "O pacote Mais Protecção também melhora a cobertura de veículo de substituição, que passa a ficar disponível logo a partir do momento do acidente e não apenas quando o seu {carDesc} estive na oficina";
const textProposalTopAdd = "Por apenas mais {addPrice} / {period}  pode ainda adicionar o Pacote Danos próprios, passando a ficar protegido de incidentes como roubo, choque ou colisão, e actos de vandalismo ao seu {carDesc}"
const textProposalTopAdd2 = "Mas eu não recomendo a compra das coberturas de danos próprios, pois não acho que o aumento do preço compense a melhoria das coberturas";
const textProposalTopAdd3 = "O pacote Danos Próprios também melhora a cobertura de veículo de substituição, que passa a ficar disponível logo a partir do momento do acidente e não apenas em caso de avaria";
const textProposalDontRecomend = "Não recomendo a compra das coberturas de danos próprios, pois não acho que o aumento do preço compense a melhoria das coberturas";

const textProposalRecomendMais = "Recomendo a compra do Plano Mais Protecção - veja em baixo as coberturas incluídas";


export const planosUthere = {
    "0": {
        product: "B",
        selected: "B",
        laraTexts: [ready]
    },
    "1a1": {
        product: "B",
        selected: "B",
        max: "M",
        laraTexts: [planoBaseText, adjustText, noRecomend],
        recomendedBullets: [textProposalRecomendBase, textProposalMaisAdd, textProposalMaisAdd2, textProposalDontRecomend]
    },
    "1a2": {
        product: "B",
        selected: "B",
        max: "T",
        laraTexts: [planoBaseText, adjustText, noRecomend],
        recomendedBullets: [textProposalRecomendBase, textProposalTopAdd, textProposalTopAdd2]
    },
    "1b1": {
        product: "B",
        selected: "B",
        max: "M",
        laraTexts: [planoBaseText, adjustText, noRecomend],
        recomendedBullets: [textProposalRecomendBase, textProposalMaisAdd, textProposalMaisAdd2, textProposalDontRecomend]
    },
    "1b2": {
        product: "B",
        selected: "B",
        max: "T",
        laraTexts: [planoBaseText, adjustText, noRecomend],
        recomendedBullets: [textProposalRecomendBase, textProposalTopAdd, textProposalTopAdd2]
    },
    "2a1": {
        product: "B",
        selected: "B",
        max: "M",
        laraTexts: [planoBaseText, adjustText, noRecomend],
        recomendedBullets: [textProposalRecomendBase, textProposalMaisAdd, textProposalMaisAdd2, textProposalDontRecomend]
    },
    "2a2": {
        product: "B",
        selected: "B",
        max: "T",
        laraTexts: [planoBaseText, adjustText, noRecomend],
        recomendedBullets: [textProposalRecomendBase, textProposalTopAdd, textProposalTopAdd2]
    },
    "2b1": {
        product: "B",
        selected: "B",
        max: "T",
        laraTexts: [planoBaseText, adjustText, noRecomend],
        recomendedBullets: [textProposalRecomendBase, textProposalTopAdd, textProposalTopAdd2]
    },
    "2b2": {
        product: "B",
        selected: "B",
        max: "T",
        laraTexts: [planoBaseText, adjustText, noRecomend],
        recomendedBullets: [textProposalRecomendBase, textProposalTopAdd, textProposalTopAdd2]
    },
    "3a1": {
        product: "M",
        selected: "M",
        max: "T",
        laraTexts: [planoMaisText, adjustText],
        recomendedBullets: [textProposalRecomendMais, textProposalTopAdd, textProposalTopAdd3]
    },
    "3a2": {
        product: "M",
        selected: "M",
        max: "T",
        laraTexts: [planoMaisText, adjustText],
        recomendedBullets: [textProposalRecomendMais, textProposalTopAdd, textProposalTopAdd3]
    },
    "3b1": {
        product: "B",
        selected: "B",
        max: "M",
        laraTexts: [planoBaseText, adjustText],
        recomendedBullets: [textProposalRecomendBase, textProposalTopAdd, textProposalTopAdd3]
    },
    "3b2": {
        product: "B",
        selected: "B",
        max: "T",
        laraTexts: [planoBaseText, adjustText],
        recomendedBullets: [textProposalRecomendTop, textProposalRecomendTop2, textProposalTopAdd3]
    },
    "4a1": {
        product: "M",
        selected: "M",
        max: "T",
        laraTexts: [planoMaisText, adjustText],
        recomendedBullets: [textProposalRecomendMais, textProposalTopAdd, textProposalTopAdd3]
    },
    "4a2": {
        product: "M",
        selected: "T",
        max: "T",
        laraTexts: [planoTopText, adjustText],
        recomendedBullets: [textProposalRecomendTop, textProposalRecomendTop2, textProposalRecomendTop3]
    },
    "4b1": {
        product: "B",
        selected: "B",
        max: "T",
        laraTexts: [planoBaseText, adjustText],
        recomendedBullets: [textProposalRecomendBase, textProposalTopAdd, textProposalTopAdd3]
    },
    "4b2": {
        product: "M",
        selected: "T",
        max: "T",
        laraTexts: [planoTopText, adjustText],
        recomendedBullets: [textProposalRecomendTop, textProposalRecomendTop2, textProposalRecomendTop3]
    },
    "5a1": {
        product: "B",
        selected: "T",
        max: "T",
        laraTexts: [planoTopText, adjustText],
        recomendedBullets: [textProposalRecomendTop, textProposalRecomendTop2, textProposalRecomendTop3]
    },
    "5a2": {
        product: "B",
        selected: "T",
        max: "T",
        laraTexts: [planoTopText, adjustText],
        recomendedBullets: [textProposalRecomendTop, textProposalRecomendTop2, textProposalRecomendTop3]
    },
    "5b1": {
        product: "B",
        selected: "T",
        max: "T",
        laraTexts: [planoTopText, adjustText],
        recomendedBullets: [textProposalRecomendTop, textProposalRecomendTop2, textProposalRecomendTop3]
    },
    "5b2": {
        product: "B",
        selected: "T",
        max: "T",
        laraTexts: [planoTopText, adjustText],
        recomendedBullets: [textProposalRecomendTop, textProposalRecomendTop2, textProposalRecomendTop3]
    },
}

export function getRecomendedProposal(danosProporios, rua, recomendedByPrice, onlyBase) {
    let code = onlyBase ? "0" : recomendedByPrice;
    if (!onlyBase) {
        code += rua ? "a" : "b";
        code += danosProporios ? "2" : "1";
    }
    return planosUthere[code];
}

export function getAutoProposalMessage(userValues) {
    let processId = getCurrentProcessId();
    let processesState = store.getState().auto.processesState;
    if (processId && processesState[processId] && processesState[processId].recomended) {
        let plano = getRecomendedProposal(userValues.hasDanosProprios == 1, userValues.carGarage == 1, processesState[processId].recomended,
            processesState[processId].onlyBase)
        if (plano) {
            return plano.laraTexts;
        }
    }
    return [ready];
}

export function getCarProposalPercentage() {
    let processId = getCurrentProcessId();
    let processesState = store.getState().auto.processesState;
    if (processesState && processesState[processId] && processesState[processId].percentageProposal) {
        return processesState[processId].percentageProposal;
    }
    return null;
}

export function hasCarInfo() {
    let processId = getCurrentProcessId();
    if (processId) {
        let allInfo = store.getState().auto.allInfo;
        if (allInfo && allInfo[processId]) {
            return true;
        }
    }
    return false;
}

export function isAlreadyAutoClient() {
    let processId = getCurrentProcessId();
    let processesState = store.getState().auto.processesState;
    if (processesState && processesState[processId] && processesState[processId].proposalStatus == 4) {
        return true;
    }
    return false;
}

