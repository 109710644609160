import * as SimulationConfig from '../simulation/SimulationConfig';
import * as ClaimConfig from '../claim/ClaimConfig';
import * as ProposalChatConfig from '../proposal/helpChat'

export function getAvatar(branchInfo) {
    if (branchInfo.type === 2) {
        return ClaimConfig.getAvatar();
    }
    else {
        return SimulationConfig.getAvatar();
    }
}

export function getFlow(branchInfo) {
    if (branchInfo.type === 2) {
        return ClaimConfig.getFlow(branchInfo);
    }
    else if (branchInfo.type === 3) {
        return ProposalChatConfig.getFlow();
    }
    else {
        return SimulationConfig.getFlow(branchInfo);
    }
}

export function getLang(branchInfo) {
    if (branchInfo.type === 2) {
        return ClaimConfig.getLang();
    }
    else if (branchInfo.type === 3) {
        return ProposalChatConfig.getLang();
    }
    else {
        return SimulationConfig.getLang(branchInfo);
    }
}